import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import i18next from 'i18next';

const styles = theme => ({
  root: {
    alignItems: 'center'
  },
  button: {
    width: '1rem',
    borderRadius: '4px',
    minWidth: '2rem',
    marginRight: '0.5rem',
    marginTop: '0.3rem'
  },
  icon: {
      color: 'gray'
  }
});

class SimpleMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handlePlaylist = () => {
      this.props.handleDialog();
      this.handleClose();
  }

  handlePacets = () => {
    this.props.handlePacketDialog();
    this.handleClose();
  }

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;

    return (
      
      <div>
        <Tooltip title={i18next.t("Add_new_playlist")}>
          <Button className={classes.button}>
            <AddIcon className={classes.icon} onClick={this.handleClick}/>
          </Button>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handlePlaylist}>{i18next.t("Create_New_Playlist")}</MenuItem>
          <MenuItem onClick={this.handlePacets}>{i18next.t("Add_pacets_playlist")}</MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(SimpleMenu);