import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import * as pageActions from '../../store/actions/PageActions'
import { connect } from 'react-redux';
import Playlist from './Playlist';
import { store } from '../../store/configureStore'
import { bindActionCreators } from 'redux'
import FileList from '../Files/files'
import AddButton from '../AddButton';
import { withStyles } from '@material-ui/core/styles';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import NewPlaylistDialog from './NewPlaylistDialog';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    main: {
        // display: 'flex',
        width: '97%',
        marginTop: '4.5rem',
        // position: 'fixed' 
    },
})

class Playlists extends Component {
    constructor(props) {
        super(props)
        this.state = {
            handleNewPlaylistDialog: false,
        }
    }

    handleNewPlaylistDialog = () => {this.setState({handleNewPlaylistDialog: !this.state.handleNewPlaylistDialog})}

    render() {
        const { content,  } = this.props.page
        const { classes, playlists } = this.props
        const { deletePlaylist, editPlaylist, createPlaylist } = this.props.pageActions
        const { token, id } = this.props.user

        let listPlaylists = () => {
            return <Playlist />
        }
        if (playlists){
            listPlaylists = playlists.map((d, i) => {
                return <Playlist key={i} playlists={d} content={content} token={this.props.user.token} deletePlaylist={deletePlaylist}/>
            })
        }
        
        return (
            <div className={classes.main}>
                {listPlaylists}
                <FileList />
                <AddButton handleNewPlaylistDialog={this.handleNewPlaylistDialog}/>
                <NewPlaylistDialog open={this.state.handleNewPlaylistDialog} handleNewPlaylistDialog={this.handleNewPlaylistDialog} createPlaylist={createPlaylist} token={token} id={id} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page
    }
}

function mapDispatchToProps(dispatch) {
    return {
      pageActions: bindActionCreators(pageActions, dispatch),
    //   userActions: bindActionCreators(userActions, dispatch)
    }
  }

export default DragDropContext(HTML5Backend)(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Playlists)));