import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import i18next from 'i18next';

const styles = theme => ({
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat( auto-fit, minmax(33.33%, 1fr) )'
    },
    item: {
        wordWrap: 'break-word',
        paddingRight: '3rem',
        alignSelf: 'flex-end',
        display: "flex",
        width: '100%',
    },
    progress: {
        marginTop: '1rem'
    },
    progressItems: {
        display: 'flex'
    },
    progressItemsNow: {
        font: 'small-caption'
    },
    progressItemsTotall: {
        marginLeft: 'auto',
        font: 'small-caption'
    },
    message: {
        marginButtom: '0.2rem'
    },
    checkBox: {
        paddingTop: '14px'
    }
})

class UploadItem extends React.Component {


    render() {
        const { file, progress, converterProgress, classes, handleComment, comments, id, upload, convert, handleConvert, duration, handleDuration } = this.props;

        return (
            <div>
                <div className={classes.grid}>
                    <div className={classes.item} style={{ paddingBottom: '10px' }}>{file.name} </div>
                    {progress[id] || converterProgress[id] ?
                        <div>
                            {!converterProgress[id] ?
                                <div>
                                    <div className={classes.message}>{progress[id].message}</div>
                                    <LinearProgress variant='determinate' value={Math.round((progress[id].uploadFile / progress[id].uploadFileTotall) * 100)} />
                                    <div className={classes.grid}>
                                        <div className={classes.progressItemsNow}>{Math.round(progress[id].uploadFile)} Mb</div>
                                        <div></div>
                                        <div className={classes.progressItemsTotall}>{Math.round(progress[id].uploadFileTotall)} Mb</div>
                                    </div>
                                </div> :
                                <div>
                                    <div className={classes.message}>{converterProgress[id].message}</div>
                                    <LinearProgress variant='determinate' value={Math.round((converterProgress[id].value / converterProgress[id].totall) * 100)} />
                                    <div className={classes.grid}>
                                        <div className={classes.progressItemsNow}> </div>
                                        <div> </div>
                                        <div className={classes.progressItemsTotall}>{Math.round((converterProgress[id].value / converterProgress[id].totall) * 100)} %</div>
                                    </div>
                                </div>

                            } </div> : <div> {upload ? <div>
                                <div className={classes.message}>{i18next.t("Complete")}</div>

                                <div className={classes.grid}>
                                    <div className={classes.progressItemsNow}> </div>
                                    <div> </div>
                                    <div className={classes.progressItemsTotall}></div>
                                </div>
                            </div> :
                                <div className={classes.item}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id={id}
                                        label={i18next.t("Comment")}
                                        type="text"
                                        fullWidth
                                        ref={(ref) => { this.fileComment = ref; }}
                                        value={comments[id]}
                                        onChange={handleComment.bind(this)}
                                    />
                                    <div style={{ "paddingLeft": "35px" }}></div>
                                    {file.name.split(".")[file.name.split(".").length - 1].toLowerCase() === "jpg" || file.name.split(".")[file.name.split(".").length - 1].toLowerCase() === "png" ?
                                        <TextField
                                            // autoFocus
                                            margin='dense'
                                            id={id}
                                            label="Duration"
                                            type='text'
                                            defaultValue="15"
                                            value={duration}
                                            onChange={handleDuration.bind(this)}
                                        /> :
                                        <FormControlLabel
                                            label={i18next.t("converting")}
                                            control={
                                                <Checkbox
                                                    id={id}
                                                    checked={convert}
                                                    className={classes.checkBox}
                                                    onChange={handleConvert.bind(this)}
                                                />}
                                            style={{ marginLeft: "0px", marginRight: "0px" }}
                                        />
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
                <hr></hr>
            </div>
        )
    }
}

export default withStyles(styles)(UploadItem);