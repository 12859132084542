import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationIcon from '@material-ui/icons/Navigation';
import { CREATE_PLAYLISTS_SUCCESS } from '../constants/Page';

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  button: {
      position: 'fixed',
      bottom: '1rem',
      left: '1rem'
  }
});

function AddButton(props) {
  const { classes, handleNewPlaylistDialog } = props;
  return (
    <div className={classes.button}>
      <Fab color="primary" aria-label="Add" className={classes.fab} onClick={handleNewPlaylistDialog}>
        <AddIcon />
      </Fab>
    </div>
  );
}

AddButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddButton);