import * as pageConstants from '../../constants/Page'
import * as network from '../../services';
import api from '../../sources/api';
import user from '../reducers/user';
import { store } from '../configureStore'
import { func } from 'prop-types';
import axios from 'axios';

export function setPlayer(player) {
    return {
        type: pageConstants.SET_PLAYER,
        payload: player
    }
}

export function setPlayList(playlist) {
    return {
        type: pageConstants.SET_PLAY_LIST,
        payload: playlist
    }
}

export function setPosition(data) {
    const d = JSON.parse(data);

    return store.dispatch({
        type: pageConstants.SET_POSITION,
        payload: d
    })
}

export function setFullPlayerInfo(data) {
    var d = [];
    if (data.id in d) {
        d.map(n => {
            if (n.id === data.id) {
                n = data;
            }
        });
    } else {
        d.push(data);
    }

    return store.dispatch({
        type: pageConstants.SET_FULL_PLAYER_INFO,
        payload: d
    });
}

export function setPositionFull(data) {
    var d = JSON.parse(data);
    d = d.map(dd => {
        // dd.title_message = dd.setings.title_message;
        return dd;
    });
    return store.dispatch({
        type: pageConstants.SET_POSITION_FULL,
        payload: d
    });
}

export function setProgress(data, token) {
    const d = data;
    if (d.message === 'done') {
        // console.log('DONE!');
        store.dispatch(getContent(token));
    }
    return store.dispatch({
        type: pageConstants.SET_PROGRESS,
        payload: d
    });
}

export function getPosition() {
    return function (dispatch) {
        dispatch({
            type: pageConstants.GET_POSITION,
            payload: [store.getState().page.players]
        })
    }
}

export function getContentD(token) {

    store.dispatch({
        type: pageConstants.GET_CONTENT_REQUEST,

    })
    api.getContent(token)
        .then(r => {
            if (r.status === 200) {
                store.dispatch({
                    type: pageConstants.GET_CONTENT_SUCCESS,
                    payload: r.data
                })
            } else {
                store.dispatch({
                    type: pageConstants.GET_CONTENT_ERROR,
                    payload: { error: '' }
                })
            }
        })
}

export function getContent(token) {
    // console.log("GET CONTENT", token)
    return function () {
        store.dispatch({
            type: pageConstants.GET_CONTENT_REQUEST,

        })
        api.getContent(token)
            .then(r => {
                if (r.status === 200) {
                    store.dispatch({
                        type: pageConstants.GET_CONTENT_SUCCESS,
                        payload: r.data
                    })
                } else {
                    store.dispatch({
                        type: pageConstants.GET_CONTENT_ERROR,
                        payload: { error: '' }
                    })
                }
            })
    }
}

export function getContentFile(token, filename) {
    return function () {
        store.dispatch({
            type: pageConstants.GET_CONTENT_FILE_REQUEST,

        })
        api.getContentFile(token, filename)
            .then(responce => {
                if (responce.status === 200) {
                    store.dispatch({
                        type: pageConstants.GET_CONTENT_FILE_SUCCESS,
                        payload: { error: '' }
                    })
                }
            })
    }
}

export function deleteContent(token, filename, id) {
    return function () {
        store.dispatch({
            type: pageConstants.DELETE_CONTENT_REQUEST,
        })
        api.deleteContent(token, { 'name': filename })
            .then(r => {
                if (r.status === 200) {
                    const socket = network.socket
                    socket.emit('play', { 'reload': 1, 'player': 'all', 'ownid': id });
                    store.dispatch({
                        type: pageConstants.DELETE_CONTENT_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.DELETE_CONTENT_ERROR,
                    payload: { error: error }
                })
            })
    }
}

export function deleteStream(token, filename, id) {
    return function () {
        store.dispatch({
            type: pageConstants.DELETE_STREAM_REQUEST,
        });
        api.deleteStream(token, { 'link': filename })
            .then(r => {
                if (r.status === 200) {
                    const socket = network.socket;
                    socket.emit('play', { 'reload': 1, 'player': 'all', 'ownid': id });
                    store.dispatch({
                        type: pageConstants.DELETE_STREAM_SUCCESS,
                        payload: r.data
                    });
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.DELETE_STREAM_ERROR,
                    payload: { error: error }
                })
            })
    }
}

export function renameContent(token, name, data, own) {
    return function () {
        store.dispatch({
            type: pageConstants.RENAME_CONTENT_REQUEST,
        })
        api.renameContent(token, name, data)
            .then(r => {
                if (r.status === 200) {
                    const socket = network.socket
                    socket.emit('play', { 'reload': 1, 'player': 'all', 'ownid': own });
                    store.dispatch({
                        type: pageConstants.RENAME_CONTENT_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.RENAME_CONTENT_ERROR,
                    payload: { error: error }
                })
            })
    }
}

export function getPlayers(token) {
    return function () {
        store.dispatch({
            type: pageConstants.GET_PLAYERS_REQUEST,
        })
        api.getPlayers(token)
            .then(r => {
                if (r.status === 200) {
                    store.dispatch({
                        type: pageConstants.GET_PLAYERS_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.GET_PLAYERS_ERROR,
                    payload: { error: error }
                })
            })
    }
}

export function getPLR(token, id) {
    store.dispatch({
        type: pageConstants.GET_PLAYER_REQUEST,
    })
    api.getPlayer(token, id)
        .then(r => {
            if (r.status === 200) {
                store.dispatch({
                    type: pageConstants.GET_PLAYER_SUCCESS,
                    payload: r.data
                })
            }
        })
        .catch(error => {
            store.dispatch({
                type: pageConstants.GET_PLAYER_ERROR,
                payload: { error: error }
            })
        })
}

export function updatePlayer(token, id, data) {
    return function () {
        store.dispatch({
            type: pageConstants.SET_PLAYER_REQUEST,
        })
        api.updatePlayer(token, id, data)
            .then(r => {
                if (r.status === 200) {
                    // const socket = network.socket
                    // socket.emit('play', {'setings': data, 'player': id});
                    store.dispatch({
                        type: pageConstants.SET_PLAYER_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.SET_PLAYER_ERROR,
                    payload: { error: error }
                })
            })
    }
}

export function deletePlayer(token, id) {
    return function () {
        store.dispatch({
            type: pageConstants.DELETE_PLAYER_REQUEST,
        })
        api.deletePlayer(token, id)
            .then(r => {
                if (r.status === 200) {
                    store.dispatch({
                        type: pageConstants.DELETE_PLAYER_SUCCESS,
                        payload: r.data
                    });
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.DELETE_PLAYER_ERROR,
                    payload: { 'error': error }
                });
            });
    };
}

export function getPlaylists(token) {
    return function () {

        store.dispatch({
            type: pageConstants.GET_PLAYLISTS_REQUEST,
        })
        api.getPlaylists(token)
            .then(r => {
                if (r.status === 200) {
                    store.dispatch({
                        type: pageConstants.GET_PLAYLISTS_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.GET_PLAYLISTS_ERROR,
                    payload: { error: error }
                })
            })
    }
}

export function getPacsPlaylists(token) {
    return function () {

        store.dispatch({
            type: pageConstants.GET_PACS_PLAYLISTS_REQUEST,
        })
        api.getPackPlaylists(token)
            .then(r => {
                if (r.status === 200) {
                    store.dispatch({
                        type: pageConstants.GET_PACS_PLAYLISTS_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.GET_PACS_PLAYLISTS_ERROR,
                    payload: { error: error }
                })
            })
    }
}

export function setPacsPlaylists(token, data) {
    return function () {

        store.dispatch({
            type: pageConstants.SET_PACS_PLAYLISTS_REQUEST,
        })
        api.setpacsplaylist(token, data)
            .then(r => {
                if (r.status === 200) {
                    store.dispatch({
                        type: pageConstants.SET_PACS_PLAYLISTS_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.SET_PACS_PLAYLISTS_ERROR,
                    payload: { error: error }
                })
            })
    }
}

export function checkpacsplaylist(token, data) {
    return function () {
        store.dispatch({
            type: pageConstants.CHECK_PACS_PLAYLIST_REQUEST
        })
        api.checkpacsplaylist(token, data)
            .then(r => {
                if (r.status === 200) {
                    store.dispatch({
                        type: pageConstants.CHECK_PACS_PLAYLIST_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.CHECK_PACS_PLAYLIST_ERROR,
                    payload: { error: error }
                })
            })
    }
}


export function updatePlaylist(data, token, own) {
    // console.log('STORE => ', store)
    store.dispatch({
        type: pageConstants.EDIT_PLAYLISTS_REQUEST,
    })
    api.editPlaylist(token, data)
        .then(r => {
            if (r.status === 200) {
                store.dispatch({
                    type: pageConstants.EDIT_PLAYLISTS_SUCCESS,
                    payload: r.data
                })
                const socket = network.socket
                // console.log("R.DATA =>", r.data)
                socket.emit('play', { 'playlists': 1, 'player': 'all', 'data': 'reload', 'ownid': r.data[0].own });
                return r.data
            }
        })
        .catch(error => {
            store.dispatch({
                type: pageConstants.EDIT_PLAYLISTS_ERROR,
                payload: { 'error': error }
            })
        })
}

export function addPlaylistToPlayer(token, data) {
    api.addPlaylistToPlayer(token, data)
        .then(r => {
            if (r.status === 200) {
                store.dispatch({
                    type: pageConstants.ADD_PLAYLIST_TO_PLAYER_SUCCESS,
                    payload: r.data
                })
            }
        })
        .catch(error => {
            store.dispatch({
                type: pageConstants.ADD_PLAYLIST_TO_PLAYER_ERROR,
                payload: { 'error': error }
            })
        })
}

export function editPlaylist(token, data, own) {
    return function () {
        store.dispatch({
            type: pageConstants.EDIT_PLAYLISTS_REQUEST,
        })
        api.editPlaylist(token, data)
            .then(r => {
                if (r.status === 200) {

                    store.dispatch({
                        type: pageConstants.EDIT_PLAYLISTS_SUCCESS,
                        payload: r.data
                    })
                    const socket = network.socket
                    socket.emit('play', { 'playlists': 1, 'player': 'all', 'data': 'reload', 'ownid': own });
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.EDIT_PLAYLISTS_ERROR,
                    payload: { 'error': error }
                })
            })
    }
}

export function createPlaylist(token, data, id) {
    return function () {
        store.dispatch({
            type: pageConstants.CREATE_PLAYLISTS_REQUEST,
        })
        api.createPlaylist(token, data)
            .then(r => {
                if (r.status === 200) {
                    store.dispatch({
                        type: pageConstants.CREATE_PLAYLISTS_SUCCESS,
                        payload: r.data
                    })
                    const socket = network.socket
                    socket.emit('play', { 'playlists': 1, 'player': 'all', 'data': 'reload', 'ownid': id });
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.CREATE_PLAYLISTS_ERROR,
                    payload: { 'error': error }
                })
            })
    }
}

export function deletePlaylist(token, data, id) {
    return function () {
        store.dispatch({
            type: pageConstants.DELETE_PLAYLISTS_REQUEST,
        })
        api.deletePlaylist(token, data)
            .then(r => {
                if (r.status === 200) {
                    store.dispatch({
                        type: pageConstants.DELETE_PLAYLISTS_SUCCESS,
                        payload: r.data
                    })
                    const socket = network.socket
                    socket.emit('play', { 'playlists': 1, 'player': 'all', 'data': 'reload', 'ownid': id });
                }
            })
            .catch(error => {
                store.dispatch({
                    type: pageConstants.DELETE_PLAYLISTS_ERROR,
                    payload: { 'error': error }
                })
            })
    }
}

export function uicommand(token, data) {
    switch (data.command) {
        case 'getplayers':
            getPlayers(token);
            break;
        default:
            break;
    }

}

export function setUploadFiles(data) {
    return function (dispatch) {
        dispatch({
            type: pageConstants.SET_UPLOAD_FILES,
            payload: data
        })
    }
}

export function clearPageError() {
    return function (dispatch) {
        dispatch({
            type: pageConstants.CLEAR_PAGE_ERROR
        })
    }
}

export function getLogs(token, data) {
    return function (dispatch) {
        dispatch({
            type: pageConstants.GET_LOGS_REQUEST
        })
        api.getLogs(token, data)
            .then(r => {
                if (r.status === 200) {
                    store.dispatch({
                        type: pageConstants.GET_LOGS_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(e => {
                store.dispatch({
                    type: pageConstants.GET_LOGS_ERROR,
                    payload: { 'error': e }
                })
            })
    }
}

export function getLogIds(token) {
    return function (dispatch) {
        dispatch({
            type: pageConstants.GET_LOG_IDS_REQUEST
        })
        api.getLogIds(token)
            .then(r => {
                if (r.status === 200) {
                    store.dispatch({
                        type: pageConstants.GET_LOG_IDS_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(e => {
                store.dispatch({
                    type: pageConstants.GET_LOG_IDS_ERROR,
                    payload: { 'error': e }
                })
            })
    }
}

export function updatePlayerApp(own, pid) {
    return function (dispatch) {
        dispatch({
            type: pageConstants.UPDATE_PLAYER_APP
        })
        const socket = network.socket
        socket.emit('play', { 'update': 1, 'player': pid, 'ownid': own });
    }
}

export function getFullPlayerInfo(own, pid) {
    return function (dispatch) {
        dispatch({
            type: pageConstants.GET_FULL_PLAYER_INFO
        })
        const socket = network.socket
        socket.emit('play', { 'getPlayerInfo': 1, 'player': pid, 'ownid': own });
    }
}

export function reloadPlayer(own, pid) {
    return function (dispatch) {
        dispatch({
            type: pageConstants.RELOAD_PLAYER
        })
        const socket = network.socket
        socket.emit('play', { 'playlists': 1, 'player': pid, 'data': 'reload', 'ownid': own });
    }
}

export function restartPlayer(own, pid) {
    return function (dispatch) {
        dispatch({
            type: pageConstants.RESTART_PLAYER
        })
        const socket = network.socket;
        socket.emit('play', { 'playlists': 1, 'player': pid, 'data': 'restart', 'ownid': own });
    }
}

export function showError(data) {
    return store.dispatch({
        type: pageConstants.SHOW_ERROR,
        payload: { 'error': data.error }
    })
}

export function addSnack(data) {
    // console.log('ADD SNACK')
    return store.dispatch({
        type: pageConstants.ADD_SNACK,
        payload: data
    })
}

export function removeSnack(data) {
    return function (dispatch) {
        dispatch({
            type: pageConstants.REMOVE_SNACK,
            payload: data
        })
    }
}

export function removeProgress(data) {
    return function (dispatch) {
        dispatch({
            type: pageConstants.REMOVE_PROGRESS,
            payload: data
        })
    }
}

export function showProgress(data) {
    return function (dispatch) {
        dispatch({
            type: pageConstants.SHOW_PROGRESS,
            payload: data
        })
    }
}

export function changeModes(token, data) {
    return function (dispatch) {
        dispatch({
            type: pageConstants.CHANGE_MODES_REQUEST
        })
        api.changeModes(token, data)
            .then(r => {
                if (r.status === 200) {
                    store.dispatch({
                        type: pageConstants.CHANGE_MODES_SUCCES,
                        payload: r.data
                    })
                    const socket = network.socket;
                    socket.emit('play', { 'player': data.id, 'data': 'restart', 'ownid': data.own });
                }
            })
            .catch(e => {
                store.dispatch({
                    type: pageConstants.CHANGE_MODES_ERROR,
                    payload: { 'error': e }
                })
            })
    }
}

export function uploadContent(token, data, id) {
    return function (dispatch) {
        var progress = {
            uploadFile: 0,
            uploadFileTotall: 0,
            done: false,
            message: '',
            file: data.file,
            id: id
        };
        dispatch({
            type: pageConstants.UPLOAD_CONTENT_REQUEST
        })
        // api.uploadContent(data, token, id)
        axios.post('/api/content/upload', data, {
            headers: { "Authorization": `Token: ${token}` },
            onUploadProgress: progressEvent => {

                progress = {
                    uploadFile: progressEvent.loaded / (1024 * 1024),
                    uploadFileTotall: progressEvent.total / (1024 * 1024),
                    done: false,
                    message: 'Uploading...',
                    file: data.file,
                    id: id
                };

                store.dispatch({
                    type: pageConstants.UPLOAD_CONTENT_SUCCES,
                    payload: progress
                })
            }
        })
            .then(r => {
                if (r.status === 200) {
                    // progress.splice(id, 1);
                    progress.done = true;
                    progress.message = "Upload complete!"
                    store.dispatch({
                        type: pageConstants.UPLOAD_CONTENT_SUCCES,
                        payload: progress
                    })
                }
            })
            .catch(e => {
                progress.done = true;
                console.log('RESPONCE ERROR: ', e.message)
                if (e.response) {
                    progress.message = e.response.data;
                } else {
                    progress.message = e.message;
                }
                store.dispatch({
                    type: pageConstants.UPLOAD_CONTENT_ERROR,
                    payload: progress
                })
            })
    }
}

export function playNowFile(own, pid, fileid) {
    return function (dispatch) {
        dispatch({
            type: pageConstants.RELOAD_PLAYER
        })
        const socket = network.socket
        socket.emit('play', { 'playnow': 1, 'player': pid, 'data': fileid, 'ownid': own });
    }
}

export function uploadContentClear() {
    return function (dispatch) {
        dispatch({
            type: pageConstants.UPLOAD_CONTENT_CLEAR
        })
    }
}