import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AddToQueue from '@material-ui/icons/AddToQueue';
import Tooltip from '@material-ui/core/Tooltip';
import NewPlaylistMenu from './NewPlaylistMenu';
import i18next from 'i18next';

const styles = theme => ({
    roothead: {
        display: 'flex',
        backgroundColor: '#d1e4fc',
        marginBottom: '1rem',
        borderRadius: '4px',
        height: '3rem',
        alignItems: 'center'
      },
    type: {
        marginTop: '1rem',
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
        fontSize: '0.8rem',
        flex: '0 0 auto',
      },
    spacer: {
        flex: '1 1 100%',
      },
    head: {
        marginTop: '1rem',
        marginLeft: '1rem',
        height: '1.5rem',
        // fontSize: '0.8rem',
        flex: '0 0 auto',
      },
    button: {
        width: '1rem',
        // height: '2.4rem',
        borderRadius: '4px',
        minWidth: '2rem',
        // marginBottom: '0.2rem',
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
      },
    icon: {
        color: 'gray'
    }

});

class PlaylistHeader extends Component {
    
    render() {
        const { classes, handleDialog, handleAddAllPlaylistsToAllPlayers, handleSortPlaylistByType, sortPlaylists, handlePacketDialog, role } = this.props;

        return (
            <div className={classes.roothead}>
                <Tooltip title={i18next.t("Add_all_playlists_to_all_players")}>
                    <Button className={classes.button} onClick={handleAddAllPlaylistsToAllPlayers}>
                        <AddToQueue className={classes.icon}/>
                    </Button>
                </Tooltip>
                <div className={classes.spacer} />
                <div className={classes.head}>{i18next.t("Playlists")}</div>
                <div className={classes.spacer} />
                <Tooltip title={i18next.t("Show_Advertising_playlist")}>
                    <Button className={classes.button} onClick={handleSortPlaylistByType} id= {sortPlaylists == "all" ? "adv" : "all"}>
                        <a style={sortPlaylists !== "adv" ? {color: '#4660e27d'} : null}> A </a>
                    </Button>
                </Tooltip>
                <Tooltip title={i18next.t("Show_Content_playlist")}>
                    <Button className={classes.button} onClick={handleSortPlaylistByType} id={sortPlaylists == "all" ? "cont" : "all"}>
                        <a style={sortPlaylists !== "cont" ? {color: '#4660e27d'} : null}> C </a>
                    </Button>
                </Tooltip>
                <Tooltip title={i18next.t("Show_Packets_playlist")}>
                    <Button className={classes.button} onClick={handleSortPlaylistByType} id={sortPlaylists == "all" ? "pacs" : "all"}>
                        <a style={sortPlaylists !== "pacs" ? {color: '#4660e27d'} : null}> P </a>
                    </Button>
                </Tooltip>
                {/* <Tooltip title="Add playlist">
                    <Button className={classes.button} onClick={handleDialog}>
                        <AddIcon className={classes.icon}/>
                    </Button>
                </Tooltip> */}
                {role === 'listen'? null :
                <NewPlaylistMenu handleDialog = {handleDialog} handlePacketDialog = {handlePacketDialog}/>
                }
            </div>
        )
    }
}

export default withStyles(styles)(PlaylistHeader);