import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { CssBaseline, Typography, Paper, Grid, Button } from '@material-ui/core';
import { PATHS, ROOT_API_URL } from '../../constants/urls';
import i18next from 'i18next';


const styles = theme => ({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      marginTop: '6rem',
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '6rem',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    stepper: {
      padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing.unit * 3,
      marginLeft: theme.spacing.unit,
    },
    subtext: {
        color: 'gray'
    },
    headline: {
        marginBottom: '3rem'
    }
  });

class Download extends React.Component{

    render(){
        const { classes } = this.props;

        return(
            <React.Fragment>
                <CssBaseline />
                <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography variant="display1" align="center" className={classes.headline}>
                    About:
                    </Typography>
                    <Grid container spacing={24}>
                    <Typography variant="body1">
                    {i18next.t("About_text")}
                    </Typography>
                    </Grid>
                </Paper>
                </main>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Download)