import React from 'react';
import { connect } from 'react-redux';
import { Button, TextField, Typography, Grid, List, ListItem, ListItemText  } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';
import AlertDialog from '../AlertDialog';
import i18next from 'i18next';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
      },
    root: {
        flexGrow: 1,
        maxWidth: 752,
        minWidth: 500
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
    },
    dialog: {
        minWidth: 500
    }
});

class PlayerInfoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    getDataInfo(data, id) {
        var res = [];
        data.map(d => {
            if(d.id = id) {
                res = d.message;
            }
        })
        return res;
    }

    fileName(data) {

        if (data.FilesLoading !== undefined) {
            const files = JSON.parse(data.FilesLoading);
            return(
                files.map((file, key) => (
                    <ListItem key={key}>
                    <ListItemText
                      primary={file.name}
                      secondary={Math.round(file.size / (1024 * 1024)) + "Mb"}
                    />
                  </ListItem>
                ))
            )
        }
    }

    playlists(data) {
        
        if (data.Playlists !== undefined) {
            const playlists = JSON.parse(data.Playlists);
            console.log("PLAYLISTS>>> ", playlists.length)
            return(
                <div>
                {playlists.length > 0 ?
                    playlists.map((p, key) => (
                            <ListItem key={key}>
                                <ListItemText
                                primary={p.name}
                                secondary={p.type}
                                />
                            </ListItem>)
                    ) : <ListItem >
                            <ListItemText primary={"No playlist in player"}/>
                        </ListItem>}
                </div>
            
        );
    }
}
    nowPlaylist(data) {
        if (data.nowPlayPlaylist !== undefined ) {
            const nowPlayPlaylist = JSON.parse(data.nowPlayPlaylist);
            return(

                    <ListItem>
                        <ListItemText 
                            primary={nowPlayPlaylist ? nowPlayPlaylist.name : "No playlist in player"}
                            secondary={nowPlayPlaylist ? nowPlayPlaylist.type : null}
                        />
                    </ListItem> 
            )
        }
    }


    render() {
        const { classes, id, open, handleClose } = this.props;
        const { fullPlayerInfo } = this.props.page;

        if (fullPlayerInfo === undefined || fullPlayerInfo.lenght === 0) {
            return (
                <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                >
                    <DialogTitle className={classes.dialog} id="form-dialog-title">
                        {i18next.t("Player Info") }
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" className={classes.title}>
                            {i18next.t("No data!!!")} 
                        </Typography>
                    </DialogContent>
                </Dialog>
            )
        }
        const data = this.getDataInfo(fullPlayerInfo, id);

        return (
            <div className={classes.root}>
                <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                >
                    <DialogTitle className={classes.dialog} id="form-dialog-title">
                        {i18next.t("Player Info") }
                    </DialogTitle>
                    <DialogContent>
                    {/* <Grid container spacing={12}> */}
                    {/* </Grid> */}
                        {/* <Grid item xs={12} sm={6}> */}
                        <Typography variant="h6" className={classes.title}>
                            {i18next.t("Playlists in player")} 
                        </Typography>
                        <div className={classes.demo}>
                            <List dense>
                                {this.playlists(data)}
                            </List>
                        </div>
                            
                        {/* </Grid> */}
                        {/* <Grid item xs={12} sm={6}> */}
                        <Typography variant="h6" className={classes.title}>
                            {i18next.t("Files in player")} 
                        </Typography>
                        <div className={classes.demo}>
                            <List dense>
                                {this.fileName(data)}
                            </List>
                        </div>

                        {/* <Grid item xs={12} sm={6}> */}
                            <Typography variant="h6" className={classes.title}>
                                {i18next.t("Now play file")} 
                            </Typography>
                            <div className={classes.demo}>
                                <List dense>
                                    <ListItem>
                                        <ListItemText 
                                            primary={data.nowPlayFile}
                                        />
                                    </ListItem>
                                    
                                </List>
                            </div>
                        {/* </Grid> */}
                        {/* <Grid item xs={12} sm={6}> */}
                            <Typography variant="h6" className={classes.title}>
                                {i18next.t("Now play playlist")} 
                            </Typography>
                            <div className={classes.demo}>
                                <List dense>
                                    {this.nowPlaylist(data)}
                                    
                                </List>
                            </div>
                        {/* </Grid> */}
                    {/* </Grid> */}
                        
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.button} onClick={handleClose} color="primary">
                            {i18next.t("Close")}
                        </Button>
                    </DialogActions>
                    
                </Dialog>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page
    }
  }

export default (connect(mapStateToProps)(withStyles(styles)(PlayerInfoDialog)));