import React, { Component } from 'react';
import Snackbar from './Snackbar';
import * as pageActions from '../../store/actions/PageActions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
var totall = '';

class Snakes extends Component {


    handleRemoveSnack = (id) => {
        const { removeSnack } = this.props.pageActions;
        removeSnack(id)
    }

    handleRemoveProgress = (id) => {
        const { removeProgress } = this.props.pageActions;
        removeProgress(id)
    }

    render() {
        const message = this.props.page.snack;
        const progress = this.props.page.progress;

        return (
            <div>
            {message.slice(0, 10).map((m, i) => {
                if(m.variant === 'info' && m.message.split(" ").pop() === 'Completed'){
                    const mess = m.message.split(" ");
                    totall = 'Totall: ' + mess[0] + '/'+ mess[2]
                    
                }
                return(<Snackbar message={m.message} key={i} id={i} variant={m.variant} handleRemoveSnack={this.handleRemoveSnack}/>)
                    })
                }
            {progress.map((m, i) => {
                const n = i + 1
                if(m.message === ''){
                    m.message = 'Writing video ';
                }
                const mess = m.message + ' ' + Math.round(m.value / m.totall * 100) +'%. ' +  totall
                return(
                    <Snackbar message={mess} key={i} id={i} variant='progress' handleRemoveSnack={this.handleRemoveProgress}/>
                )
                })
            }
                
                
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      pageActions: bindActionCreators(pageActions, dispatch),
    //   userActions: bindActionCreators(userActions, dispatch)
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Snakes)