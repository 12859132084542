import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import * as userActions from '../../store/actions/UserActions';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PlaylistIcon from '@material-ui/icons/PlaylistPlay';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import PlaylistDialog from './playlistDialog';
import PauseIcon from '@material-ui/icons/Pause';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
import IconMinimize from '@material-ui/icons/Minimize';
import IconMaximize from '@material-ui/icons/Maximize';
import IconDownload from '@material-ui/icons/SaveAlt';
import i18next from 'i18next';
import AlertDialog from '../AlertDialog';
import PlayerDialog from './PlayerDialog';
import * as network from '../../services';
import UpdateIcon from '@material-ui/icons/Update';

var tkn = ''
const minDiscFreeSpace = 1024;

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '240px',
    maxWidth: '240px',
    marginRight: '0.5rem',
    marginBottom: '1rem'
    // paddingTop: '0.2rem'
  },
  mobile: {
    flexGrow: 1,
    width: window.screen.width > 450 ? '240px' : window.screen.width - 20,
    marginBottom: '1rem',
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
  },
  card: {
    // display: 'flex',
    // alignItems: 'baseline',
    paddingTop: '35px',
    paddingRight: 'inherit',
    // order: 1,
  },
  details: {
    // display: 'flex',
    flexDirection: 'column',
    paddingRight: '20px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
    // paddingTop: '21px'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    alignSelf: 'center'
  },
  contentbtn: {
    display: 'flex',
    // alignSelf: 'flex-end'
  },
  cover: {
    width: 151,
  },
  controls: {
    // display: 'flex',
    alignSelf: 'center',
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  container: {
    display: 'flex',
    height: '100%',
    // paddingTop: '15px',
    flexDirection: 'column'
  },
  containercenter: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerdown: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    height: '100%'
  },
  button: {
    padding: 3,
    borderRadius: '4px',
    minWidth: '10px'
  },
  comment: {
    fontSize: '15px',
    color: 'gray',
    paddingTop: '3px',
  },
  gearicon: {
    color: 'gray',
    paddingTop: '-20px',
    width: '1.1rem'
  },
  playlisticonactive: {
    color: 'blue',
    paddingTop: '-20px',
    width: '1.1rem'
  },
  playlisticonnotactive: {
    color: 'lightgray',
    paddingTop: '-20px',
    width: '1.1rem'
  },
  id: {
    fontSize: '10px',
    color: 'gray',
    textAlign: 'left',
    // paddingLeft: '10px',
    paddingLeft: '0.5rem',
    paddingTop: '0.4rem',
    flex: '0 0 auto',
  },
  counter: {
    textAlign: 'center',
    fontSize: '15px',
    color: 'gray'
  },
  duration: {
    fontSize: '10px',
    color: 'gray'
  },
  error: {
    fontSize: '14px',
    color: 'red',
    textAlign: 'center'
  },
  spacer: {
    flex: '1 1 100%',
  },
  stateicons: {
    paddingBottom: '5px',
    paddingRight: '5px',
    fontSize: 'small',
  }
});

const plistTarget = {
  drop(props, monitor, component) {
    const { own } = props.data;
    const item = monitor.getItem();
    const playlist = {
      playlist: { content: props.data.playlist.content, advertising: props.data.playlist.advertising },
      id: props.data.id
    };
    try {
      // Checking the mode and rights of the player
      if (item.playlist.type === "pacs" || item.playlist.type === "mixed") {
        if (props.data.mode < 300) {
          props.showError({ error: "Error adding playlist. Check you player mode!" });
          return;
        }
      }
      playlist.playlist.content.push(item.playlist.id);
      props.socket.emit('play', { 'playlists': 1, 'player': props.isGroupPlayers ? 'all' : props.data.mac, 'data': playlist.playlist, 'ownid': own });
    } catch (error) {
      // console.log('UiControl - Target - Push error!');
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    item: monitor.getItem(),
  }
}

class UiControl extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      isGroupPlayers: props.isGroupPlayers,
      ismarque: false,
      alert: false,
      pldialogopen: false,
      pldialognotsave: true,
      playerData: props.data,
      listenerid: props.listenerid === undefined ? "" : props.listenerid,
      isSetLstn: false,
      id: this.props.user.role === 'listen' ? this.props.user.parent : this.props.user.id,
      titleMessage: "",
      isRecive: false,
    };
  }

  componentDidMount() {
    const { mac } = this.props.data;
    const id = this.props.user.role === 'listen' ? this.props.user.parent : this.props.user.id;
    const data = { ownid: id, player: mac };
    this.props.socket.emit('getplayersettings', data);

  }

  componentWillReceiveProps() {
    const { isGroupPlayers } = this.props;
    const { isRecive } = this.state;

    this.setState({
      isGroupPlayers: isGroupPlayers,
    });
  }

  handlePlaylistDialog = () => {
    this.setState({ open: !this.state.open });
  }

  handleAlertClose() {
    this.setState({
      alert: false
    })
  }

  handleClearPlaylistsInPlayer() {
    this.setState({
      alert: true
    })
  }

  handleClearPlaylistsInPlayerConfirm() {
    const { own, mac } = this.props.data;
    this.handleAlertClose();
    this.props.socket.emit('play', {
      'playlists': 1, 'player': mac, 'data': {
        'content': [], 'advertising': []
      }, 'ownid': own
    });

  }

  handleMouseEnter() {
    this.setState({
      ismarque: true
    });
  }

  handleMouseLeave() {
    this.setState({
      ismarque: false
    });
  }

  handlePlayerDialog() {
    this.setState({
      pldialognotsave: !this.state.pldialogopen ? true : false,
      pldialogopen: !(this.state.pldialogopen),
      titleMessage: this.props.data.setings.titleessage
    });
  }
  handlePlayerDialogSave(data) {
    console.log("Handle Player Dialog Save - ", data);
    // const { id } = this.props.user;
    const { isSetLstn, id, playerData } = this.state;
    this.setState({
      pldialogopen: !(this.state.pldialogopen)
    });
    playerData.setings.title_message = this.state.titleMessage;
    const newData = {
      name: playerData.name,
      comment: playerData.comment,
      setings: playerData.setings,

    }

    network.socket.emit('play', { 'setings': 1, 'player': this.state.isGroupPlayers ? 'all' : playerData.mac, 'data': newData, 'ownid': id });
    if (isSetLstn) {
      this.setListenerId();
    }

    // this.props.updatePlayer(this.props.token, data.mac, newData)
  }

  setListenerId() {
    const { listenerid, listenerid_old } = this.state;
    const { id } = this.props.data;
    const { token, listeners, role } = this.props.user;
    const { setListenerIds } = this.props.userActions;
    var uid;
    var lstn = [];
    listeners.map(l => {
      if (l.username === listenerid && l.listenid != null || l.username === listenerid_old) {
        l.listenid.map(n => {
          lstn.push(n);
        });
        uid = l.id;
      }
    });
    const i = lstn.indexOf(id);
    if (listenerid !== "") {
      if (i < 0) {
        lstn.push(id);
      }
    } else {
      lstn.splice(i, 1);
      listeners.map(l => {
        if (l.username === listenerid_old) {
          uid = l.id;
        }
      });
      this.setState({ listenerid_old: "" });
    }
    const data = {
      listenids: lstn,
      p_id: uid
    };

    setListenerIds(token, data);
  }

  handlePlayerChangeText = name => e => {
    this.setState({
      pldialognotsave: false,

      playerData: {
        ... this.state.playerData,
        [name]: e.target.value
      }
    })
  }

  handleChangePlayerSttings = event => {
    this.setState({
      pldialognotsave: false,
      playerData: {
        ...this.state.playerData,
        setings: {
          ...this.state.playerData.setings,
          [event.target.name]: event.target.value
        }
      }
    })
  }

  handlePlayerChangeTitleMessage = name => e => {
    this.setState({
      pldialognotsave: false,
      titleMessage: e.target.value,
    })
  }

  handlePlayerChangeSwitch = name => e => {
    const { id } = this.state;
    const socket = network.socket;
    var checked = e.target.checked;
    if (name === "title_size") {
      checked = checked ? 1 : 0
    }
    const newState = {
      ...this.state.playerData,
      setings: {
        ... this.state.playerData.setings,
        [name]: checked,
        ['title_message']: this.props.data.setings.titleessage
      }
    }
    this.setState({ playerData: newState, pldialognotsave: false })
    socket.emit('play', { 'setings': 1, 'player': this.state.isGroupPlayers ? 'all' : this.props.data.mac, 'data': newState.setings, 'ownid': id })
  }

  handlePlayerDialogDelete(data) {
    const { pageActions, user } = this.props;
    pageActions.deletePlayer(user.token, data.id);
    this.setState({
      pldialogopen: !this.state.pldialogopen
    });
  }

  handleChange = event => {
    this.setState({ [event.target.name + '_old']: this.state[event.target.name], [event.target.name]: event.target.value, pldialognotsave: false, isSetLstn: true });

  };

  handleSaveEnable = () => {
    this.setState({
      pldialognotsave: false,

    })
  }

  render() {
    const { classes, theme, handleButton, connectDropTarget, isMobile, isCompact, handleCompact, index } = this.props;
    var { data } = this.props;
    const { freediscspace } = this.props.data.setings;
    const { audiovolume } = this.props.data;
    const { open, ismarque, alert, pldialogopen, pldialognotsave, playerData, titleMessage, listenerid } = this.state;
    const { playlists, latestVersion } = this.props.page;
    const { token, role } = this.props.user;
    tkn = token;

    if (data.file && data.file.split('____')[1]) {
      data.file = data.file.split('____')[1]
    }

    data.comment = data.comment.replace("_", " ");

    if (!data.playlist.content) {
      data.playlist.content = [];
    }

    return connectDropTarget(
      <div>
        {isCompact ?
          <div>
            <Card className={isMobile ? classes.mobile : classes.root} style={freediscspace < minDiscFreeSpace ? { background: '#ff000053', height: 65 } : { height: 65 }}>
              <div className={classes.container}>
                <div className={classes.contentbtn}>
                  <div className={classes.id}> {data.name} </div>
                  <div className={classes.spacer}></div>
                  {data.P_name ?
                    <Tooltip title={
                      <React.Fragment>
                        <div>{i18next.t("Download")}</div>
                        <div>{i18next.t("File_name")} {data.P_name}</div>
                        <div>{i18next.t("complete")} {Math.round(data.P_now / (1024 * 1024))}Mb / {Math.round(data.P_max / (1024 * 1024))}Mb</div>
                      </React.Fragment>
                    }>
                      <IconButton className={classes.button}>
                        <IconDownload className={classes.gearicon} style={{ color: 'green' }} />
                      </IconButton>
                    </Tooltip>
                    : null
                  }
                  <Tooltip title={i18next.t("Playlists")}>
                    <IconButton className={classes.button} size='medium'>
                      <PlaylistIcon className={data.playlist.content.length > 0 ? classes.playlisticonactive : classes.playlisticonnotactive} onClick={this.handlePlaylistDialog} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={i18next.t("Settings")}>
                    <IconButton className={classes.button} onClick={this.handlePlayerDialog.bind(this)} id={data.id} mac={data.mac} data={data}>
                      <SettingsIcon className={classes.gearicon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={i18next.t("Maximize_all_players")}>
                    <IconButton className={classes.button}>
                      <IconMaximize className={classes.gearicon} onClick={handleCompact.bind(this)} id={index} />
                    </IconButton>
                  </Tooltip>

                </div>

                <div className={classes.container} >
                  <div className={classes.counter}>{data.online ? null :
                    <div className={classes.error}>
                      {i18next.t("Player_is_Offline")}
                    </div>
                  }</div>
                  {data.online ?
                    <div className={classes.content}>
                      <p className={classes.duration}>{Math.floor(data.duration / 3600)}:{('0' + Math.floor(data.duration / 60 % 60)).slice(-2)}:{('0' + Math.floor(data.duration % 60)).slice(-2)} </p>
                      <input type="range" value={data.duration} min="0" max={data.totalld} step="1" onChange={handleButton.bind(this)} id={"playbtn" + data.mac} />
                      <p className={classes.duration}> {Math.floor(data.totalld / 3600)}:{('0' + Math.floor(data.totalld / 60 % 60)).slice(-2)}:{('0' + Math.floor(data.totalld % 60)).slice(-2)} </p>
                    </div> : null}
                </div>
              </div>
            </Card>
          </div> :
          <div >
            <Card className={isMobile ? classes.mobile : classes.root} style={freediscspace < minDiscFreeSpace ? { background: '#ff000053', height: 220 } : { height: 220 }}>
              <div className={classes.container}>
                <div className={classes.contentbtn}>
                  <div className={classes.id}> {data.name} </div>
                  <div className={classes.spacer}></div>
                  {data.P_name ?
                    <Tooltip title={
                      <React.Fragment>
                        <div>{i18next.t("Download")}</div>
                        <div>{i18next.t("File_name")} {data.P_name}</div>
                        <div>{i18next.t("complete")} {Math.round(data.P_now / (1024 * 1024))}Mb / {Math.round(data.P_max / (1024 * 1024))}Mb</div>
                      </React.Fragment>
                    }>
                      <IconButton className={classes.button}>
                        <IconDownload className={classes.gearicon} style={{ color: 'green' }} />
                      </IconButton>
                    </Tooltip>
                    : null
                  }
                  <Tooltip title={i18next.t("Playlists")}>
                    <IconButton className={classes.button} size='medium'>
                      <PlaylistIcon className={data.playlist.content.length > 0 ? classes.playlisticonactive : classes.playlisticonnotactive} onClick={this.handlePlaylistDialog.bind(this)} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={i18next.t("Settings")}>
                    <IconButton className={classes.button} onClick={this.handlePlayerDialog.bind(this)} id={data.id} mac={data.mac} data={data}>
                      <SettingsIcon className={classes.gearicon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={i18next.t("Minimize_all_players")}>
                    <IconButton className={classes.button}>
                      <IconMinimize className={classes.gearicon} onClick={handleCompact.bind(this)} id={index} />
                    </IconButton>
                  </Tooltip>


                </div>


                <CardContent className={classes.card}>
                  {data.online ?
                    <div>
                      <div onMouseEnter={this.handleMouseEnter.bind(this)} onMouseLeave={this.handleMouseLeave.bind(this)} >
                        {ismarque ?
                          <marquee behavior="alternate" scrollamount="1" width='200px' className={classes.details}> {data.file}</marquee> : <div className={classes.details} style={{ paddingBottom: '0.2rem' }}> {data.file} </div>}
                      </div>
                      <div className={classes.comment} >{data.comment}</div>
                    </div> :
                    <div className={classes.error}>
                      {i18next.t("Player_is_Offline")}
                    </div>
                  }

                </CardContent>

                <div className={classes.containercenter} >
                  <div className={classes.counter}></div>
                  {data.online ?
                    <div className={classes.content}>
                      <p className={classes.duration}>{Math.floor(data.duration / 3600)}:{('0' + Math.floor(data.duration / 60 % 60)).slice(-2)}:{('0' + Math.floor(data.duration % 60)).slice(-2)} </p>
                      <input type="range" value={data.duration} min="0" max={data.totalld} step="1" onChange={handleButton.bind(this)} id={"playbtn" + data.mac} />
                      <p className={classes.duration}> {Math.floor(data.totalld / 3600)}:{('0' + Math.floor(data.totalld / 60 % 60)).slice(-2)}:{('0' + Math.floor(data.totalld % 60)).slice(-2)} </p>
                    </div> : null}
                </div>
                <div className={classes.container} style={{ height: '40px' }}>
                  {data.online ?
                    <div className={classes.controls}>
                      <IconButton aria-label="Previous" onClick={handleButton.bind(this)} id={"prevbtn" + data.mac} >
                        {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
                      </IconButton>
                      <IconButton aria-label="Play/pause" onClick={handleButton.bind(this)} id={"playbtn" + data.mac}>
                        {data.status ? <PauseIcon className={classes.playIcon} /> : <PlayArrowIcon className={classes.playIcon} />}
                      </IconButton>
                      <IconButton aria-label="Next" onClick={handleButton.bind(this)} id={"nextbtn" + data.mac}>
                        {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
                      </IconButton>
                    </div>
                    : null}
                </div>
                <div className={classes.containerdown} >
                  {latestVersion === data.setings.VersionName ? null :
                    <Tooltip title={i18next.t("update")}>
                      <div className={classes.stateicons} style={{ color: 'red' }}>
                        <UpdateIcon style={{ fontSize: "15px" }} />
                      </div>
                    </Tooltip>}
                </div>
              </div>
            </Card>
          </div>}
        <PlaylistDialog open={open} handlePlaylistDialog={this.handlePlaylistDialog.bind(this)} data={data} playlists={playlists} handleClearPlaylistsInPlayer={this.handleClearPlaylistsInPlayer.bind(this)} role={role} />
        <AlertDialog alert={i18next.t("Warning")} message={i18next.t("Confirm_clear_playlists")} open={alert} handleClose={this.handleAlertClose.bind(this)} handleAgree={this.handleClearPlaylistsInPlayerConfirm.bind(this)} />
        <PlayerDialog
          open={pldialogopen}
          handlePlayerDialog={this.handlePlayerDialog.bind(this)}
          setings={data.setings}
          playerdata={playerData}
          handlePlayerDialogSave={this.handlePlayerDialogSave.bind(this)}
          handlePlayerChangeText={this.handlePlayerChangeText.bind(this)}
          handlePlayerChangeSwitch={this.handlePlayerChangeSwitch.bind(this)}
          handlePlayerDialogDelete={this.handlePlayerDialogDelete.bind(this)}
          handlePlayerChangeTitleMessage={this.handlePlayerChangeTitleMessage.bind(this)}
          pldialognotsave={pldialognotsave}
          audioVolume={audiovolume}
          titleMessage={titleMessage}
          handleChange={this.handleChange.bind(this)}
          listenerid={listenerid}
          handleChangePlayerSttings={this.handleChangePlayerSttings.bind(this)}
        />
      </div>
    );
  }
}

UiControl.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    page: state.page,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  }
}


export default DropTarget('PLAYLIST', plistTarget, collect)(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(UiControl)));
