import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import i18next from 'i18next';

class ChangePassword extends React.Component {
    state = {
        passwd: "",
        newpasswd1: "",
        newpasswd2: "",
        ok: true
    }

    handleChangeOk() {
        const { changeUserPassword, token, handleChangeClose } = this.props;
        const data = {
            login: this.props.username,
            passwd: this.state.passwd,
            newpasswd1: this.state.newpasswd1,
            newpasswd2: this.state.newpasswd2,
        }

        changeUserPassword(token, data)
        handleChangeClose()
    }

    handleChangeInput = e => {
        e.preventDefault();
        // if (this.props.error) clearUserErrors();
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            ok: this.state.passwd !== "" && this.state.newpasswd1 !== "" && this.state.newpasswd2 !== "" ? false : true
        });

    };

    checkOk() {
        const { passwd, newpasswd1, newpasswd2 } = this.state;
        if (passwd !== "" && newpasswd1 !== "" && newpasswd1 == newpasswd2 && passwd !== newpasswd1) {
            this.setState({
                ok: false
            });
        } else {
            this.setState({
                ok: true
            });
        }
    }

    render() {

        const { open, handleChangeClose } = this.props;
        const { ok } = this.state;

        return (
            <Dialog
                open={open}
                onClose={handleChangeClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogTitle id="change-password-dialog-title">
                    {i18next.t("Change_User_Password")}
                </DialogTitle>
                <DialogContent>
                    <form onChange={this.handleChangeInput}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="passwd">{i18next.t("Old_password")}</InputLabel>
                            <Input
                                name="passwd"
                                type="password"
                                id="passwd"
                                required
                            // autoComplete="current-password"
                            />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="newpasswd1">{i18next.t("New_Password")}</InputLabel>
                            <Input
                                name="newpasswd1"
                                type="password"
                                id="newpasswd1"
                                required
                            // autoComplete="current-password"
                            />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="newpasswd2">{i18next.t("Confirm_new_password")}</InputLabel>
                            <Input
                                name="newpasswd2"
                                type="password"
                                id="newpasswd2"
                                required
                            // autoComplete="current-password"
                            />
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleChangeOk.bind(this)} color="primary" autoFocus disabled={ok}>
                        {i18next.t("Ok")}
                    </Button>
                    <Button onClick={handleChangeClose} color="primary" autoFocus>
                        {i18next.t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ChangePassword;