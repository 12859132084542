import * as userConstants from '../../constants/User';
import api from '../../sources/api';
import { getContent } from './PageActions';
import { func } from 'prop-types';
import user from '../reducers/user';

export function getUserAuthorization(username, passwd) {

    return function (dispatch) {
        dispatch({
            type: userConstants.GET_USER_AUTHORIZATION_REQUEST
        })

        api.authorizeUser(username, passwd)
            .then(r => {
                if (r.status === 200) {
                    dispatch({
                        type: userConstants.GET_USER_AUTHORIZATION_SUCCESS,
                        payload: r.data
                    })

                } else {
                    dispatch({
                        type: userConstants.GET_USER_AUTHORIZATION_ERROR,
                        payload: { error: 'Authentication error!' }
                    })
                }
            })
    }
}

export function getUserData(token) {
    return function (dispatch) {
        dispatch({
            type: userConstants.GET_USER_DATA_REQUEST
        })

        api.getUserData(token)
            .then(r => {
                if (r.status === 200) {
                    dispatch({
                        type: userConstants.GET_USER_DATA_SUCCESS,
                        payload: r.data
                    })
                }
            })

            .catch(e => {
                dispatch({
                    type: userConstants.GET_USER_DATA_ERROR,
                    payload: { error: e }
                })
            })

    }
}

export function uploadLogo(token, data) {
    return function (dispatch) {
        dispatch({
            type: userConstants.UPLOAD_LOGO_REQUEST
        })

        api.uploadLogo(token, data)
            .then(r => {
                if (r.status === 200) {
                    dispatch({
                        type: userConstants.UPLOAD_LOGO_SUCCESS
                    });

                }
                return Promise.resolve();
            })
            .catch((error) => {
                if (!error) { error = "Upload logo error!" }
                dispatch({
                    type: userConstants.UPLOAD_LOGO_ERROR,
                    payload: { error: error }
                })
            })
    }
}

export function getLogo(token) {
    return function (dispatch) {
        dispatch({
            type: userConstants.GET_LOGO_REQUEST
        })

        api.getLogo(token)
            .then(r => {
                if (r.status === 200) {
                    const b = Buffer.from(r.data, 'binary').toString('base64');
                    dispatch({
                        type: userConstants.GET_LOGO_SUCCESS,
                        payload: b
                    })
                }
            })
            .catch(e => {
                dispatch({
                    type: userConstants.GET_LOGO_ERROR,
                    payload: e
                })
            })
    }
}

export function logOut(token) {
    return function (dispatch) {
        dispatch({
            type: userConstants.LOG_OUT_REQUEST
        })
        api.logout(token)
            .then(r => {
                if (r.status === 200) {
                    dispatch({
                        type: userConstants.LOG_OUT_SUCCESS,
                    })
                }
            })
            .catch(e => {
                dispatch({
                    type: userConstants.LOG_OUT_ERROR,
                    payload: { error: e }
                })
            })
    }
}

export function clearUserError() {
    return function (dispatch) {
        dispatch({
            type: userConstants.CLEAR_USER_ERROR
        })
    }
}

export function registrationUser(data) {
    return function (dispatch) {
        dispatch({
            type: userConstants.REGISTRATION_USER_REQUEST
        })
        api.registerUser(data)
            .then(r => {
                if (r.status === 200) {
                    dispatch({
                        type: userConstants.REGISTRATION_USER_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(e => {
                dispatch({
                    type: userConstants.REGISTRATION_USER_ERROR,
                    payload: e
                })
            })
    }
}

export function registrationListener(token, data) {
    return function (dispatch) {
        dispatch({
            type: userConstants.REGISTRATION_LISTENER_REQUEST
        });
        api.registerListener(token, data)
            .then(r => {
                if (r.status === 200) {
                    dispatch({
                        type: userConstants.REGISTRATION_LISTENER_SUCCESS,
                        payload: r.data
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: userConstants.REGISTRATION_LISTENER_ERROR,
                    payload: e
                });
            });
    };
}

export function setListenerIds(token, data) {
    return function (dispatch) {
        dispatch({
            type: userConstants.SET_LISTEN_IDS_REQUEST
        });
        api.setListenIds(token, data)
            .then(r => {
                if (r.status === 200) {
                    dispatch({
                        type: userConstants.SET_LISTEN_IDS_SUCCES,
                        payload: r.data
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: userConstants.SET_LISTEN_IDS_ERROR,
                    payload: e
                });
            });
    };
}

export function changeUserPassword(token, data) {
    return function (dispatch) {
        dispatch({
            type: userConstants.CHANGE_USER_PASSWORD_REQUEST
        })
        api.changePassword(token, data)
            .then(r => {
                if (r.status === 200) {
                    dispatch({
                        type: userConstants.CHANGE_USER_PASSWORD_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(e => {
                dispatch({
                    type: userConstants.CHANGE_USER_PASSWORD_ERROR,
                    payload: e
                })
            })
    }
}

export function getModes(token) {
    return function (dispatch) {
        dispatch({
            type: userConstants.GET_MODES_REQUEST
        })
        api.getModes(token)
            .then(r => {
                if (r.status === 200) {
                    dispatch({
                        type: userConstants.GET_MODES_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(e => {
                dispatch({
                    type: userConstants.GET_MODES_ERROR,
                    payload: e
                })
            })
    }
}

export function confirmEmail() {
    return function (dispatch) {
        dispatch({
            type: userConstants.CONFIRM_EMAIL
        })
    }
}

export function sendConfirmMessage(token) {
    return function (dispatch) {
        dispatch({
            type: userConstants.SEND_CONFIRM_MESSAGE_REQUEST
        })
        api.sendConfirmMessage(token)
            .then(r => {
                if (r.status === 200) {
                    dispatch({
                        type: userConstants.SEND_CONFIRM_MESSAGE_SUCCESS,
                        payload: r.data
                    })
                }
            })
            .catch(e => {
                dispatch({
                    type: userConstants.SEND_CONFIRM_MESSAGE_ERROR,
                    payload: e
                })
            })
    }
}

export function deleteListenUser(token, data) {
    return function (dispatch) {
        dispatch({
            type: userConstants.DELETE_LISTENER_USER_REQUEST
        })
        var status = false;
        api.deleteListener(token, data)
            .then(r => {
                if (r.status === 200) {
                    status = true;
                }
            })
            .catch(e => {
                dispatch({
                    type: userConstants.DELETE_LISTENER_USER_ERROR,
                    payload: e
                })
            })
        if (status) {
            api.getUserData(token)
                .then(r => {
                    if (r.status === 200) {
                        dispatch({
                            type: userConstants.DELETE_LISTENER_USER_SUCCES,
                            payload: r.datda
                        })
                    }
                })
                .catch(e => {
                    dispatch({
                        type: userConstants.DELETE_LISTENER_USER_ERROR,
                        payload: e
                    })
                })
        }
    }

}

export function getpromotioncode(token) {
    return function (dispatch) {
        dispatch({
            type: userConstants.GET_PROMOTION_CODE_REQUEST
        })
        api.getpromotioncode(token)
            .then(resp => {
                if (resp.status === 200) {
                    dispatch({
                    type: userConstants.GET_PROMOTION_CODE_SUCCES,
                    payload: resp.data
                })
            }
        })
        .catch(e => {
            dispatch({
                type: userConstants.GET_PROMOTION_CODE_ERROR,
                payload: e
            })
        })
    }
}