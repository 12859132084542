import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import PlaylistShort from '../Playlists/PlaylistShort';
import i18next from 'i18next';
import { isMobile } from 'react-device-detect';

const styles = theme => ({
    // paper: {
    //     display: 'initial',
    // //   padding: theme.spacing.unit * 2,
    //   textAlign: 'center',
    //   color: theme.palette.text.secondary,
    // //   width: '100%',
    //   height: '15rem',
    //   minWidth: '15.5rem',
    // //   marginBottom: '1rem',
    // //   marginRight: '1rem'
    // margin: "0px"
    // },
    paperWidthSm: {
        width: isMobile ? window.innerWidth : window.innerWidth / 2,
        maxWidth: "650px",
        margin: "10px",
        minWidth: isMobile ? window.innerWidth - 50 : "650px"
    }
});



class PlaylistDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: [],
        };
    }

    checkPlaylist(data, playlists) {
        // console.log("checkPlayList => ", data, playlists);
        var plst = [];
        var d = [];
        try {
            if (data.playlist.content) {
                d = data.playlist.content;
            }
            if (playlists) {
                d.map(id => {
                    playlists.map(playlist => {
                        if (playlist.id === id) {
                            plst.push(playlist);
                        }
                    });
                });
            }
            // if (data.playlist.advertising) {
            //     d = data.playlist.advertising;
            // }
            // if (playlists) {
            //     d.map(id => {
            //         playlists.map(playlist => {
            //             if (playlist.id === id) {
            //                 plst.push(playlist);
            //             }
            //         });
            //     });
            // }
        } catch (e) {
            // console.log(e)
            return plst;
        }
        // console.log('>>>', data, playlists, plst)
        return plst;
    }

    render() {
        const { classes, open, handlePlaylistDialog, data, playlists, handleClearPlaylistsInPlayer, role } = this.props;
        var pl = this.checkPlaylist(data, playlists);

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handlePlaylistDialog}
                    aria-labelledby="Playlist-dialog-title"
                    // fullWidth={true}
                    // maxWidth={false}
                    // fullScreen={isMobile ? true : false}
                    classes={classes}
                >
                    <DialogTitle id="Playlist-dialog-title"> {i18next.t("Playlists")} </DialogTitle>
                    {pl.length > 0 ?
                        <DialogContent>
                            {pl.map((p, key) => {
                                return (
                                    <div key={key} className={classes.paper}>
                                        <PlaylistShort playlist={p} nowPlayning={data.file} short={true} plplaylist={data.playlist} mac={data.mac} nowPlst={data.playlistnowplayning} playerid={data.id} isMobile={isMobile} />
                                    </div>
                                )
                            })}
                        </DialogContent> : <DialogContent>
                            {i18next.t("Dialog_no_playlist_in_player")}
                        </DialogContent>}
                    <DialogActions>
                        {pl.length > 0 && role !== 'listen' ?
                            <Button onClick={handleClearPlaylistsInPlayer} color="primary">
                                {i18next.t("Clear")}
                            </Button> : null}
                        <Button onClick={handlePlaylistDialog} color="primary">
                            {i18next.t("Close")}
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
};

export default withStyles(styles)(PlaylistDialog);