import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { DragSource } from 'react-dnd';
import Typography from '@material-ui/core/Typography';
import i18next from 'i18next';

const styles = theme => ({
  root: {
    width: '20%',
    // maxWidth: 360,
    marginTop: theme.spacing.unit * 3,
    backgroundColor: theme.palette.background.paper,
    marginLeft: 'auto',
    marginRight: '1rem',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis'
  },
  title: {
    //   flex: 'auto 0 auto',
    marginLeft: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    // backgroundColor: 'lightgray'
  },
  filename: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',

  },
  filenameHover: {

  },
  header: {
    //   backgroundColor: 'lightgray'
  },
  hover: {
    '&:hover': {
      backgroundColor: '#d1e4fc !important',
      wordWrap: 'break-word !important',
    }

  },
  nohover: {

  },
  stream: {
    // backgroundColor: 'red',

  },
  avatar: {
    backgroundColor: 'lightblue',
    borderRadius: '20% !important',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  avatarBig: {
    backgroundColor: 'lightblue',
    borderRadius: '10% !important',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '6rem',
    height: '6rem',
    position: 'absolute',
    left: '0rem',
    zIndex: 1,
    borderStyle: 'groove'
    // right: '-15rem'
  },
  avatarBigHuge: {
    backgroundColor: 'lightblue',
    borderRadius: '10% !important',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '12rem',
    height: '12rem',
    position: 'absolute',
    left: '0rem',
    zIndex: 3,
    borderStyle: 'groove'
    // right: '-15rem'
  },
  openAvatarNormal: {
    height: 100,
    width: 100
  },
  openAvanerHuge: {
    height: 200,
    width: 200
  },
  iFrame: {
    // display: 'flex',
    // position: 'absolute',
    left: '0rem',
    top: '0rem'
  }
});

const itemSource = {
  // canDrag(props) {
  //   return props.isReady
  // },

  // isDragging(props, monitor) {
  //   return monitor.getItem().id === props.id;
  // },

  beginDrag(props, monitor, component) {
    // console.log('Dragging???', props)
    const item = { id: props.item.id };
    return item
  },

  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }
    // const item = monitor.getItem();

    // const dropResult = monitor.getDropResult();

    // This is a good place to call some Flux action
    // console.log('Card Action - ', props);
  }
};

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging()
  };
}

class File extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hugeAvatar: false
    }
  }

  handleHugeAvatar = () => {
    this.setState({
      hugeAvatar: !this.state.hugeAvatar
    })
  }

  render() {
    const { classes, item, connectDragSource, id, isMouseOver, handleClick } = this.props;
    const { hugeAvatar } = this.state;
    const name = item.name.split('/');
    var isStream = false;
    var isHover = false;
    if (name[0].toLowerCase() === 'http:' || name[0].toLowerCase() === 'https:') { isStream = true; }
    if (isMouseOver.hover && isMouseOver.id === item.id) { isHover = true; } else { isHover = false; }
    // console.log('Name: ', name[0].toLowerCase())

    return connectDragSource(
      <div>
        <ListItem >
          {isHover ? <Avatar onClick={this.handleHugeAvatar} variant="rounded" className={hugeAvatar ? classes.avatarBigHuge : classes.avatarBig}>
            {isStream ? <a className={classes.stream}>{i18next.t("Stream")}</a> : <img src={`https://revip.org/api/ui/getpreviewgif/${id}/${name[name.length - 1]}`} className={hugeAvatar ? classes.openAvanerHuge : classes.openAvatarNormal} alt={name} />}
          </Avatar> : null}
          <Avatar variant="rounded" className={classes.avatar}>
            {isStream ? <a className={classes.stream}>S</a> : <img src={`https://revip.org/api/ui/getpreviewjpeg/${id}/${name[name.length - 1]}`} height="42" width="42" alt='' />}
          </Avatar>

          <ListItemText onClick={event => handleClick(event, item)}
            primary={<Typography variant="subtitle2" >{
              isHover ?
                <div>
                  <marquee behavior="alternate" scrollamount="1" width={window.innerWidth / 7.2}>
                    {item.name}
                  </marquee> </div> : <div>{item.name}</div>

            }</Typography>}

            secondary={item.comments} className={classes.filename} />
        </ListItem>
        <Divider />
      </div>
    )
  }
}

export default DragSource('FILE', itemSource, collect)(withStyles(styles)(File));