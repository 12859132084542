import * as pageConstant from '../../constants/Page';
import { modifyFiles } from '../../components/Files/uploadFiles.utils';
import { version } from 'react';

const initialState = {
    players: [],
    content: [],
    playlists: [],
    pacsplaylists: [],
    fetching: false,
    loaded: false,
    error: '',
    messages: [],
    progress: [],
    fileProgress: {
    },
    logs: [],
    logids: [],
    fullPlayerInfo: [],
    snack: [],
    uploads: []
};

export default function page(state = initialState, action) {

    switch (action.type) {
        case pageConstant.SET_PLAYER:
            return { ...state, players: action.payload }

        case pageConstant.SET_PLAY_LIST:
            return { ...state, playlist: action.payload }

        case pageConstant.GET_CONTENT_REQUEST:
            return { ...state, fetching: true, loaded: false }

        case pageConstant.GET_CONTENT_SUCCESS:
            return { ...state, content: action.payload, fetching: false, loaded: true }

        case pageConstant.GET_PLAYERS_REQUEST:
            return { ...state, fetching: true, loaded: false }

        case pageConstant.GET_PLAYERS_SUCCESS:
            var data = { ...state, players: action.payload, fetching: false, loaded: true }
            if (action.payload[0].version) { data.latestVersion = action.payload[0].version };
            return data

        case pageConstant.SET_POSITION:
            const pl = action.payload[0];
            const newposition = state.players.map((player, i) => {
                if (player.id === pl.id) {
                    player.duration = pl.duration;
                    player.totalld = pl.totalld;
                    player.status = pl.status
                    player.online = pl.online
                    if (pl.P_name) {
                        player.P_name = pl.P_name;
                        player.P_max = pl.P_max;
                        player.P_now = pl.P_now;
                    }
                }
                return player
            })
            return { ...state, players: newposition }

        case pageConstant.SET_POSITION_FULL:
            const plf = action.payload;
            const newpositionf = state.players.map((player, i) => {
                plf.map((pl, i) => {
                    if (player.id === pl.id) {
                        let settings = { ...player.setings, ...pl.setings }
                        player = pl
                        player.setings = settings;
                        if (player.setings.title_message) {
                            player.setings.titleessage = player.setings.title_message
                        }
                    }

                }
                )
                // console.log(">>>", player)
                return player
            })

            return { ...state, players: newpositionf }

        case pageConstant.SET_PROGRESS:
            var { progress, messages } = state;
            if (!action.payload.id) { return { ...state } }
            if (action.payload.message === 'done') {
                delete progress[action.payload.id]
                messages.push({
                    file: action.payload.file,
                    message: action.payload.message,
                    error: action.payload.error
                })

                return { ...state, messages, progress: progress }
            }
            else {
                progress[action.payload.id] = {
                    value: action.payload.value,
                    totall: action.payload.totall,
                    message: action.payload.message,
                    id: action.payload.id,
                    bar: action.payload.bar,
                }

                return { ...state, progress: progress }
            }

        case pageConstant.GET_POSITION:
            return { ...state, players: action.players }

        case pageConstant.DELETE_CONTENT_SUCCESS:
            return { ...state, content: action.payload, fetching: false, loaded: true }

        case pageConstant.DELETE_CONTENT_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.DELETE_CONTENT_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.DELETE_STREAM_SUCCESS:
            return { ...state, content: action.payload, fetching: false, loaded: true }

        case pageConstant.DELETE_STREAM_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.DELETE_STREAM_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.RENAME_CONTENT_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.RENAME_CONTENT_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.RENAME_CONTENT_SUCCESS:
            return { ...state, content: action.payload, fetching: false, loaded: true }

        case pageConstant.SET_PLAYER_SUCCESS:
            return { ...state, players: [action.payload], fetching: false, loaded: true }

        case pageConstant.SET_PLAYER_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.DELETE_PLAYER_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.DELETE_PLAYER_SUCCESS:
            return { ...state, fetching: false, loaded: true, players: action.payload }

        case pageConstant.DELETE_PLAYER_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.GET_PLAYLISTS_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.GET_PLAYLISTS_SUCCESS:
            return { ...state, fetching: false, loaded: true, playlists: action.payload }

        case pageConstant.GET_PLAYLISTS_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.EDIT_PLAYLISTS_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.EDIT_PLAYLISTS_SUCCESS:
            return { ...state, fetching: false, loaded: true, playlists: action.payload }

        case pageConstant.EDIT_PLAYLISTS_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.CREATE_PLAYLISTS_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.CREATE_PLAYLISTS_SUCCESS:
            return { ...state, fetching: false, loaded: true, playlists: action.payload }

        case pageConstant.CREATE_PLAYLISTS_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.CHECK_PACS_PLAYLIST_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.CHECK_PACS_PLAYLIST_SUCCESS:
            return { ...state, fetching: false, loaded: true, playlists: action.payload }

        case pageConstant.CHECK_PACS_PLAYLIST_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.GET_PACS_PLAYLISTS_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.GET_PACS_PLAYLISTS_SUCCESS:
            return { ...state, fetching: false, loaded: true, pacsplaylists: action.payload }

        case pageConstant.GET_PACS_PLAYLISTS_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.SET_PACS_PLAYLISTS_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.SET_PACS_PLAYLISTS_SUCCESS:
            return { ...state, fetching: false, loaded: true, playlists: action.payload }

        case pageConstant.SET_PACS_PLAYLISTS_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.DELETE_PLAYLISTS_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.DELETE_PLAYLISTS_SUCCESS:
            return { ...state, fetching: false, loaded: true, playlists: action.payload }

        case pageConstant.DELETE_PLAYLISTS_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.ADD_PLAYLIST_TO_PLAYER_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.ADD_PLAYLIST_TO_PLAYER_SUCCESS:
            return { ...state, fetching: false, loaded: true }

        case pageConstant.ADD_PLAYLIST_TO_PLAYER_REQUEST:
            return { ...state, fetching: true }

        // case "LOG_OUT_SUCCESS":
        //     window.location.reload();
        //     return initialState

        case pageConstant.SET_UPLOAD_FILES:
            // console.log("REDUCER => ", action.payload[0], "State FileProgress => ", state.fileProgress, "Modify Files: ", modifyFiles(state.fileProgress, action.payload))
            return {
                ...state, fileProgress: {
                    ...state.fileProgress,
                    ...modifyFiles(state.fileProgress, action.payload[0]),
                }
            }

        case pageConstant.CLEAR_PAGE_ERROR:
            return { ...state, error: "" }

        case pageConstant.GET_LOGS_SUCCESS:
            return { ...state, logs: action.payload }

        case pageConstant.GET_LOGS_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.GET_LOGS_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.GET_LOG_IDS_SUCCESS:
            return { ...state, logids: action.payload }

        case pageConstant.GET_LOG_IDS_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.GET_LOG_IDS_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.SET_FULL_PLAYER_INFO:
            return { ...state, fullPlayerInfo: action.payload }

        case pageConstant.SHOW_ERROR:
            console.log("REDUCER- ERROR", action.payload)
            return { ...state, error: action.payload.error }

        case pageConstant.ADD_SNACK:
            // console.log(action.payload)
            state.snack.push(action.payload)
            return { ...state, snack: state.snack }

        case pageConstant.REMOVE_SNACK:
            state.snack.splice(action.payload, 1);
            return { ...state, snack: state.snack }

        case pageConstant.REMOVE_PROGRESS:
            state.progress.splice(action.payload, 1);
            return { ...state, progress: state.progress }

        case pageConstant.SHOW_PROGRESS:
            state.progress[action.payload.id].isshow = action.payload.state
            return { ...state, progress: state.progress }

        case pageConstant.CHANGE_MODES_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.CHANGE_MODES_SUCCES:
            return { ...state, players: action.payload, fetching: false, loaded: true }

        case pageConstant.CHANGE_MODES_ERROR:
            return { ...state, fetching: true, loaded: true, error: action.payload.error }

        case pageConstant.PLAY_NOW_FILE:
            return { ...state }

        case pageConstant.UPLOAD_CONTENT_REQUEST:
            return { ...state, fetching: true }

        case pageConstant.UPLOAD_CONTENT_SUCCES:
            state.uploads[action.payload.id] = action.payload
            // if(action.payload.done) { state.uploads.splice(action.payload.id, 1)}
            return { ...state, uploads: state.uploads }

        case pageConstant.UPLOAD_CONTENT_ERROR:
            state.uploads[action.payload.id] = action.payload
            // if(action.payload.done) { state.uploads.splice(action.payload.id, 1)}
            return { ...state, uploads: state.uploads }

        case pageConstant.UPLOAD_CONTENT_CLEAR:
            state.uploads = []
            return { ...state, uploads: state.uploads }

        default:
            return state
    }
}