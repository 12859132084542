import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { PATHS } from '../../constants/urls';
import Register from './Register';
import AppBar from '../Header/AppBarZerro';
import Footer from '../Footer/Footer';
import i18next from 'i18next';
import logo from '../../img/logo2.1.png';



let styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  register: {
    fontSize: '10px',
    color: 'gray',
    marginTop: '1rem',
    alignSelf: 'flex-end',
  },
  logo: {
    width: '50px',
    paddingBottom: '10px'
  }
});

class SignIn extends React.Component {


  static propTypes = { loading: PropTypes.bool.isRequired, error: PropTypes.string };

  state = {
    login: '',
    password: '',
    loginpage: false,
  };


  componentWillUnmount = () => {
    // clearUserErrors();
  };

  handleChangeInput = e => {
    e.preventDefault();
    // if (this.props.error) clearUserErrors();
    const { name, value } = e.target;
    this.setState({ [name]: value });

  };

  handleSubmiteForm = e => {
    e.preventDefault();
    const { login, password } = this.state;
    const { getUserAuthorization, getState } = this.props;
    getUserAuthorization(login, password)

  };

  handleRegister() {

    this.setState({
      loginpage: !this.state.loginpage,
    })
  };

  render() {
    const { classes, loading, error, lang } = this.props;
    const { login, passwd1, passwd2, loginpage } = this.state;

    if (loginpage) {
      return (
        <Register handleRegister={this.handleRegister.bind(this)} />
      )
    }

    return (
      <React.Fragment>
        <AppBar lang={lang} />
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {/* <Avatar className={classes.avatar}> */}
              {/* <LockIcon /> */}
              <img src={logo} alt='Logo' className={classes.logo} />
            {/* </Avatar> */}
            <Typography variant="headline" >{i18next.t("Sign_in")}</Typography>
            <form className={classes.form} onChange={this.handleChangeInput} onSubmit={this.handleSubmiteForm}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">{i18next.t("Login")}</InputLabel>
                <Input id="email" name="login" autoComplete="username" autoFocus />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">{i18next.t("Password")}</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </FormControl>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={i18next.t("Remember_me")}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {i18next.t("Sign_in")}
              </Button>
            </form>

            <Button className={classes.register} onClick={this.handleRegister.bind(this)}>
              {i18next.t("Register_new_user")}
            </Button>

          </Paper>
        </main>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SignIn);