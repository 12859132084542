import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
  listItem: {
    padding: `${theme.spacing.unit}px 0`,
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing.unit * 2,
  },
});

class UserUsage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
  const { classes, user, page } = this.props;

  return (
    <div>
      <Typography variant="title" gutterBottom>
        Usage summary
      </Typography>
      <List disablePadding>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total players usage" />
          <Typography variant="body2" className={classes.total}>
            {page.players.length} &nbsp;thing{page.players.length > 1? <a>s</a> : null}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Maximum players" />
          <Typography variant="body2" className={classes.total}>
            {user.maximumplayers} 
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total data space" />
          <Typography variant="body2" className={classes.total}>
            {user.maxsize} Mb
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary="All usage data" />
          <Typography variant="body2" className={classes.total}>
            {Math.round(user.totallFilesSize / (1024 * 1024))} Mb
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Free data space" />
          <Typography variant="body2" className={classes.total}>
            {Math.round(user.freespace / (1024 * 1024))} Mb
          </Typography>
        </ListItem>
      </List>
      </div>
  )
}
}


UserUsage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserUsage);