import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { size, toArray } from 'lodash';
import UploadItem from './UploadItem';
import i18next from 'i18next';




const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  error: {
    color: 'red'
  },
  uploadfiles: {
    paddingTop: '2rem'
  },
  table: {
    minWidth: "90%",
  },
  row: {
    maxWidth: "50%"
  },
  infoText: {
    paddingLeft: '5rem',
    paddingBottom: '1rem'
  },
  paperWidthSm: {
    maxWidth: '60%'
  }
});

function isEmptyArray(arr) {
  var ret = false;
  if (arr) {
    arr.map(a => {
      if (a !== null) {
        ret = true;
      }
    });
  }
  return ret;
}

class UploadDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      uploadFile: 0,
      uploadFileTotall: 0,
      disabledUp: true,
      disabled: false,
      error: '',
      perc: 0,
      comment: '',
      files: [],
      comments: [],
      progress: [],
      show: false,
      upload: false,
      isConverterProgressStarted: false,
      converts: [],
      duration: []
    };
  }
  componentWillReceiveProps() {
    const converterProgress = this.props.page.progress;
    this.setState({ isConverterProgressStarted: isEmptyArray(converterProgress) });
    const { show, isConverterProgressStarted, converts } = this.state;
    const progress = this.props.page.uploads.filter(e => { return e.done === false; });
    if (isConverterProgressStarted && converts.length === 0 && progress.length === 0 && show) {
      this.handleClose();
      this.props.pageActions.uploadContentClear();
      this.setState({ upload: false });
      // this.props.getContent(this.props.token);
    } else if (isEmptyArray(progress) || isConverterProgressStarted || progress.length > 0) {
      // this.setState({ upload: true });
    }

  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleComment = (e) => {
    const { comments } = this.state;
    comments[e.currentTarget.id] = e.currentTarget.value;
    this.setState({ comments });
  }

  handleDuration = (e) => {
    const { duration } = this.state;
    duration[e.currentTarget.id] = e.currentTarget.value;
    this.setState({ duration });
  }

  handleConvert = (e) => {
    const { converts } = this.state;
    converts[e.currentTarget.id] = e.currentTarget.checked;
    this.setState({
      converts
    })
    // console.log('CONVERT>>>', converts)
  }

  handleClose = () => {
    this.props.handleDialog();
    this.clear();
  };

  handleChange = (e) => {
    e.preventDefault();
    var filename = e.currentTarget.value.split("\\")
    const files = e.target.files;
    const arrFile = [];
    for (let i = 0; i < files.length; i++) {
      arrFile.push(files[i])
    }
    // console.log("ARRAY => ", arrFile, 'FILES => ', files)
    this.props.pageActions.setUploadFiles([arrFile])
    this.setState({
      disabledUp: !this.state.disabledUp,
      filename: filename[filename.length - 1],
      files: [arrFile],
    })
    e.target.value = null;
  }

  clear = () => {
    this.setState({
      open: false,
      uploadFile: 0,
      uploadFileTotall: 0,
      disabledUp: true,
      disabled: false,
      // error: '',
      files: [],
      comments: [],
      progress: [],
      show: false,
      upload: false,
      convert: false,
      duration: 15
    });
  };
  handleChangeCancel = () => {
    this.setState({
      disabled: !this.state.disabled
    });
  };

  handleUpload = (e) => {
    e.preventDefault();
    this.setState({
      disabledUp: !this.state.disabledUp,
      upload: true
    })
    const { files, comments, converts, duration } = this.state;
    const { uploadContent } = this.props.pageActions;
    const { token } = this.props;
    var { progress } = this.state;
    this.handleChangeCancel()
    // console.log('COMMENTS => ', comments);
    files[0].map((file, id) => {
      const data = new FormData();
      const comment = comments[id];
      const convert = converts[id] === undefined ? false : converts[id];
      const dur = duration[id] === undefined ? 15 : duration[id];
      data.append('file', file);
      data.append('comment', comment);
      data.append('id', id);
      data.append('convert', convert);
      data.append('duration', dur);
      uploadContent(token, data, id);

    })
    this.setState({ show: true });
  };

  render() {
    const { open, classes, user } = this.props;
    const { fileProgress } = this.props.page;
    const progress = this.props.page.uploads;
    const converterProgress = this.props.page.progress;
    const { uploadFile, uploadFileTotall, disabled, disabledUp, error, files, comments, upload, converts, duration } = this.state;
    const uploadedFileAmount = size(files[0]);
    var { perc } = this.state;
    perc = (uploadFile / uploadFileTotall) * 100;

    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{i18next.t("Upload_video_file")}</DialogTitle>
          <DialogContent>
            {/* <Dropzone onDrop={(files) => this.onDrop(files)}> */}
            {/* <div>>Try dropping some files here, or click to select files to upload.</div> */}

            <DialogContentText>
              {i18next.t("Dialog_Upload_a_video_file_1")}  {user.freespace / (1024 * 1024 * 1024) <= 0 ? <a className={classes.error}> {(user.freespace / (1024 * 1024 * 1024)).toFixed(3)} </a> : <a>{(user.freespace / (1024 * 1024 * 1024)).toFixed(3)}</a>}  {i18next.t("Dialog_Upload_a_video_file_2")}
            </DialogContentText>
            <DialogContentText className={classes.uploadfiles}>
              {uploadedFileAmount > 0 && uploadedFileAmount <= 10 ?
                <div>{size(files[0]) ? toArray(files[0]).map((file, id) => (
                  <div key={id}>
                    <UploadItem file={file} upload={upload} convert={converts[id]} comments={comments} progress={progress} converterProgress={converterProgress} key={id} id={id} handleComment={this.handleComment} handleConvert={this.handleConvert} handleDuration={this.handleDuration} duration={duration[id]} />
                  </div>
                )) : null} </div>
                : null
              }
            </DialogContentText>
            {uploadedFileAmount > 10 ? <DialogContent>
              <div className={classes.infoText} style={{ color: 'red' }}>{i18next.t("Error_Upload_dialog")}</div>
            </DialogContent> : null}
            {upload && uploadedFileAmount === 0 ? <DialogContent>
              <div className={classes.infoText}>{i18next.t("Currently_loading")}</div>
              {progress
                .filter(e => { return e.done === false })
                .map((p, i) => (
                  <LinearProgress key={i} variant='determinate' value={Math.round((p.uploadFile / p.uploadFileTotall) * 100)} />
                ))}
            </DialogContent> : null}
            {/* </Dropzone> */}

            {error ? <a className={classes.error}>{error}</a> : <br />}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" disabled={disabled}>
              {i18next.t("Cancel")}
            </Button>
            {!upload && uploadedFileAmount <= 10 && user.freespace / (1024 * 1024 * 1024) > 0 ? <div>
              {disabledUp ?
                <div>
                  <input accept="video/quicktime, video/mp4, video/avi, video/mkv, image/jpg, image/png, audio/mp3"
                    className={classes.input}
                    type='file'
                    multiple
                    name='userfile'
                    id='userfile'
                    ref={(ref) => { this.uploadInput = ref; }}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="userfile">
                    <Button color="primary" component="span" className={classes.button} disabled={upload}>
                      {i18next.t("Choose_file")}
                    </Button>
                  </label>
                </div>
                :
                <Button onClick={this.handleUpload} color="primary" disabled={disabledUp}>
                  {i18next.t("Upload")}
                </Button>}</div> : null}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    page: state.page
  }
}

function mapDispatchToProps(dispatch) {
  return {
    pageActions: bindActionCreators(pageActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UploadDialog));