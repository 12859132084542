import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from '../../store/actions/UserActions';
import * as languageActions from '../../store/actions/LanguageAction';
import i18next from 'i18next';
import { Button } from '@material-ui/core';

const styles = theme => ({
    button: {
        minWidth: '50px'
    }
})

class Lang extends Component {

    componentWillMount() {
        var lang = window.localStorage.getItem("language");
        if (lang == null) {
            lang = "en";
        }
        this.setState({
            lang: lang
        });
       
        this.setLanguage(lang);
        
    }

    setLanguage(lang) {
        i18next.init({
            lng: lang,
            resources: require(`./${lang}`)
        });

        this.props.languageActions.changeLanguage(i18next);
        window.localStorage.setItem("language", lang);
    }

    render() {
        const { lang } = this.state;
        const { classes } = this.props;

        return (
            <div>
           
                <Button className={classes.button}>
                    <div onClick={this.setLanguage.bind(this, 'en')} style={lang === "en" ? {color: "lightblue"} : null}>En</div>
                </Button>
                <Button className={classes.button}>
                    <div onClick={this.setLanguage.bind(this, 'ru')} style={lang === "ru" ? {color: "lightblue"} : null}>Ru</div>
                </Button>
                <Button className={classes.button}>
                    <div onClick={this.setLanguage.bind(this, 'sp')} style={lang === "sp" ? {color: "lightblue"} : null}>Sp</div>
                </Button>
                <Button className={classes.button}>
                    <div onClick={this.setLanguage.bind(this, 'cn')} style={lang === "cn" ? {color: "lightblue"} : null}>Ch</div>
                </Button>
                
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        language: state.language
    }
  }

function mapDispatchToProps(dispatch) {
    return {
      userActions: bindActionCreators(userActions, dispatch),
      languageActions: bindActionCreators(languageActions, dispatch)
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Lang));