import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core';
import Packets from './Packets';
import i18next from 'i18next';

const styles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing.unit,
    },
    withoutLabel: {
      marginTop: theme.spacing.unit * 3,
    },
    textField: {
      flexBasis: 200,
    },
    ranges: {
      width: '70px',
    },
    rangesDay: {
      width: '150px'
    },
    nameField: {
      width: '200px'
    },
    typeField: {
      width: '150px'
    },
    text: {
      marginTop: '2rem',
      marginBottom: '1rem'
    },
    formgroup: {
      marginTop: '0.5rem',
      marginLeft: '0.5rem',
    }
  });

class AddPacetsDialog extends React.Component {
    constructor(props) {
        super(props);

        this.setState = {
            open: false
        }
    }


    render() {
        const { classes, open, handlePacketDialog, pacsplaylists, content, token, setPacsPlaylists, playlists, getContent } = this.props;

        return (
            <div className={classes.root}>
                <Dialog
                    open={open}
                    onClose={handlePacketDialog}
                    aria-labelledby="form-dialog-title"
                    maxWidth="sm"
                >
                <DialogTitle id="form-dialog-title">{i18next.t("Chose_Packet_Playlists")}</DialogTitle>
                <DialogContent>
                    {pacsplaylists.map( (pacs, key) => {
                        return <Packets key={key} pacs={pacs} content={content} token = {token} setPacsPlaylists = {setPacsPlaylists} playlists = {playlists} getContent={getContent}/>
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePacketDialog} color="primary">
                        {i18next.t("Close")}
                    </Button>
                </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(styles)(AddPacetsDialog);