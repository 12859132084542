import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import page from './page';
import user from './user';
import language from './language';

const userPersistConfig = {
    key: 'user',
    storage: storage,
    blacklist: ['logo']
}

export default combineReducers({
    page,
    user,
    language
});
