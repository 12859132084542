import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import i18next from 'i18next';

class NewListener extends React.Component {
    state = {
        username: "",
        newpasswd1: "",
        newpasswd2: "",
        ok: true
    }


    handleChangeOk() {
        const { registrationListener, token, handleChangeClose, email } = this.props;
        const data = {
            login: email,
            fullname: this.state.username,
            passwd1: this.state.newpasswd1,
            passwd2: this.state.newpasswd2,
        }

        registrationListener(token, data)
        handleChangeClose()
    }

    handleChangeInput = e => {
        e.preventDefault();
        // if (this.props.error) clearUserErrors();
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            ok: this.state.fullname !== "" && this.state.newpasswd1 !== "" && this.state.newpasswd2 !== "" ? false : true
        });

    };

    checkOk() {
        const { username, newpasswd1, newpasswd2 } = this.state;
        if (username !== "" && newpasswd1 !== "" && newpasswd1 == newpasswd2) {
            this.setState({
                ok: false
            });
        } else {
            this.setState({
                ok: true
            });
        }
    }

    render() {

        const { open, handleChangeClose } = this.props;
        const { ok } = this.state;

        return (
            <Dialog
                open={open}
                onClose={handleChangeClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}

            >
                <DialogTitle id="create-listener-dialog-title">
                    {i18next.t("Create_listener")}
                </DialogTitle>
                <DialogContent>
                    <form onChange={this.handleChangeInput} >
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="username">{i18next.t("Login")}</InputLabel>
                            <Input
                                name="username"
                                id="username"
                                required
                            />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="newpasswd1">{i18next.t("Password")}</InputLabel>
                            <Input
                                name="newpasswd1"
                                type="password"
                                id="newpasswd1"
                                required
                            />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="newpasswd2">{i18next.t("Confirm_password")}</InputLabel>
                            <Input
                                name="newpasswd2"
                                type="password"
                                id="newpasswd2"
                                required
                            />
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleChangeOk.bind(this)} color="primary" autoFocus disabled={ok}>
                        {i18next.t("Ok")}
                    </Button>
                    <Button onClick={handleChangeClose} color="primary" autoFocus>
                        {i18next.t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default NewListener;