import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Lang from '../components/lang/lang';
import i18next from 'i18next';

const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  link: {
    textdecoration: 'none'
  }
};

var menu = ['Main', 'Downloads', 'About', 'Logout']
var isBuildMenu = false;

class TemporaryDrawer extends React.Component {
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };
  componentDidMount() {
    // this.getMenuItems();
  }

  getMenuItems(role) {
    // console.log('GET MENU', role)
    if (role !== undefined && role !== '') {
      if (role !== 'listen' && !isBuildMenu) {
        menu.splice(3, 0, 'Settings');
      }
      if (role === 'admin' && !isBuildMenu) {
        menu.splice(3, 0, 'Log');
      }

      isBuildMenu = true;
    }
    return menu
  }

  render() {
    const { classes, toggleDrawer, drawer, role } = this.props;


    const sideList = (
      <div className={classes.list}>
        <Divider />
        <List>
          {this.getMenuItems(role).map((text, index) => (

            <ListItem button key={i18next.t(text)} >
              <Link to={`/${text.toLowerCase()}`}>
                {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                <ListItemText primary={i18next.t(text)} />
              </Link>
            </ListItem>
          ))}
          {/* {role === "admin" ? 
          <ListItem button key="Logs" >
            <Link to="/log">
            
            <ListItemText primary="Logs" />
            </Link>
          </ListItem>
          : null} */}
          <ListItem>
            <Lang />
          </ListItem>
        </List>
        <Divider />
      </div>
    );

    return (
      <div>
        <Drawer open={drawer} onClose={toggleDrawer(false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TemporaryDrawer);