import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import * as Utils from '../../services/utils';
import i18next from 'i18next';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  textField: {
    flexBasis: 200,
  },
  ranges: {
    width: '70px',
  },
  ratio: {
    width: '30px'
  },
  ratioLabel: {
    paddingTop: '20px',
    display: 'inline-block',
    paddingLeft: '10px',
  },
  rangesDay: {
    width: '150px'
  },
  nameField: {
    width: '200px'
  },
  typeField: {
    width: '150px'
  },
  text: {
    marginTop: '2rem',
    marginBottom: '1rem'
  },
  formgroup: {
    marginTop: '0.5rem',
    marginLeft: '0.5rem',
  }
});

function formatDate(day, hour, minute) {
  return String(day + ':' + hour + ':' + minute)
}

class NewPlaylistDialog extends React.Component {

  initState() {
    const state = {
      name: "New_playlist_name",
      type: 'content',
      repeat: 'true',
      startDay: '00',
      startHour: '25',
      startMinute: '00',
      stopDay: '00',
      stopHour: '25',
      stopMinute: '00',
      shuffle: false,
      fadeout: false,
      pl1: "",
      pl2: "",
      r_to: 1,
      r_from: 1,
    }
    return state;
  }

  constructor(props) {
    super(props);

    this.state = this.initState();
  }

  state = {
    open: false,
  };



  componentDidMount() {
    const { role } = this.props;
  }
  // componentWillMount() {
  //   const { role } = this.props;

  // }

  handleSave = () => {
    const { name, repeat, startDay, startHour, startMinute, stopDay, stopHour, stopMinute, type, shuffle, fadeout, pl1, pl2, r_to, r_from } = this.state

    const { token, id } = this.props

    const playlists = {
      "type": "content",
      "name": i18next.t("new_name"),
      "start": "00:00:00",
      "stop": "00:00:00",
      "repeat": true,
      "files": [],
      "shuffle": this.state.shuffle,
      "fadeout": this.state.fadeout,
      "mixplaylist1": pl1,
      "mixplaylist2": pl2,
      "ratio": r_from + ":" + r_to,
    }

    playlists.name = name
    playlists.start = formatDate(startDay, startHour, startMinute)
    playlists.stop = formatDate(stopDay, stopHour, stopMinute)
    playlists.type = type
    playlists.shuffle = shuffle
    playlists.fadeout = fadeout
    // playlists.repeat = repeat
    this.props.handleNewPlaylistDialog()
    this.props.createPlaylist(token, playlists, id)
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleChangeRatio = prop => event => {
    if (event.target.value < 1) {
      this.setState({ [prop]: 1 });
    } else {
      this.setState({ [prop]: Math.trunc(event.target.value) });
    }
  }

  handleChangeSwitch = name => e => {
    this.setState({ [name]: !this.state[name] });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    const init = this.initState();
    this.setState(init);
    this.setState({ open: false });
  };

  render() {
    const { classes, role, playlists } = this.props;

    var typeRanges = Utils.typeRanges();

    const DayRanges = Utils.dayRanges();

    if (role === "admin") {
      typeRanges.push({
        value: 'pacs',
        label: i18next.t("Packets"),
      });
    }

    return (
      <div className={classes.root}>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleNewPlaylistDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{i18next.t("Create_New_Playlist")}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={i18next.t("Name_playlist")}
              type="text"
              value={i18next.t(this.state.name)}
              onChange={this.handleChange('name')}
              className={classNames(classes.margin, classes.textField, classes.nameField)}
            />
            <TextField
              select
              label={i18next.t("Type")}
              className={classNames(classes.margin, classes.textField, classes.typeField)}
              value={this.state.type}
              onChange={this.handleChange('type')}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            >{typeRanges.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            </TextField>
            <DialogContentText className={classes.text}>
              {i18next.t("Start_time_range")}
            </DialogContentText>
            <TextField
              select
              label={i18next.t("StartDay")}
              className={classNames(classes.margin, classes.textField, classes.rangesDay)}
              value={this.state.startDay}
              onChange={this.handleChange('startDay')}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            >{DayRanges.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            </TextField>
            <TextField
              select
              label={i18next.t("StartHour")}
              className={classNames(classes.margin, classes.textField, classes.ranges)}
              value={this.state.startHour}
              onChange={this.handleChange('startHour')}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            >{Utils.ArrayGenerate(24).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            </TextField>
            <TextField
              select
              label={i18next.t("startMinute")}
              className={classNames(classes.margin, classes.textField, classes.ranges)}
              value={this.state.startMinute}
              onChange={this.handleChange('startMinute')}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            >{Utils.ArrayGenerate(60).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            </TextField>
            {this.state.type !== "advertising" ?
              <div>
                <DialogContentText className={classes.text}>
                  {i18next.t("Stop_time_range")}
                </DialogContentText>
                <TextField
                  select
                  label={i18next.t("StopDay")}
                  className={classNames(classes.margin, classes.textField, classes.rangesDay)}
                  value={this.state.stopDay}
                  onChange={this.handleChange('stopDay')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                >{DayRanges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
                </TextField>
                <TextField
                  select
                  label={i18next.t("SoptHour")}
                  className={classNames(classes.margin, classes.textField, classes.ranges)}
                  value={this.state.stopHour}
                  onChange={this.handleChange('stopHour')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                >{Utils.ArrayGenerate(24).map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
                </TextField>
                <TextField
                  select
                  label={i18next.t("stopMinute")}
                  className={classNames(classes.margin, classes.textField, classes.ranges)}
                  value={this.state.stopMinute}
                  onChange={this.handleChange('stopMinute')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                >{Utils.ArrayGenerate(60).map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
                </TextField>
              </div> : null}

            {this.state.type === "mixed" ?
              <div>
                <DialogContentText className={classes.text}>
                  {i18next.t("Mixed_options")}
                </DialogContentText>
                <div>
                  <TextField
                    select
                    label={i18next.t("Playlist") + " 1"}
                    className={classNames(classes.margin, classes.textField, classes.rangesDay)}
                    value={this.state.pl1}
                    onChange={this.handleChange('pl1')}
                    required={true}
                  // InputProps={{
                  //   startAdornment: <InputAdornment position="start"></InputAdornment>,
                  // }}
                  >{playlists.map(playlist => (
                    playlist.type !== "advertising" && playlist.id !== this.state.pl2 ?
                      <MenuItem key={playlist.name} value={playlist.id} >
                        {playlist.name}
                      </MenuItem> : null
                  ))}
                  </TextField>
                  <TextField
                    select
                    label={i18next.t("Playlist") + " 2"}
                    className={classNames(classes.margin, classes.textField, classes.rangesDay)}
                    value={this.state.pl2}
                    onChange={this.handleChange('pl2')}
                    required={true}
                  // InputProps={{
                  //   startAdornment: <InputAdornment position="start"></InputAdornment>,
                  // }}
                  >{playlists.map(playlist => (
                    playlist.type !== "advertising" && playlist.id !== this.state.pl1 ?
                      <MenuItem key={playlist.id} value={playlist.id}>
                        {playlist.name}
                      </MenuItem> : null
                  ))}
                  </TextField>
                </div>
                <div>
                  <a className={classes.ratioLabel}>{i18next.t("Ratio")}: </a>
                  <TextField
                    id="ratio_from"
                    // label="from"
                    value={this.state.r_from}
                    onChange={this.handleChangeRatio('r_from')}
                    type="number"
                    className={classNames(classes.margin, classes.textField, classes.ratio)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                  />
                  <a>:</a>
                  <TextField
                    id="ratio_to"
                    // label="to"
                    value={this.state.r_to}
                    onChange={this.handleChangeRatio('r_to')}
                    type="number"
                    className={classNames(classes.margin, classes.textField, classes.ratio)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                  />
                </div>
              </div>
              : null}

            <FormGroup row className={classes.formgroup}>
              {this.state.type !== "advertising" ?
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.shuffle}
                      onChange={this.handleChangeSwitch('shuffle')}
                      value={this.state.shuffle}
                    />
                  }
                  label={i18next.t("Shuffle")}
                /> : null}
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.fadeout}
                    onChange={this.handleChangeSwitch('fadeout')}
                    value={this.state.fadeout}
                  />
                }
                label={i18next.t("FadeOut")}
              />
            </FormGroup>


          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleNewPlaylistDialog} color="primary">
              {i18next.t("Cancel")}
            </Button>
            <Button onClick={this.handleSave} color="primary">
              {i18next.t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

NewPlaylistDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewPlaylistDialog);