export const GET_USER_AUTHORIZATION_REQUEST = 'GET_USER_AUTHORIZATION_REQUEST';
export const GET_USER_AUTHORIZATION_SUCCESS = 'GET_USER_AUTHORIZATION_SUCCESS';
export const GET_USER_AUTHORIZATION_ERROR = 'GET_USER_AUTHORIZATION_ERROR';
export const GET_USER_DATA_REQUEST = "GET_USER_DATA_REQUEST";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_ERROR = "GET_USER_DATA_ERROR";
export const LOG_OUT_REQUEST = "LOG_OUT_REQUEST";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_ERROR = "LOG_OUT_ERROR";
export const UPLOAD_LOGO_REQUEST = "UPLOAD_LOGO_REQUEST";
export const UPLOAD_LOGO_SUCCESS = "UPLOAD_LOGO_SUCCESS";
export const UPLOAD_LOGO_ERROR = "UPLOAD_LOGO_ERROR";
export const GET_LOGO_REQUEST = "GET_LOGO_REQUEST";
export const GET_LOGO_SUCCESS = "GET_LOGO_SUCCESS";
export const GET_LOGO_ERROR = "GET_LOGO_ERROR";
export const CLEAR_USER_ERROR = "CLEAR_USER_ERROR";
export const REGISTRATION_USER_REQUEST = "REGISTRATION_USER_REQUEST";
export const REGISTRATION_USER_SUCCESS = "REGISTRATION_USER_SUCCESS";
export const REGISTRATION_USER_ERROR = "REGISTRATION_USER_ERROR";
export const CHANGE_USER_PASSWORD_REQUEST = "CHANGE_USER_PASSWORD_REQUEST";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_ERROR = "CHANGE_USER_PASSWORD_ERROR";
export const GET_MODES_REQUEST = "GET_MODES_REQUEST";
export const GET_MODES_SUCCESS = "GET_MODES_SUCCESS";
export const GET_MODES_ERROR = "GET_MODES_ERROR";
export const CONFIRM_EMAIL = "CONFIRM_EMAIL";
export const SEND_CONFIRM_MESSAGE_REQUEST = "SEND_CONFIRM_MESSAGE_REQUEST";
export const SEND_CONFIRM_MESSAGE_SUCCESS = "SEND_CONFIRM_MESSAGE_SUCCESS";
export const SEND_CONFIRM_MESSAGE_ERROR = "SEND_CONFIRM_MESSAGE_ERROR";
export const REGISTRATION_LISTENER_REQUEST = "REGISTRATION_LISTENER_REQUEST";
export const REGISTRATION_LISTENER_SUCCESS = "REGISTRATION_LISTENER_SUCCESS";
export const REGISTRATION_LISTENER_ERROR = "REGISTRATION_LISTENER_ERROR";
export const SET_LISTEN_IDS_REQUEST = 'SET_LISTEN_IDS_REQUEST';
export const SET_LISTEN_IDS_SUCCES = 'SET_LISTEN_IDS_SUCCES';
export const SET_LISTEN_IDS_ERROR = 'SET_LISTEN_IDS_ERROR';
export const DELETE_LISTENER_USER_REQUEST = 'DELETE_LISTENER_USER_REQUEST';
export const DELETE_LISTENER_USER_SUCCES = 'DELETE_LISTENER_USER_SUCCES';
export const DELETE_LISTENER_USER_ERROR = 'DELETE_LISTENER_USER_ERROR';
export const GET_PROMOTION_CODE_REQUEST = 'GET_PROMOTION_CODE_REQUEST';
export const GET_PROMOTION_CODE_SUCCES = 'GET_PROMOTION_CODE_SUCCES';
export const GET_PROMOTION_CODE_ERROR = 'GET_PROMOTION_CODE_ERROR';