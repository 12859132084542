import React from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        paddingTop: '3rem'
    },
    table: {
        minWidth: 700,
    },
    date: {
        width: '15%'
    },
    id: {
        width: '5%'
    },
    tag: {
        width: '5%'
    },
    message: {
        // width: '40%'
    },
    button: {
        margin: theme.spacing.unit,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },

});

class Logs extends React.Component {
    state = {
        id: '*',
        type: '*',
        user: '*',
        labelWidth: 0,
        limit: 100,
        offset: 0,
        setOffset: 0,
        users: this.sortUserName(),
        players: this.props.page.logids,
        search: "",
        isSearch: false,
    };

    componentDidMount() {
        this.props.pageActions.getLogIds(this.props.user.token);

    }

    componentWillReceiveProps() {
        this.setState({
            users: this.sortUserName(),
            // players: this.props.page.logids,
        })
    }

    sortUserName() {
        var uname = [];
        this.props.page.logids.map(n => {
            if (!uname.includes(n.username)) {
                uname.push(n.username)
            }
        });
        return uname;
    }

    sortPlayers(user) {
        const { logids } = this.props.page;
        var players = [];
        if (user === "*") {
            return logids;
        }
        logids.map(n => {
            if (n.username == user) {
                players.push(n);
            }
        })
        return players;
    }

    handleChange = name => event => {
        if (name === "user") {
            this.setState({
                players: this.sortPlayers(event.target.value)
            })
        }
        this.setState({ [name]: event.target.value });
    };

    handleSubmit() {
        const data = {
            id: this.state.id,
            type: this.state.type,
            limit: this.state.limit,
            offset: this.state.offset
        }
        this.props.pageActions.getLogs(this.props.user.token, data);
    }

    handleClear() {

    }

    handleNext() {
        const { limit, offset } = this.state;
        this.setState({
            offset: offset + limit
        })
        this.handleSubmit()
    }

    handlePrev() {
        const { limit, offset } = this.state;
        var res = 0
        if (offset - limit > 0) {
            res = offset - limit
        }
        this.setState({
            offset: res
        })
        this.handleSubmit()
    }

    handleSearch() {
        this.setState({
            isSearch: !this.state.isSearch,
            search: ""
        })
    }

    handleChangeSearch = event => {
        this.setState({ search: event.target.value });
    };

    render() {
        const { classes } = this.props;
        const { logids, logs } = this.props.page;
        const { users, players, isSearch, search } = this.state;

        const ofst = [100, 200, 500, 1000]
        const typeLbl = ["Info", "Error"]
        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="select-user">User Name</InputLabel>
                                    <Select
                                        native
                                        value={this.state.user}
                                        onChange={this.handleChange('user')}
                                        inputProps={{
                                            name: 'user',
                                            id: 'user',
                                        }}
                                    >
                                        <option value="*">All</option>
                                        {users.map((v, key) => (
                                            <option key={key} value={v}>{v}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="select-id">Players</InputLabel>
                                    <Select
                                        native
                                        value={this.state.id}
                                        onChange={this.handleChange('id')}
                                        inputProps={{
                                            name: 'id',
                                            id: 'id',
                                        }}
                                    >
                                        <option value="*">All</option>
                                        {players.map((v, key) => (
                                            <option key={key} value={v.id}>{v.id} - {v.name}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="select-type">Type</InputLabel>
                                    <Select
                                        native
                                        value={this.state.type}
                                        onChange={this.handleChange('type')}
                                        inputProps={{
                                            name: 'type',
                                            id: 'type',
                                        }}
                                    >
                                        <option value="*">All</option>
                                        {typeLbl.map((v, key) => (
                                            <option key={key} value={v}>{v}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="select-type">Limit</InputLabel>
                                    <Select
                                        native
                                        value={this.state.limit}
                                        onChange={this.handleChange('limit')}
                                        inputProps={{
                                            name: 'limit',
                                            id: 'limit',
                                        }}
                                    >
                                        {/* <option value="*">All</option> */}
                                        {ofst.map((v, key) => (
                                            <option key={key} value={v}>{v}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <Button variant="contained" className={classes.button} onClick={this.handlePrev.bind(this)}>
                                    Prev {this.state.limit}
                                </Button>
                                <Button variant="contained" className={classes.button} onClick={this.handleNext.bind(this)}>
                                    Next {this.state.limit}
                                </Button>
                            </TableCell>
                            <TableCell align="right">
                                <Button variant="contained" className={classes.button} onClick={this.handleSubmit.bind(this)}>
                                    Submit
                                </Button>
                                <Button variant="contained" className={classes.button} onClick={this.handleClear.bind(this)}>
                                    Clear log
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <Table className={classes.table}>

                    <TableHead>

                        <TableRow>
                            <TableCell className={classes.date} align="center">Server Date</TableCell>
                            <TableCell className={classes.date} align="center">Player Date</TableCell>
                            <TableCell className={classes.id} align="center">ID</TableCell>
                            <TableCell className={classes.tag} align="center">TAG</TableCell>
                            <TableCell className={classes.message} align="center" > {isSearch ? <Input placeholder="Search" margin="normal" onChange={this.handleChangeSearch.bind(this)} /> : <div onClick={this.handleSearch.bind(this)}> Message </div>} </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs
                            .filter((e) => {
                                if (search === "") {
                                    return e.message
                                } else if (e.message.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                                    return e.message
                                }
                            })
                            .map((log, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {log.ctime.split('GMT', 1)[0]}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {log.date}
                                    </TableCell>
                                    <TableCell>{log.pid}</TableCell>
                                    <TableCell>{log.tag}</TableCell>
                                    <TableCell>{log.message}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Paper>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Logs)));