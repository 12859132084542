import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import logo from '../../img/logo2.1.png';


const styles = {
  root: {
    flexGrow: 1,
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: '1'
  },
  grow: {
    flexGrow: 1,
    'text-align': 'center',
  },
  logo: {
    marginLeft: -12,
    marginRight: 20,
  },
  button: {
    borderRadius: '4px'
  },
  avatar: {
    backgroundColor: 'white',
    borderRadius: '20% !important' ,
    fontWeight : 'bold',
    textAlign: 'center'
  },
  logo: {
    fontSize: '2.5rem',
    color: 'blue'
  }, 
  img: {
    width: '1.6rem'
  }
};


class ButtonAppBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        handleNewPlaylistDialog: false,
        handleUploadDialog: false,
        addstreamopen: false,
    }
}

  render() {
  const { classes, handleOpenMenu, user, role } = this.props;
  
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
            <MenuIcon onClick={handleOpenMenu}/>
          </IconButton>
          <Typography variant="title" color="inherit" className={classes.grow}>
            Remote Video Player
          </Typography>
          {role === "listen"? 
            <Avatar variant="rounded" className={classes.avatar}>
              {user.logo ? <img src={`data:image/png;base64,${user.logo}`} alt='' className={classes.img} /> : 
                <img src={logo} alt='' /> }
            </Avatar> :
          <IconButton className={classes.button} href="/user">
            <Avatar variant="rounded" className={classes.avatar}>
            {user.logo ? <img src={`data:image/png;base64,${user.logo}`} alt='' className={classes.img} /> : 
              <img src={logo} alt='' /> }
            </Avatar>
          </IconButton>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}};

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ButtonAppBar);