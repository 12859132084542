import React, { Component } from 'react';
import '../../../node_modules/video-react/dist/video-react.css'
import {
    Player,

} from 'video-react';
import api from '../../sources/api';

export default class PreviewPlayer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            video: null
        }
    }

    getFile = () => {
        const { preview } = this.props;
        api.getContentFile(preview.token, preview.filename)
            .then(responce => {
                if (responce.status === 200) {
                    // responce.blob()
                    console.log("RESPONCE DATA: ", responce)
                    this.setState({
                        video: responce.data
                    })
                }
            })
            // .then(blob => {
            //     const videoURL = URL.createObjectURL(blob);
            //     this.setState({
            //         video: videoURL
            //     })
            // })
            .catch(error => {
                console.error("Error fetching video", error);
            })
    }


    componentDidMount = () => {
        this.getFile()
    }



    render() {
        const { video } = this.state;
        const { preview } = this.props;
        const requestHeader = { "Authorization": `Token: ${preview.token}` }
        const url = `https://www.revip.org/api/content/${preview.filename}`

        return (
            <Player
                ref={player => {
                    this.player = player;
                }}
                videoId={preview.filename}
                autoPlay
            // poster={`https://revip.org/api/ui/getpreviewjpeg/${preview.own}/${preview.filename}`}
            >

                {/* <source src={video} /> */}

                <source src={video} />
                {/* <source src={`https://revip.org/api/ui/getpreviewgif/${preview.own}/${preview.filename}`} /> */}
            </Player>
        );
    }
};