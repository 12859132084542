import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import i18next from 'i18next';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    input: {
      display: 'none',
    },
    error: {
      color: 'red'
    }
  });

class AddStreamDialog extends React.Component {
    state = {
        open: false,
        link: '',
        comment: '',
        error: '',
        disabledUp: true,
    };
    

    handleClickOpen = () => {
        this.setState({ open: true });
      };
    
      handleTextChange = (e) => {
        this.setState({ [e.currentTarget.id] : e.currentTarget.value});
        this.handleChangeRight(e);
      }

      handleClose = () => {
        this.props.handleDialog();
        this.setState({
          open: false,
          disabledUp: true,
          disabled: false,
          error: '',
          perc: 0,
          comment: '',
          link: '',
        })
        this.clear();
      };

      handleChangeRight = (e) => {
          const valueStart = this.state.link.split("://");
        if (valueStart[0].toLowerCase() == "http" || valueStart[0].toLowerCase() == "https") {
            this.setState({disabledUp: false});
        } else {
            this.setState({disabledUp: true });
        }
        
      };

      clear = () => {
        this.setState({
          comment: 'No comment',
          link: 'http://'
        });
      };

      handleChangeCancel = () => {
        this.setState({
          disabled: !this.state.disabled
        });
      };

      handleSendLink = (e) => {
        e.preventDefault();
        this.setState({
            disabledUp: !this.state.disabledUp,
        });
        this.handleChangeCancel();
        const data = {
                link: this.state.link,
                comment: this.state.comment,
            }
        axios.post('/api/content/uploadstream', data, { headers: {"Authorization" : `Token: ${this.props.token}`}, onUploadProgress: e => {
            
            }
        })
        .then(r => {
            if (r.status === 200) {
                this.handleClose();
                this.props.getContent(this.props.token);
                this.clear();
            }
        })
        .catch(error => {
            if (error.response) {
                this.setState({error: error.response.statusText});
                this.handleChangeCancel();
                return;
            }
        })

      };

    render(){
        const { open, classes, user } = this.props;
        const { disabled, disabledUp, error, link } = this.state;

        return(
            <div>
                <Dialog 
                    fullWidth
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title" >
                <DialogTitle id="form-dialog-title">Add Stream Link</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {i18next.t("Insert_link_to_live_stream_source")}
                    </DialogContentText>
                    <TextField 
                        autoFocus
                        margin="dense"
                        id="link"
                        label="Link"
                        type="text"
                        defaultValue="http://"
                        fullWidth
                        ref={(ref) => {this.link = ref; }}
                        onChange={this.handleTextChange}
                    />
                    <TextField 
                        margin="dense"
                        id="comment"
                        label={i18next.t("Comment")}
                        type="text"
                        fullWidth
                        ref={(ref) => {this.comment = ref; }}
                        onChange={this.handleTextChange}
                    />
                    {<br></br>}
                    {<br></br>}
                    {error ? <a className={classes.error}>{i18next.t("ERROR")} {error}</a> : <br />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary" disabled={disabled}>
                        {i18next.t("Cancel")}
                    </Button>
                    <Button onClick={this.handleSendLink} color="primary" disabled={disabledUp}>
                        {i18next.t("Save")}
                    </Button>
                </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(styles)(AddStreamDialog);