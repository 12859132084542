import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as network from '../../services';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';
import TvOffIcon from '@material-ui/icons/TvOff';
import TvOnIcon from '@material-ui/icons/LiveTv';
import GroupWork from '@material-ui/icons/GroupWork';
import IconButton from '@material-ui/core/IconButton';
import IconMinimize from '@material-ui/icons/Minimize';
import IconMaximize from '@material-ui/icons/Maximize';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';
import UIcontrol from './UIcontrol';
import i18next from 'i18next';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '40%'
    },
    header: {
        display: 'flex',
        backgroundColor: '#d1e4fc',
        marginRight: '0.4rem',
        marginBottom: '1rem',
        height: '3rem',
        borderRadius: '4px'
    },
    header2: {
        // display: 'flex',
        backgroundColor: '#d1e4fc',
        // marginRight: '0.4rem',
        marginBottom: '1rem',
        height: '3rem',
        lineHeight: '3',
        color: '#737373',
        borderRadius: '4px',
        fontSize: '1rem',
        flex: '0 0 auto',
    },
    title: {
        //   flex: 'auto 0 auto',
        flex: '0 0 auto',
        marginLeft: '1rem',
        // marginTop: '1rem',
        marginBottom: '0.5rem',
        fontSize: '1rem',
        // fontWeight: '400',
        lineHeight: '3',
        color: '#737373',
        backgroundColor: '#d1e4fc'
    },
    paper: {
        display: 'block',
        overflow: 'auto',
        //   padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '240px',
        //   height: '100%',
        minWidth: '15.5rem',
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch",
        //   marginBottom: '1rem',
        //   marginRight: '1rem'
    },
    paperMobile: {
        display: 'block',
        // overflow: 'scroll',
        width: '100%',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minWidth: '15.5rem',
    },
    playlists: {
        display: 'block',
        overflow: 'scroll',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '100%',
        paddingRight: '0.5rem',
        width: '100%',
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch",
    },
    content: {
        textAlign: 'center',
        fontSize: '20px',
        paddingTop: '25px'
    },
    contentplayer: {
        'text-align': 'center',
        paddingBottom: '38px',
        fontSize: '20px',
        paddingTop: '25px'
    },
    main: {
        display: 'flex',
        position: 'absolute',
        marginTop: '4.5rem',
        marginLeft: '0.5rem',
        width: '79%',
        height: '100%',
        minHeight: '15rem'
    },
    player: {
        flexBasis: '250px',
        flexShrink: '0',
        paddingRight: '10px',
        paddingLeft: '5px'
    },
    addicon: {
        // paddingLeft: '10px',
        color: 'gray',
        padding: 3,
    },
    players: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        // minWidth: '30rem',
        width: '240px'
    },
    playersMobile: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    mobile: {
        paddingTop: '4rem'
    },
    spacer: {
        flex: '1 1 100%',
    },
    tvicon: {
        alignSelf: 'center',
        // paddingRight: '0.5rem',
        width: '1.1rem'
    },
    gwicon: {
        alignSelf: 'center',
        // paddingRight: '0.5rem',
        color: 'gray',
        width: '1.1rem'
    },
    gwiconact: {
        alignSelf: 'center',
        // paddingRight: '0.5rem',
        color: 'Red',
        width: '1.1rem'
    },
    button: {
        // padding: 1,
        borderRadius: '4px',
        width: '1.1rem'
    },
});

class Players extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowOffline: false,
            isGroupPlayers: false,
            isCompact: false,
            compact: [],
            compactIndex: null,
            pldialogopen: false,
            pldialognotsave: true,
            ownid: this.props.user.role === 'listen' ? this.props.user.parent : this.props.user.id,
            version: '',
            setings: {
                deflogo: true,
                discspace: 0,
                dispid: false,
                freediscspace: 0,
                fs: false,
                ip: "",
                title: false,
                title_message: '',
                api: 0,
                uptime: '',
                VersionName: '',
            },
            playerplaylistdata: {
                playlist: {
                    content: [],
                    advertising: []
                }
            },
            playerdata: {
                name: '',
                comment: '',
                mac: '',
                audiovolume: -1,
            },
        }
    }
    componentDidMount() {
        this.props.userActions.getModes(this.props.user.token);
        this.setState({
            isShowOffline: (window.localStorage.getItem("isShowOffline") === "true"),
            isGroupPlayers: (window.localStorage.getItem("isGroupPlayers") === "true"),
            isCompact: (window.localStorage.getItem("isCompact") === "true"),
        });
    }

    handleShowOffLine() {
        window.localStorage.setItem("isShowOffline", !this.state.isShowOffline);
        this.setState({
            isShowOffline: !this.state.isShowOffline
        });
    }

    handleGroupPlayers() {
        window.localStorage.setItem("isGroupPlayers", !this.state.isGroupPlayers);
        this.setState({
            isGroupPlayers: !this.state.isGroupPlayers
        });
    }

    handlePlayersCompact() {
        window.localStorage.setItem('isCompact', !this.state.isCompact);
        this.setState({
            isCompact: !this.state.isCompact,
            compact: []
        });
    }
    handleCompact(e) {
        var { compact, isCompact } = this.state;
        compact[e.target.id] = compact[e.target.id] !== undefined ? !compact[e.target.id] : !isCompact;
        this.setState({
            compact: compact,
        });
    }

    handlePlaylistDialog(data) {
        this.setState({
            playlistdialogopen: !this.state.playlistdialogopen,
            playerplaylistdata: data
        });
    }
    handlePlaylistDialogClose() {
        this.setState({
            playlistdialogopen: !this.state.playlistdialogopen,
        });
    }

    handleButton(e) {
        const { ownid } = this.state;
        const socket = network.socket;
        const type = e.currentTarget.id.split('btn');
        console.log('isGroupPlayers: ', this.state.isGroupPlayers);
        if (!socket) return;
        if (e.currentTarget.type === 'button') {
            switch (type[0]) {
                case "play":
                    socket.emit('play', { 'play': 1, 'player': this.state.isGroupPlayers ? 'all' : type[1], 'ownid': ownid });
                    break;
                case "prev":
                    socket.emit('play', { 'previous': 1, 'player': this.state.isGroupPlayers ? 'all' : type[1], 'ownid': ownid });
                    break;
                case "next":
                    socket.emit('play', { 'next': 1, 'player': this.state.isGroupPlayers ? 'all' : type[1], 'ownid': ownid });
                    break;
                default:
                    break;
            }
        }
        if (e.currentTarget.type === 'range') {
            socket.emit('play', { 'position': e.currentTarget.value, 'player': this.state.isGroupPlayers ? 'all' : type[1], 'ownid': ownid });
        }
    }

    getListenName(id) {
        const { listeners } = this.props.user;
        var name;
        if (listeners === undefined) return "";
        listeners.map(l => {
            l.listenid.map(i => {
                if (i === id) {
                    name = l.username;
                }
            });
        });
        return name;
    }

    render() {

        const { classes, socket, isMobile, } = this.props;
        const { isGroupPlayers, isShowOffline, pldialogopen, setings, playerdata, pldialognotsave, isCompact, compact } = this.state;
        const { players } = this.props.page;
        const modes = this.props.user.modes;

        return (
            <div className={isMobile ? classes.paperMobile : classes.paper} style={{ height: window.innerHeight - 50 }}>
                <div className={classes.header}>
                    <div className={classes.title}>{i18next.t('Players')}</div>
                    <div className={classes.spacer}></div>
                    <Tooltip title={i18next.t("Group_Players")}>
                        <IconButton className={classes.button}>
                            <GroupWork className={isGroupPlayers ? classes.gwiconact : classes.gwicon} onClick={this.handleGroupPlayers.bind(this)} title='Group' />
                        </IconButton>
                    </Tooltip>
                    {isShowOffline ?
                        <Tooltip title={i18next.t("Show_Online_players")}>
                            <IconButton className={classes.button}>
                                <TvOnIcon className={classes.tvicon} onClick={this.handleShowOffLine.bind(this)} />
                            </IconButton></Tooltip> :
                        <Tooltip title={i18next.t("Show_Offline_players")}>
                            <IconButton className={classes.button}>
                                <TvOffIcon className={classes.tvicon} onClick={this.handleShowOffLine.bind(this)} />
                            </IconButton>
                        </Tooltip>
                    }
                    {isCompact ?
                        <Tooltip title={i18next.t("Maximize_all_players")}>
                            <IconButton className={classes.button}>
                                <IconMaximize className={classes.tvicon} onClick={this.handlePlayersCompact.bind(this)} />
                            </IconButton>
                        </Tooltip> :
                        <Tooltip title={i18next.t("Minimize_all_players")}>
                            <IconButton className={classes.button}>
                                <IconMinimize className={classes.tvicon} onClick={this.handlePlayersCompact.bind(this)} />
                            </IconButton>
                        </Tooltip>
                    }
                </div>
                <div className={isMobile ? classes.playersMobile : classes.players}>
                    {players ? players.map((d, i) => {
                        if (!isShowOffline && !d.online) return null
                        if (this.props.user.role === 'listen') {
                            d.own = this.props.user.parent
                        };

                        return <UIcontrol
                            data={d}
                            index={i}
                            key={i}
                            handleButton={this.handleButton.bind(this)}
                            socket={socket}
                            isMobile={isMobile}
                            handlePlaylistDialog={this.handlePlaylistDialog.bind(this)}
                            isGroupPlayers={isGroupPlayers}
                            isCompact={compact[i] !== undefined || compact[i] != null ? compact[i] : isCompact}
                            handleCompact={this.handleCompact.bind(this)}
                            showError={this.props.pageActions.showError}
                            listenerid={this.getListenName(d.id)}
                            titlemessage={d.setings.titleessage}
                        />
                    }) : null}
                </div>

            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Players)))