import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings'
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { DropTarget } from 'react-dnd'
import { updatePlaylist } from '../../store/actions/PageActions'
import PlaylistSettingsDialog from './playlistSettingsDialog';
import i18next from 'i18next';

let playlist = {};
let counter = 0;
let tkn = '';

function createData(name, comment, duration, size) {
  counter += 1;
  return { id: counter, name, comment, duration, size };
}

function convertTiming(timing) {
  const time = timing.split(':')
  if (time[0] === '00') {
    time[0] = i18next.t("All_days")
  }
  if (time[1] === '25') {
    time[1] = i18next.t("All_hour")
  }
  if (time[2] === '61') {
    time[2] = i18next.t("All_minutes")
  }
  return String(time[0] + ':' + time[1] + ':' + time[2])
}
const rows = [
  { id: 'name', numeric: false, disablePadding: true, label: 'File name' },
  { id: 'comment', numeric: true, disablePadding: false, label: 'Comment' },
  { id: 'duration', numeric: true, disablePadding: false, label: 'Duration' },
  { id: 'size', numeric: true, disablePadding: false, label: 'Size (MB)' },
];

class EnhancedTableHead extends React.Component {

  render() {
    const { onSelectAllClick, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? 'none' : 'default'}
              >
                    {row.label}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    backgroundColor: '#d1e4fc'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto'
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, playlists, handleDelete, handleSettingsDialog } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="subtitle1" id="tableType">
            {playlists.type}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h5" id="tableName">
            {playlists.name}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon onClick={handleDelete}/>
            </IconButton>
          </Tooltip>
        ) : (
            <div>
                <Tooltip title="Settings">
                    <IconButton aria-label="Settings">
                      <SettingsIcon onClick={handleSettingsDialog}/>
                    </IconButton>
                </Tooltip>
                
            </div>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  playlists: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '80%',
    marginTop: theme.spacing.unit * 3,  
    marginLeft: '1rem',
    marginRight: '1rem'
    
  },
  paper: {
    backgroundColor: '#d1e4fc',
  },
  table: {
    // minWidth: 1020,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '97%',

  },
  tableWrapper: {
    overflowX: 'auto',
    
  },
  rows: {
      backgroundColor: 'white',
      
  },
  timing: {
      marginTop: '1rem',
    //   marginBottom: 'auto',
      marginLeft: '1rem'
  },
  name: {
    width: '75%',
  },
  comment: {
    width: '15%',
  },
  duration: {
    width: '10%',
  }
});

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    item: monitor.getItem(),
  }
}

function addFileToPlist(id) {
  playlist.files.push(id)
  updatePlaylist(playlist, tkn)
}

const plistTarget = {
  drop(props, monitor, component) {
    const item = monitor.getItem()
    addFileToPlist(item.id)
  }
}

class EnhancedTable extends React.Component {
  state = {
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 5,
    playlist: {},
    settingsDialogOpen: false
  };


  componentWillReceiveProps = () => {
    const { content, playlists} = this.props
    this.addToPlaylist(content, playlists)
  }

  componentWillMount = () => {
    const { content, playlists} = this.props
    this.addToPlaylist(content, playlists)
  }

  addToPlaylist = (content, playlists) => {
    counter = 0
    playlist = playlists
    let list = []
    if (playlist.files) {
    playlists.files.map(p => {
      content.map(c => {
        if (c.id === p) {
            list.push(createData(c.name, c.comments, c.lenght, Math.round(c.size/(1024*1024))))
        }
      })
    })
    this.setState({
      data: list
    })
  }
  }

  handleDelete = () => {
    this.state.selected.reverse().map((v) => {
      playlist.files.splice(v-1, 1)
    })
    updatePlaylist(playlist, tkn)   
    this.setState({selected: []})
    this.setState();
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event,  n) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(n.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, n.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleSettingsDialog = () => this.setState({settingsDialogOpen: !this.state.settingsDialogOpen})

  render() {
    const { classes, playlists, key, connectDropTarget, token, deletePlaylist } = this.props;
    const { data, selected, rowsPerPage, page, settingsDialogOpen } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    tkn = token

    return connectDropTarget(
      <div className={classes.root}>
      <Paper className={classes.paper} key={key}>
        <EnhancedTableToolbar numSelected={selected.length} playlists={playlists} handleDelete={this.handleDelete} handleSettingsDialog={this.handleSettingsDialog}/>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={this.handleSelectAllClick}
              rowCount={data.length}
            />
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      className={classes.rows}
                      hover
                      onClick={event => this.handleClick(event, n)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox" >
                        <Checkbox checked={isSelected} />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {n.name}
                      </TableCell>
                      <TableCell numeric >{n.comment}</TableCell>
                      <TableCell numeric >{n.duration}</TableCell>
                      <TableCell numeric >{n.size}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className={classes.timing}>
            Timing: {convertTiming(playlists.start)} | {convertTiming(playlists.stop)}
        </div>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
      <PlaylistSettingsDialog open={settingsDialogOpen} handleSettingsDialog={this.handleSettingsDialog} playlists={playlists} updatePlaylist={updatePlaylist} deletePlaylist={deletePlaylist} token={tkn} />
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default DropTarget('FILE', plistTarget, collect)(withStyles(styles)(EnhancedTable));