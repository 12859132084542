import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../store/actions/UserActions';
import * as pageActions from '../../store/actions/PageActions';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PaymentForm from '../Other/PaymentForm';
import UserForm from './UserForm';
import UserUsage from './UserUsage';
import Players from './Players';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    marginTop: '6rem',
    [theme.breakpoints.up(900 + theme.spacing.unit * 2 * 2)]: {
      maxWidth: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '6rem',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
    // width: 'fit-content'
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
});

const steps = ['User info', 'Players', 'Usage'];

function getStepContent(step, user, page) {
  switch (step) {
    case 0:
      return <UserForm />;
    case 1:
      return <Players user={user} page={page} />;
    case 2:
      return <UserUsage user={user} page={page} />;
    default:
      throw new Error('Unknown step');
  }
}

class Userpage extends React.Component {
  state = {
    activeStep: 0,
  };

  componentWillMount() {
    this.props.userActions.getModes(this.props.user.token);
  }

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleSetStep = (index) => {

    this.setState({
      activeStep: index
    });
  }

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const { classes, user, page } = this.props;
    const { activeStep } = this.state;
    const { role } = this.props.user;

    if (role === 'listen') { return (<div></div>); }

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography variant="display1" align="center">
              User settings
            </Typography>
            <Stepper nonLinear activeStep={activeStep} className={classes.stepper}>
              {steps.map((label, index) => {
                if (role === "corp" && label === "Players") { return null }

                return (
                  <Step key={label} onClick={() => this.handleSetStep(index)}>
                    <StepLabel>{label}</StepLabel>
                  </Step>)
              }
              )}
            </Stepper>
            <React.Fragment>
              {getStepContent(activeStep, user, page)}
            </React.Fragment>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

Userpage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    page: state.page,
    language: state.language.lang.language
  }
}

function mapDispatchToProps(dispatch) {
  return {
    pageActions: bindActionCreators(pageActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Userpage));