import * as languageConstants from '../../constants/Language';

export function changeLanguage(lang) {
    return function(dispatch) {
        dispatch({
            type: languageConstants.SET_LANGUAGE,
            payload: {
                language: lang
            }
        })
    }
}