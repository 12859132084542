import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import yellow from '@material-ui/core/colors/yellow';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  progress: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
    maxWidth: window.innerWidth - 50
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  progress: {
    backgroundColor: yellow[700]
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    maxWidth: window.innerWidth - 50
  }
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'progress']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit,

  },
});

class CustomizedSnackbars extends React.Component {
  state = {
    open: true,
  };

  componentDidMount() {
    const { variant, handleRemoveSnack, id } = this.props;
    console.log('VARIANT: ', variant)
    if (variant !== 'progress') {
      console.log('SET REMOVE SNACK')
      setTimeout(handleRemoveSnack, 5000, 0)
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.handleRemoveSnack(this.props.id);
    // this.setState({ open: false });
  };

  render() {
    const { classes, message, id, variant, handleRemoveSnack } = this.props;

    if (message !== null) {
      const m = message.split('')
      var mes = 0
      if (m.length >= 3) {
        mes = parseInt(m[3].split('%'))
      }

      if (mes >= 99) {
        handleRemoveSnack(id)
      }
    }
    return (
      <div>

        <Snackbar
          style={{ bottom: variant === "progress" ? 24 : 24 + id * 60 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: variant === "progress" ? 'left' : 'right'
          }}
          open={this.state.open}
          //   autoHideDuration={6000}
          onClose={this.handleClose}
          key={id}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={variant}
            message={message}
          />
        </Snackbar>


      </div>
    );
  }
}

CustomizedSnackbars.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles2)(CustomizedSnackbars);