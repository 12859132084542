import * as userConstants from '../../constants/User';


const initialState = {
    username: "Unknown name",
    token: '',
    id: '',
    loading: false,
    load: false,
    error: '',
    message: '',
    logo: '',
    role: '',
    lang: 'en',
    confirmed: false,
    modes: [],
    balance: 0,
    listeners: [],
    parent: 0,
    promotioncode: '',
    errormessage: ''
}

export default function user(state = initialState, action) {

    switch (action.type) {
        case userConstants.GET_USER_AUTHORIZATION_REQUEST:
            return { ...state, loading: true }

        case userConstants.GET_USER_AUTHORIZATION_SUCCESS:
            return { ...state, token: action.payload.token, id: action.payload.identity, load: true }

        case userConstants.GET_USER_AUTHORIZATION_ERROR:
            return { ...state, error: action.payload.error }

        case userConstants.GET_USER_DATA_SUCCESS:
            return { ...state, username: action.payload.name, maxsize: action.payload.maxsize, email: action.payload.email, freespace: action.payload.freespace, totallFilesSize: action.payload.files_size, role: action.payload.role, confirmed: action.payload.confirmed, balance: action.payload.balance, listeners: action.payload.listeners, parent: action.payload.parent, errormessage: action.payload.errormessage }

        case userConstants.LOG_OUT_SUCCESS:
            window.location.reload();
            return initialState

        case userConstants.LOG_OUT_ERROR:
            return initialState

        case userConstants.UPLOAD_LOGO_ERROR:
            return { ...state, error: action.payload }

        case userConstants.UPLOAD_LOGO_SUCCESS:
            return { ...state }

        case userConstants.GET_LOGO_SUCCESS:
            return { ...state, logo: action.payload }

        case userConstants.GET_LOGO_ERROR:
            return { ...state, error: action.payload }

        case userConstants.CLEAR_USER_ERROR:
            return { ...state, error: "", errormessage: "" }

        case userConstants.REGISTRATION_USER_SUCCESS:
            return { ...state, token: action.payload.token, id: action.payload.identity, load: true }

        case userConstants.REGISTRATION_USER_ERROR:
            return { ...state, error: action.payload }

        case userConstants.REGISTRATION_LISTENER_SUCCESS:
            return { ...state, listeners: action.payload }

        case userConstants.REGISTRATION_LISTENER_ERROR:
            return { ...state, error: action.payload }

        case userConstants.SET_LISTEN_IDS_SUCCES:
            return { ...state, listeners: action.payload }

        case userConstants.SET_LISTEN_IDS_ERROR:
            return { ...state, error: action.payload }

        case userConstants.CHANGE_USER_PASSWORD_SUCCESS:
            return { ...state, token: action.payload.token, id: action.payload.identity, load: true }

        case userConstants.CHANGE_USER_PASSWORD_ERROR:
            return { ...state, error: action.payload }

        case userConstants.GET_MODES_SUCCESS:
            return { ...state, modes: action.payload }

        case userConstants.GET_MODES_ERROR:
            return { ...state, error: action.payload }

        case userConstants.CONFIRM_EMAIL:
            return { ...state, confirmed: true }

        case userConstants.SEND_CONFIRM_MESSAGE_SUCCESS:
            return { ...state, message: action.payload.message }

        case userConstants.SEND_CONFIRM_MESSAGE_ERROR:
            return { ...state, error: action.payload }

        case userConstants.DELETE_LISTENER_USER_SUCCES:
            return { ...state, username: action.payload.name, maxsize: action.payload.maxsize, email: action.payload.email, freespace: action.payload.freespace, totallFilesSize: action.payload.files_size, role: action.payload.role, confirmed: action.payload.confirmed, balance: action.payload.balance, listeners: action.payload.listeners, parent: action.payload.parent }

        case userConstants.DELETE_LISTENER_USER_ERROR:
            return { ...state, error: action.payload }

        case userConstants.GET_PROMOTION_CODE_SUCCES:
            return { ...state, promotioncode: action.payload.promotioncode }

        case userConstants.GET_PROMOTION_CODE_ERROR:
            return { ...state, error: action.payload }

        default:
            return state
    }
}