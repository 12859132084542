import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import i18next from 'i18next';

class AlertDialog extends React.Component {

  state = {
    open: false,
  };

  render() {
    const { alert, message, open, handleClose, handleAgree, extraButton, extraClick } = this.props;

    return (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
          <a style={alert === 'Info' ? {color: "green"} : {color: "red"}}>{i18next.t(alert)}</a></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {i18next.t(message)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          {handleClose ? 
          <div>
            <Button onClick={handleClose} color="primary">
              {i18next.t("Disagree")}
            </Button> 
            <Button onClick={handleAgree} color="primary" autoFocus>
              {i18next.t("Agree")}
            </Button>
            </div> :  
            <div>
            <Button onClick={handleAgree} color="primary" autoFocus>
              {i18next.t("Ok")}
            </Button>
              {extraButton ? 
              <Button onClick={extraClick} color="primary">
                {i18next.t(extraButton)}
              </Button>
              :
              null
              }
              </div>
            }
          </DialogActions>
        </Dialog>
    );
  }
}

export default AlertDialog;