import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import Page from '../components/Page';
import SignIn from '../components/User/SignIn';
import { bindActionCreators } from 'redux';
import * as pageActions from '../store/actions/PageActions';
import * as userActions from '../store/actions/UserActions';
import * as languageAction from '../store/actions/LanguageAction';
import * as network from '../services';
import '../styles/App.css';
import AppBar from '../components/Header/AppBar';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { isMobile, osName } from 'react-device-detect';
import Drawer from '../components/Drawer';
import Playlists from '../components/Playlists/playlists';
import Footer from '../components/Footer/Footer';
import Userpage from '../components/User/User';
import Download from '../components/Download/Download';
import Logs from '../components/Logs/Logs';
import About from '../components/About/About';
import i18next from 'i18next';
import AlertDialog from '../components/AlertDialog';
import Snakes from '../components/Other/Snakes';

export var socket;

const LANGUAGE_SUPPORT = ['en', 'ru', 'cn', 'sp'];
const history = createBrowserHistory();

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});


class App extends Component {

  state = {
    drawer: false,
    alertUser: false,
    alertPage: false,
    messageUser: false,
    isReSending: false,
    lang: Intl.DateTimeFormat().resolvedOptions().locale.split("-")[0].toLocaleLowerCase(),
    errormessage: false
  }
  // socket = null

  componentWillMount = () => {
    this.setLanguage();
    if (this.props.user.token) {
      pageActions.getContent(this.props.user.token);
      userActions.getLogo(this.props.user.token);
    }
  }


  setLanguage() {
    var lang = window.localStorage.getItem("language");
    const locale = this.state.lang;
    if (lang == null) {
      if (LANGUAGE_SUPPORT.includes(locale)) {
        lang = locale;
      } else {
        lang = 'en'
      }
    }
    i18next.init({
      lng: lang,
      resources: require(`../components/lang/${lang}`)
    });

    this.props.languageActions.changeLanguage(i18next);
  }

  load = false

  handleLogOut = () => {
    this.props.userActions.logOut(this.props.user.token);
    setTimeout(() => {
      localStorage.clear();
      socket.disconnect();
    }, 500);

  }

  toggleDrawer = (open) => () => {
    this.setState({
      drawer: open,
    });
  };

  handleOpenMenu = () => {
    this.setState({
      drawer: !this.state.drawer
    })
  }

  handleAlertOk() {
    this.props.pageActions.clearPageError();
    this.props.userActions.clearUserError();
    this.setState({
      alertUser: false,
      alertPage: false,
      messageUser: false,
      isReSending: false,
      errormessage: false
    });
  }

  handleCheckConfirm() {
    const { token } = this.props.user;
    this.props.userActions.getUserData(token);
  }

  handleResendMessage() {
    const { token } = this.props.user;
    this.props.userActions.sendConfirmMessage(token);
    this.setState({
      isReSending: true
    });
  }

  render() {
    const { user, page, store } = this.props;
    const { setPlayList, getContent, setPosition, getPosition, deleteContent, renameContent, updatePlayer, getPlaylists, createPlaylist,
      getPlayers } = this.props.pageActions;
    const { getUserAuthorization, getUserData, getLogo, confirmEmail } = this.props.userActions;
    const { token, loading, id, confirmed, role, parent } = this.props.user;
    const { loaded, content, players, playlists } = this.props.page;
    const { alertPage, alertUser, messageUser, lang, errormessage } = this.state;
    const userid = role === 'listen' ? parent : id;
    if (this.props.page.error !== "" && !alertPage) {
      this.setState({
        alertPage: true
      });
    }
    if (this.props.user.error !== "" && !alertUser && this.props.user.error !== undefined) {
      this.setState({
        alertUser: true
      });
    }
    if (this.props.user.message !== undefined && this.props.user.message !== '' && !messageUser) {
      this.setState({
        messageUser: true
      })
    }
    if (this.props.user.errormessage !== undefined && this.props.user.errormessage !== null && !errormessage) {
      if (this.props.user.errormessage.lenght >= 4) {
        this.setState({
          errormessage: true
        })
      }
    }

    if (!token) {
      return (
        <div>
          <SignIn getUserAuthorization={getUserAuthorization} loading={loading} getContent={getContent} lang={lang} />
          <AlertDialog open={this.state.alertUser} alert="Error!" message={this.props.user.error} handleAgree={this.handleAlertOk.bind(this)} />
        </div>
      )
    }

    if (!this.load) {
      getContent(token);
      getUserData(token);
      getPlaylists(token);
      getPlayers(token);
      getLogo(token);
      socket = network.socket.connect(token, userid)
      this.load = true
    }

    return (
      <div>
        <React.StrictMode>
          <BrowserRouter history={history}>
            <MuiThemeProvider theme={theme}>

              <AppBar handleOpenMenu={this.handleOpenMenu.bind(this)} user={user} token={token} getContent={getContent} createPlaylist={createPlaylist} isMobile={isMobile} role={role} />
              <Drawer toggleDrawer={this.toggleDrawer.bind(this)} drawer={this.state.drawer} role={user.role} />


              <Route exact path='/'
                render={() => [
                  <div key={1}>
                    <Page players={players} setPlayList={setPlayList} getContent={getContent} fetching={page.fetching} token={token} content={content} setPosition={setPosition} playlist={page.playlist} getPosition={getPosition} socket={socket} isMobile={isMobile} deleteContent={deleteContent} renameContent={renameContent} updatePlayer={updatePlayer} user={user} loaded={loaded} />
                    <Footer />
                  </div>
                ]}
              />
              <Route path='/main'
                render={() => [
                  <div key={2}>
                    <Page players={players} setPlayList={setPlayList} getContent={getContent} fetching={page.fetching} token={token} content={content} setPosition={setPosition} playlist={page.playlist} getPosition={getPosition} socket={socket} isMobile={isMobile} deleteContent={deleteContent} renameContent={renameContent} updatePlayer={updatePlayer} user={user} loaded={loaded} />
                    <Footer />
                  </div>
                ]}
              />
              <Route path='/downloads'
                render={() => [
                  <div key={3}>
                    <Download />
                    <Footer />
                  </div>
                ]} />
              <Route path='/about'
                render={() => [
                  <div key={4}>
                    <About />
                    <Footer />
                  </div>
                ]}
              />
              <Route path='/playlists'
                render={() => [
                  <Playlists playlists={playlists} />
                ]}
              />
              <Route path='/logout'
                render={() => {
                  this.handleLogOut()
                  return (
                    <Redirect to="/" />
                  )
                }} />
              <Route path='/user'
                render={() => [
                  <div key={5}>
                    <Userpage />
                    <Footer />
                  </div>
                ]}
              />
              <Route path='/settings'
                render={() => [
                  <div key={6}>
                    <Userpage />
                    <Footer />
                  </div>
                ]}
              />
              {user.role === "admin" ?
                <Route path='/log'
                  render={() => [
                    <div key={7}>
                      <Logs />
                    </div>
                  ]}
                />
                : null}
              {/* <User username={user.username} /> */}



            </MuiThemeProvider>
          </BrowserRouter>
          {confirmed ? null :
            <AlertDialog open={true} alert={i18next.t("Info")} message={i18next.t("Confirm_message")} handleAgree={confirmEmail.bind(this)} extraButton="resend" extraClick={this.handleResendMessage.bind(this)} />
          }
          <AlertDialog open={this.state.alertPage} alert="Error!" message={this.props.page.error} handleAgree={this.handleAlertOk.bind(this)} />
          <AlertDialog open={this.state.alertUser} alert="Error!" message={this.props.user.error} handleAgree={this.handleAlertOk.bind(this)} />
          <AlertDialog open={this.state.isReSending} alert="Info" message='resending_message' handleAgree={this.handleAlertOk.bind(this)} />
          <AlertDialog open={this.state.errormessage} alert="Error!" message={this.props.user.errormessage} handleAgree={this.handleAlertOk.bind(this)} />
          {/* <AlertDialog open={this.state.messageUser} alert="Message!" message={this.props.user.message} handleAgree={this.handleAlertOk.bind(this)}/> */}
          <Snakes />
        </React.StrictMode>
      </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    page: state.page,
    language: state.language
  }
}

function mapDispatchToProps(dispatch) {
  return {
    pageActions: bindActionCreators(pageActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    languageActions: bindActionCreators(languageAction, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
