import * as languageConstants from '../../constants/Language';

const initialState = {
    lang: 'en',
}

export default function language(state=initialState, action) {
    switch (action.type) {
        case languageConstants.SET_LANGUAGE:
            return {...state, lang: action.payload.language}
            
    
        default:
            return state
    }
}