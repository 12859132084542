import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import * as pageActions from '../../store/actions/PageActions'
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import File from './file';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import AddMenu from '../Header/AddMenu';
import UploadDialog from './UploadDialog';
import AddStreamDialog from './AddStreamDialog';
import i18next from 'i18next';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import SortIcon from '@material-ui/icons/Sort';
import Input from '@material-ui/core/Input';
import { fromDataStringToInt } from '../../services/utils';



const styles = theme => ({
  root: {
    width: '20%',
    // maxWidth: 360,
    // marginTop: theme.spacing.unit * 3,
    backgroundColor: theme.palette.background.paper,
    // marginLeft: 'auto',
    // marginRight: '1rem',
    overflow: 'auto',
    height: '100%',
    // textOverflow: 'ellipsis'
    position: 'fixed',
    top: '4.5rem',
    right: '0.3rem'
  },
  title: {
    //   flex: 'auto 0 auto',
    flex: '0 0 auto',
    marginLeft: '1rem',
    // marginTop: '1rem',
    marginBottom: '0.5rem',
    fontSize: '1rem',
    // fontWeight: '400',
    lineHeight: '3',
    color: '#737373'
    // backgroundColor: '#d1e4fc'
  },
  filename: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word'
  },
  header: {
    display: 'flex',
    backgroundColor: '#d1e4fc',
    // marginTop: '-1rem',
    // marginBottom: '-1rem',
    height: '3rem',
    borderRadius: '4px',
    // position: 'absolute',
    zIndex: 1
  },
  spacer: {
    flex: '1 1 100%',
  },
  selected: {
    backgroundColor: 'red'
  },
  unselected: {
  },
  hover: {
    '&:hover': {
      backgroundColor: '#d1e4fc !important'
    }
  },
  nohover: {

  },
  sel: {
    backgroundColor: '#ff8f8f',
    '&:hover': {
      backgroundColor: '#fd4444 !important'
    }
  },
  scrool: {
    display: 'block',
    overflow: 'scroll',
    height: window.innerHeight - 160,
    // position: 'absolute',

  },
  button: {
    width: '1.1rem',
    // height: '2.4rem',
    borderRadius: '4px',
    marginBottom: '0.2rem',
    // marginRight: '0.5rem',
    minWidth: '2.5rem'
  },
  icon: {
    color: 'gray'
  },
  uploaddialog: {
    position: "absolute",
    bottom: '100px',
    right: 0,
    backgroundColor: "white",
    width: "400px",
    overflow: "scroll",
    maxHeight: "400px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '30rem',
    marginTop: "0.6rem",
    height: "2em",
    // backgroundColor: "white"
  },
  fake: {

  }
});
const sortType = ['AB', 'BA', 'Date Down', 'Date Up'];

class FolderList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: 0,
      selected: [],
      isHover: false,
      handleUploadDialog: false,
      addstreamopen: false,
      mouseOver: false,
      isMouseOver: { id: null, hover: false },
      searchOpen: false,
      searchText: '',
      sort: true,
      sortBy: 'AB'
    }
  }
  // componentWillReceiveProps() {
  //   const converterProgress = this.props.page.progress;
  //   const { progress, show } = this.state;
  //   if (size(converterProgress) === 0) {
  //     this.handleUploadDialogClose();
  //     this.props.getContent(this.props.token);
  //   }
  // }

  handleUploadDialog = () => {
    this.props.pageActions.uploadContentClear();
    this.setState({ handleUploadDialog: !this.state.handleUploadDialog })
  };

  handleUploadDialogClose = () => { this.setState({ handleUploadDialog: false }) };

  handleStreamDialog = () => { this.setState({ addstreamopen: !this.state.addstreamopen }) };

  handleClick = (event, row) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected });
  };

  // TODO Set marque to long filename & show gif for big preview
  onMouseOver = (e) => {

  }


  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleOver = (id) => {
    this.setState({ isMouseOver: { id: id, hover: true } })
  }

  handleUnOver = (id) => {
    this.setState({ isMouseOver: { id: id, hover: false } })
  }

  handleSearchOpen = () => {
    const { searchOpen } = this.state;

    this.setState({
      searchOpen: !searchOpen,
      searchText: ""
    })
  }

  handleSearch = (e) => {

    this.setState({
      searchText: e.target.value
    })
  }

  handleSearchAB = () => {
    const { sortBy } = this.state;
    const nextType = sortType.indexOf(sortBy) === 3 ? 'AB' : sortType[sortType.indexOf(sortBy) + 1]
    this.setState({
      sort: !this.state.sort,
      sortBy: nextType
    })
  }

  handleDelete = () => {
    const { token } = this.props.user;
    const userid = this.props.user.id;
    const { content } = this.props.page;
    const { selected } = this.state;
    const { deleteContent, deleteStream } = this.props.pageActions;

    if (selected) {
      selected.map(id => {
        const data = content.filter(content => id === content.id)
        console.log(data)
        if (data[0].type === "stream") {
          // console.log("Delete stream - ", data[0].name)
          deleteStream(token, data[0].name, userid)
        } else {
          deleteContent(token, data[0].name, userid)
        }

      })
      this.setState({ selected: [] })
    }
  }

  render() {
    const { classes } = this.props;
    const { token, role } = this.props.user
    const { content } = this.props.page;
    const { getContent } = this.props.pageActions;
    const { isMouseOver, searchOpen, searchText, sort, sortBy } = this.state;
    const id = this.props.user.role === 'listen' ? this.props.user.parent : this.props.user.id;

    return (
      <div className={classes.root}>
        <Paper>
          <div className={classes.header}>
            {!searchOpen ?
              <div className={classes.title}>{i18next.t("You_upload_files")}</div> :
              <Input
                placeholder='Search'
                className={classes.textField}
                margin="normal"
                onChange={this.handleSearch}
              />
            }
            <div className={classes.spacer}></div>
            <Tooltip title={i18next.t("Search")}>
              <IconButton onClick={this.handleSearchOpen} className={classes.button}><SearchIcon /></IconButton>
            </Tooltip>
            <Tooltip title={`${i18next.t("Sort By ")} ${sortType[sortType.indexOf(sortBy) === sortType.length - 1 ? 0 : sortType.indexOf(sortBy) + 1]}`}>
              <IconButton onClick={this.handleSearchAB} className={classes.button}><SortIcon /></IconButton>
            </Tooltip>
            {/* <div className={classes.spacer}></div> */}
            {role === 'listen' ? null : <div>
              {this.state.selected.length ? <IconButton className={classes.button} aria-label="Delete">
                <DeleteIcon onClick={this.handleDelete} />
              </IconButton> :
                <AddMenu className={classes.button} handleUploadDialog={this.handleUploadDialog} handleStreamDialog={this.handleStreamDialog} />}</div>}
            <div></div>
          </div>
          <Divider />
          <List className={classes.scrool} style={{ height: window.innerHeight - 160 }}>
            {content
              .sort((a, b) => {
                if (sortBy === 'AB' || sortBy === 'BA') {
                  var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
                  if (nameA < nameB) //сортируем строки по возрастанию
                    return sort ? -1 : 1
                  if (nameA > nameB)
                    return sort ? 1 : -1
                  return 0 // Никакой сортировки
                } else {
                  var dateA = fromDataStringToInt(a.dateadd), dateB = fromDataStringToInt(b.dateadd);
                  if (dateA < dateB)
                    return sort ? -1 : 1
                  if (dateA > dateB)
                    return sort ? 1 : -1
                  return 0
                }
              })
              .filter((e) => {
                if (searchText === "") {
                  return e
                } else if (e.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
                  return e
                } else if (e.comments.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
                  return e
                }
              })
              .map((item, key) => {
                const isSelected = this.isSelected(item.id);
                if (item.private) {
                  return (

                    <div key={key} hover className={isSelected ? classes.sel : classes.hover} onMouseEnter={event => this.handleOver(item.id)} onMouseLeave={event => this.handleUnOver(item.id)}>
                      <File style={{ width: window.innerWidth / 5 }} key={item.id} item={item} id={id} isMouseOver={isMouseOver} handleClick={this.handleClick.bind(this)} />
                    </div>

                  )
                }
              })}
          </List>
          <div className={classes.fake}></div>
        </Paper>
        <UploadDialog user={id} open={this.state.handleUploadDialog} handleDialog={this.handleUploadDialogClose} token={token} getContent={getContent} />
        <AddStreamDialog user={id} open={this.state.addstreamopen} handleDialog={this.handleStreamDialog} token={token} getContent={getContent} />

      </div>
    );
  }
}

FolderList.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    page: state.page
  }
}

function mapDispatchToProps(dispatch) {
  return {
    pageActions: bindActionCreators(pageActions, dispatch),
    //   userActions: bindActionCreators(userActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FolderList));