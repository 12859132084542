import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../store/actions/UserActions';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography, Grid } from '@material-ui/core';
import logo from '../../img/logo2.1.png';
import i18next from 'i18next';
import ChangePassword from './ChangePassword';
import NewListener from './NewListener';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import UserIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import AlertDialog from '../AlertDialog';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  error: {
    color: 'red'
  },
  img: {
    width: '8rem',
    height: '8rem'
  },
  title: {
    paddingBottom: '2rem',
    paddingTop: '8px',
  }
});

class UserForm extends React.Component {
  state = {
    open: false,
    newListenerOpen: false,
    deleteusername: '',
    deleteuseralert: false
  }

  handleChangeClose() {
    this.setState({
      open: false,
      newListenerOpen: false,
    })
  }

  chanagePassword(username) {
    this.setState({
      open: true,
      username: username
    });
  }

  deleteUser(username) {
    this.setState({
      deleteusername: username,
      deleteuseralert: true
    })
  }

  deleteUserConfirmed() {
    const { token } = this.props.user

    const data = {
      username: this.state.deleteusername
    }
    this.props.userActions.deleteListenUser(token, data);
    this.setState({
      deleteuseralert: false
    })
  }

  closeAlert() {
    this.setState({
      deleteuseralert: false
    })
  }

  createListener() {
    this.setState({
      newListenerOpen: true
    });
  }

  handleUploadLogo = (e) => {
    e.preventDefault();
    const { token } = this.props.user;
    const { uploadLogo, getLogo } = this.props.userActions;
    const data = new FormData();
    data.append('file', this.uploadInput.files[0]);
    data.append('comment', 'logo');
    uploadLogo(token, data);
    setTimeout(() => {
      getLogo(token)
    }, 1000);
  }

  render() {
    const { user, classes } = this.props;
    const { uploadLogo, changeUserPassword, registrationListener } = this.props.userActions;
    const { token, email, listeners } = this.props.user;
    const { open, newListenerOpen, username, deleteuseralert } = this.state;



    return (
      <React.Fragment>
        <Typography variant="title" gutterBottom>
          User info:
      </Typography>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="userName"
              name="userName"
              label="User name"
              fullWidth
              autoComplete="uname"
              value={user.username}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              name="email"
              label="e-mail"
              fullWidth
              autoComplete="email"
              value={user.email}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography > User logo: </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            {user.logo ? <img src={`data:image/png;base64,${user.logo}`} alt='' className={classes.img} /> :
              <img src={logo} alt='' className={classes.img} />}
            <label htmlFor="contained-button-file">
              <Button color="primary" component="span" className={classes.button}>
                {i18next.t("Logo_upload")}
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} sm={6}>
            <input
              accept="image/png"
              className={classes.input}
              id="contained-button-file"
              // multiple
              type="file"
              ref={(ref) => { this.uploadInput = ref; }}
              onChange={this.handleUploadLogo}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>
          <Grid item xs={12} sm={6}>
            <hr />
            <Grid container spacing={12}>
              <Grid item xs={12} sm={6}>

                <div className={classes.title}>
                  {i18next.t("Listener_options")}:</div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button color="primary" onClick={this.createListener.bind(this)}>
                  {i18next.t("Create_new_listener")}
                </Button>
              </Grid>
              {listeners.map((lstn, key) => (
                <Grid container spacing={12} key={key}>
                  <Grid item xs={12} sm={6}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <UserIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={lstn.username}
                      // secondary={lstn.id}
                      />
                    </ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <IconButton aria-label="Edit" onClick={this.chanagePassword.bind(this, lstn.username)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="Delete" onClick={this.deleteUser.bind(this, lstn.username)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <hr />
            <Grid container spacing={12}>
              <Grid item xs={12} sm={6}>
                <div className={classes.title}>
                  {i18next.t("User_options")}:</div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button color="primary" onClick={this.chanagePassword.bind(this, user.username)}>
                  {i18next.t("Change_User_Password")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>


        <ChangePassword open={open} token={token} username={username} handleChangeClose={this.handleChangeClose.bind(this)} changeUserPassword={changeUserPassword} />
        <NewListener open={newListenerOpen} token={token} username={user.username} handleChangeClose={this.handleChangeClose.bind(this)} registrationListener={registrationListener} email={email} />
        <AlertDialog open={deleteuseralert} alert='Warning' message='Plece_confirm_to_delete_listen_user' handleClose={this.closeAlert.bind(this)} handleAgree={this.deleteUserConfirmed.bind(this)} />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    page: state.page
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserForm));