import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import AlertDialog from '../AlertDialog';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import * as Utils from '../../services/utils';
import i18next from 'i18next';
import { isMobile } from 'react-device-detect';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  textField: {
    flexBasis: 200,
  },
  ranges: {
    width: '70px',
  },
  rangesDay: {
    width: '150px'
  },
  nameField: {
    width: '200px'
  },
  typeField: {
    width: '150px'
  },
  text: {
    marginTop: '2rem',
    marginBottom: '1rem'
  },
  formgroup: {
    marginTop: '0.5rem',
    marginLeft: '0.5rem',
  },
  paperWidthSm: {
    maxWidth: "100%",
    margin: "0px"
}
});


function formatDate (day, hour, minute) {
  return String(day + ':' + hour + ':' + minute)
} 

class PlaylistSettingsDialog extends React.Component {
  constructor(props) {
    super(props);
    const { playlists } = this.props;
    const start = playlists.start.split(':')
    const stop = playlists.stop.split(':')
    this.state = {
      name: playlists.name,
      type: playlists.type,
      repeat: playlists.repeat,
      start: playlists.start,
      stop: playlists.stop,
      startDay: start[0],
      startHour: start[1],
      startMinute: start[2],
      stopDay: stop[0],
      stopHour: stop[1],
      stopMinute: stop[2],
      shuffle: playlists.shuffle,
      fadeout: playlists.fadeout,
      open: false,
      alertDialog: false,
      agree: false
    }
  }


  handleSave = () => {
    const { name, repeat, startDay, startHour, startMinute, stopDay, stopHour, stopMinute, type, shuffle, fadeout } = this.state
    const { playlists, token, userid } = this.props

    playlists.name = name
    playlists.start = formatDate(startDay, startHour, startMinute)
    playlists.stop = formatDate(stopDay, stopHour, stopMinute)
    playlists.type = type
    playlists.repeat = repeat
    playlists.shuffle = shuffle
    playlists.fadeout = fadeout
    this.props.handleSettingsDialog()
    this.props.updatePlaylist(playlists, token, userid)
  }

  handleDelete = () => {
    this.setState({ alertDialog: true})
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleChangeSwitch = name => e => {
    this.setState({ [name]: !this.state[name]});
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCloseAlert = () => {
    this.setState({ alertDialog: false})
  }

  handleAgreeAlert = () => {
    const { deletePlaylist } = this.props
    const id = {
      id: this.props.playlists.id
    }
    this.setState({ alertDialog: false, agree: true });
    deletePlaylist(this.props.token, id, this.props.playlists.own);
    this.props.handleSettingsDialog()
  }



  render() {
    const { classes, role, playlists } = this.props;
    const { type } = this.state;

    var typeRanges = Utils.typeRanges();

    const DayRanges = Utils.dayRanges();
    if(role === "admin" && !typeRanges[2] || playlists.type === 'pacs' && !typeRanges[2]) {
      typeRanges.push({
        value: 'pacs',
        label: i18next.t("Packets"),
      })
    }

    return (
      <div className={classes.root}>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleSettingsDialog}
          aria-labelledby="form-dialog-title"
          classes={isMobile? classes : null}
        >
          <DialogTitle id="form-dialog-title">{i18next.t("Playlist_settings")}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={i18next.t("Name_playlist")}
              type="text"
              value={this.state.name}
              onChange={type === "pacs"? null : this.handleChange('name')}
              className={classNames(classes.margin, classes.textField, classes.nameField)}
            />
            {type === "pacs" ? null : 
            <TextField
              select
              label="Type"
              className={classNames(classes.margin, classes.textField, classes.typeField)}
              value={type === "pacs"? 'pacs' : this.state.type}
              onChange={type === "pacs"? null : this.handleChange('type')}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              >{typeRanges.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>}
            <DialogContentText className={classes.text}>
                {i18next.t("Start_time_range")}
            </DialogContentText>
            <TextField
            select
            label={i18next.t("StartDay")}
            className={classNames(classes.margin, classes.textField, classes.rangesDay)}
            value={this.state.startDay}
            onChange={this.handleChange('startDay')}
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
            >{DayRanges.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
              select
              label={i18next.t("StartHour")}
              className={classNames(classes.margin, classes.textField, classes.ranges)}
              value={this.state.startHour}
              onChange={this.handleChange('startHour')}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              >{Utils.ArrayGenerate(24).map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label={i18next.t("startMinute")}
              className={classNames(classes.margin, classes.textField, classes.ranges)}
              value={this.state.startMinute}
              onChange={this.handleChange('startMinute')}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              >{Utils.ArrayGenerate(60).map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {type !== "advertising" ? 
            <div>
            <DialogContentText className={classes.text}>
                {i18next.t("Stop_time_range")}
            </DialogContentText>
            <TextField
            select
            label={i18next.t("StopDay")}
            className={classNames(classes.margin, classes.textField, classes.rangesDay)}
            value={this.state.stopDay}
            onChange={this.handleChange('stopDay')}
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
            >{DayRanges.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
              select
              label={i18next.t("SoptHour")}
              className={classNames(classes.margin, classes.textField, classes.ranges)}
              value={this.state.stopHour}
              onChange={this.handleChange('stopHour')}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              >{Utils.ArrayGenerate(24).map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label={i18next.t("stopMinute")}
              className={classNames(classes.margin, classes.textField, classes.ranges)}
              value={this.state.stopMinute}
              onChange={this.handleChange('stopMinute')}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              >{Utils.ArrayGenerate(60).map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem> 
              ))}
            </TextField>
            </div> : null }
            <FormGroup row className={classes.formgroup}>
            {type !== "advertising" ? 
              <FormControlLabel
                control={
                    <Switch 
                        checked={this.state.shuffle}
                        onChange={this.handleChangeSwitch('shuffle')}
                        value={this.state.shuffle}
                    />
                }
                label={i18next.t("Shuffle")}
              /> : null }
              <FormControlLabel
                control={
                    <Switch 
                        checked={this.state.fadeout}
                        onChange={this.handleChangeSwitch('fadeout')}
                        value={this.state.fadeout}
                    />
                }
                label={i18next.t("FadeOut")}
              />
            </FormGroup>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleSettingsDialog} color="primary">
              {i18next.t("Cancel")}
            </Button>
            <Button onClick={this.handleSave} color="primary">
              {i18next.t("Save")}
            </Button>
            
            <Button onClick={this.handleDelete} color="primary">
              {i18next.t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
        <AlertDialog alert={i18next.t("Delete_playlist")} message={i18next.t("Confirm_delete_playlist")} open={this.state.alertDialog} handleClose = {this.handleCloseAlert} handleAgree = {this.handleAgreeAlert} />
      </div>
    );
  }
}

PlaylistSettingsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PlaylistSettingsDialog);