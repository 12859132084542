import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Movie';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    root: {
    //   width: '100%',
      padding: theme.spacing.unit * 1 ,
    //   maxWidth: 360,
      textAlign: 'center',
      backgroundColor: theme.palette.background.paper,
      flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    button: {
        marginTop: '-1.3rem',
        padding: 3,
        borderRadius: '4px'
      },
  });

class Content extends Component {
    state = {
        edit: false,
        comment: this.props.data.comments,
        filename: this.props.data.name,
        edited: false
    }

    handleEdit = () => {
        this.setState({ edit: !this.state.edit})
    }

    handleChange = (e) => {
        this.setState({ [e.currentTarget.id] : e.currentTarget.value, edited: true })
    }

    handleSave = (e) => {
        this.setState({edited: false})
        this.handleEdit()
        const data = {'comment': this.state.comment, 'filename': this.state.filename}
        this.props.editButton(this.props.data.name, data)
    }
 
    render() {

        const { data, index, classes, deleteButton } = this.props;
        const { comment, edit, filename, edited } = this.state;
            return(
                <div className={classes.root} key={ index }>
                <Paper className={classes.paper}>
                <Grid container spacing={24}>
                    
                    <Grid item xs={10}>
                        
                        <ListItem>
                        <Avatar>
                            <ImageIcon />
                        </Avatar>
                        {!edit ? <ListItemText primary={filename} secondary={comment} /> : <div>
                        <TextField
                            id="comment"
                            label="Comment"
                            className={classes.textField}
                            value={comment}
                            onChange={this.handleChange}
                            margin="normal"
                        />
                        </div>
                        }
                        
                        </ListItem>
                       
                    </Grid>
                    <Grid item xs={1}>
                    {!edited ? <IconButton className={classes.button}><EditIcon onClick={this.handleEdit} /> </IconButton>:
                    <IconButton className={classes.button}>
                    <DoneIcon onClick={this.handleSave} />
                    </IconButton>
                    
                }
                        
                    </Grid>
                    <Grid item xs={1}>
                    <IconButton className={classes.button}>
                        <ClearIcon onClick={deleteButton.bind(this)} id={data.name} />
                        </IconButton>
                    </Grid>
                    
                </Grid>
                </Paper>
                </div>

            );
        }

}


export default withStyles(styles)(Content);