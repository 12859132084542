import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../store/actions/UserActions';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/PersonAdd';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    layout: {
      width: 'auto',
      display: 'block', // Fix IE11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE11 issue.
      marginTop: theme.spacing.unit,
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
    },
    buttons: {
        textAlign: 'center',
    },
    button: {
        width: '40%',
        marginTop: theme.spacing.unit * 3,
        marginLeft: '1rem',
        marginRight: '1rem',
    }
  });


class Register extends React.Component{

    state = {
        login: '',
        passwd1: '',
        passwd2: '',
        fullname: '',
        phone: ''

    }

    handleChangeInput = e => {
        e.preventDefault();
        // if (this.props.error) clearUserErrors();
        const { name, value } = e.target;
        this.setState({ [name]: value });
      };
      
      handleSubmiteForm = e => {
        e.preventDefault();
        
        const { registrationUser } = this.props.userActions;
        registrationUser(this.state);
      };

    render() {
        const { classes, handleRegister } = this.props;


        return (
            <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography variant="headline" >Register new user</Typography>
          <form className={classes.form} onChange={this.handleChangeInput} onSubmit={this.handleSubmiteForm}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="login">User name (login)</InputLabel>
              <Input 
                id="name" 
                name="fullname" 
                autoComplete="username"
                required
                autoFocus 
                />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">E-mail</InputLabel>
              <Input 
                id="email" 
                name="login" 
                autoComplete="email" 
                required
                // autoFocus 
                />
            </FormControl>
            
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Phone</InputLabel>
              <Input 
                id="phone" 
                name="phone" 
                autoComplete="tel"
                required
                // autoFocus 
                />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="passwd1">Password</InputLabel>
              <Input
                name="passwd1"
                type="password"
                id="passwd1"
                required
                autoComplete="new-password"
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="passwd2">Confirm password</InputLabel>
              <Input
                name="passwd2"
                type="password"
                id="passwd2"
                required
                // autoComplete="current-password"
              />
            </FormControl>
            <div className={classes.buttons}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                >
                Register
                </Button>
                <Button
                //   type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleRegister}
                >
                Cancel
                </Button>
            </div>
          </form>
        </Paper>
      </main>
    </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
  return {
      user: state.user,
      // page: state.page
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // pageActions: bindActionCreators(pageActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Register));