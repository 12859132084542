import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography, Grid  } from '@material-ui/core';
import Add from '@material-ui/icons/QueuePlayNext';
import Check from '@material-ui/icons/Check';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import * as utils from '../../services/utils';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
      },
    table: {
    minWidth: 700,
    },
    button: {
      margin: theme.spacing.unit,
    },
    input: {
      display: 'none',
    },
    error: {
      color: 'red'
    },
    img: {
      width: '4rem'
    }
  });

let id = 0;
function createData(name, comment, duration, size) {
id += 1;
return { id, name, comment, duration, size };
}

class Packets extends React.Component {
    constructor(props) {
        super(props);

        this.setate = {
            isUsed: this.isUsed(),
        };
    }

    componentWillMount() {
        this.setState({
            data: this.addToPlaylist(),
            isUsed: this.isUsed(),
        });
    }

    addToPlaylist(){
        const { pacs, content } = this.props;

        let list = []
        if (pacs.files) {
        pacs.files.map(p => {
          content.map(c => {
            if (c.id === p) {
                list.push(createData(c.name, c.comments, c.lenght, c.size))
            }
          })
        })
      }
      return list
      }

    isUsed(){
        const {pacs, playlists} = this.props;
        var result = false;
        playlists.map(p => {
            // console.log(p.id, pacs.id)
            if(p.name === pacs.name) {
                result = true;
            }
        });
        return result;
    }

    handleSetPacsPlaylist() {
        const { setPacsPlaylists, token, pacs,} = this.props;
        const data = {
            id: pacs.id
        }
        setPacsPlaylists(token, data);
    }

    render() {
        const { classes, pacs } = this.props;
        const { isUsed } = this.state;
        return (
            <TableBody>
                <TableCell component="th" scope="row">
                    {pacs.name}
                </TableCell>
                {/* <TableCell component="th" scope="row">
                    comment
                </TableCell> */}
                <TableCell align="right">{utils.prityTime(pacs.duration)}</TableCell>
                <TableCell align="right">{Math.round(pacs.size / (1024 * 1024))} &nbsp;  Mb</TableCell>
                <TableCell align="right">
                {!this.isUsed() ? 
                    <Button onClick = {this.handleSetPacsPlaylist.bind(this)} color="primary">
                        <Add/>
                    </Button> : 
                    <Button>
                        <Check style={{color: 'green'}}/>
                    </Button> }
                </TableCell>
            </TableBody>
        )
    }
}

export default withStyles(styles)(Packets);