import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { DropTarget, DragSource } from 'react-dnd';
import { is } from 'immutable';
import { findDOMNode } from 'react-dom';
import Avatar from '@material-ui/core/Avatar'
import PreviewPlayer from './PreviewPlayer';

const styles = theme => ({
    root: {
        // width: '100%',
        // marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: '0.5rem',
        borderStyle: 'solid',
        borderColor: '#d1e4fc'
    },
    table: {
        backgroundColor: '#d1e4fc',

        // minWidth: 700,
    },
    row: {
        backgroundColor: 'white',
    },
    head: {
        marginTop: '1rem',
        marginLeft: '-0.5rem',
        height: '1.5rem',
        // fontSize: '0.8rem',
        flex: '0 0 auto',
    },
    headMobile: {
        marginTop: '0.8rem',
        marginLeft: '-0.5rem',
        // height: '1.5rem',
        fontSize: '12px',
        flex: '0 0 auto',
    },
    spacer: {
        flex: '1 1 100%',
    },
    roothead: {
        display: 'flex',
        backgroundColor: '#d1e4fc',
    },
    type: {
        marginTop: '1rem',
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
        fontSize: '0.8rem',
        flex: '0 0 auto',
    },
    typeMobile: {
        marginTop: '0.8rem',
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
        fontSize: '10px',
        flex: '0 0 auto',
    },
    cell: {
        width: '10rem'
    },
    fotter: {
        display: 'flex',
        backgroundColor: '#d1e4fc',
        height: '1.4rem',
        marginTop: '0.8rem',
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        flex: '0 0 auto',
        textTransform: 'uppercase',
        fontSize: '0.7rem'
    },
    fotterMobile: {
        display: 'flex',
        backgroundColor: '#d1e4fc',
        height: '1rem',
        marginTop: '0.8rem',
        marginLeft: '0.2rem',
        flex: '0 0 auto',
        // textTransform: 'uppercase',
        fontSize: '0.4rem'
    },
    more: {
        // marginRight: '1rem',
        // marginBottom: '0.5rem',
        fontSize: '0.8rem',
        flex: '0 0 auto',
        // height: '1rem',
        color: 'red'
    },
    button: {
        width: '1.1rem',
        // height: '2.4rem',
        borderRadius: '4px',
        marginBottom: '0.2rem',
        // marginRight: '0.5rem',
        minWidth: '2.5rem'
    },
    buttonMobile: {
        width: '1.1rem',
        // height: '2.4rem',
        borderRadius: '4px',
        marginBottom: '0.2rem',
        // marginRight: '0.5rem',
        // minWidth: '2.5rem'
    },
    icon: {

    },
    iconMobile: {
        width: "16px",
        height: "16px"
    },
    hover: {
        '&:hover': {
            backgroundColor: '#c0dbff !important'
        }
    },
    nohover: {

    },
    sel: {
        backgroundColor: '#ff8f8f',
        '&:hover': {
            backgroundColor: '#fd4444 !important'
        }
    },
    selShort: {
        backgroundColor: '#8fffc1',
        '&:hover': {
            backgroundColor: '#c0dbff !important'
        }
    },
    textselect: {
        color: 'red'
    },
    reload: {
        display: 'flex'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '30rem',
        marginTop: "0.6rem",
        height: "2em",
        // backgroundColor: "white"
    },
    textFieldMobile: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '50rem',
        // marginTop: "0.6rem",
        height: "2em",
        // backgroundColor: "white"
    },
    paperWidthSm: {
        width: "50%"
    },
    avatarHuge: {
        backgroundColor: 'lightblue',
        borderRadius: '0% !important',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '25rem',
        height: '15rem',
        position: 'absolute',
        // left: '0rem',
        zIndex: 3,
        borderStyle: 'groove',
        padding: '2px',
        top: '-3px',
        left: '-3px'
        // right: '-15rem'
    },

});

const itemSource = {
    // canDrag(props) {
    //     return false
    //     // return props.isReady
    // },

    isDragging(props, monitor) {
        return monitor.getItem().id === props.id;
    },

    beginDrag(props, monitor, component) {
        // console.log('Dragging', monitor.getItemType())

        const item = {
            row: props.row,
            index: props.elem,
            id: props.elem,
            playlist: props.playlist,
        };
        // console.log('DRAG>>> ', props, component, monitor)
        props.onDragHandle(item);
        return item;
    },

    endDrag(props, monitor, component) {

        if (!monitor.didDrop()) {
            return;
        }
        // const item = monitor.getItem();

        // const dropResult = monitor.getDropResult();
        // This is a good place to call some Flux action
        // console.log('Card Action - ', item, dropResult, props);
    }
};

function collectSource(connect, monitor) {
    return {
        // Call this function inside render()
        // to let React DnD handle the drag events:
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        // You can ask the monitor about the current drag state:
        isDragging: monitor.isDragging(),

    };
}

function collect(connect, monitor) {

    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        hovered: monitor.isOver(),
        item: monitor.getItem(),
        highlighted: monitor.canDrop(),
        canDrop: monitor.canDrop()
    }
}

const fileTarget = {

    drop(props, monitor, component) {
        // const type = monitor.getItemType();
        // console.log('>>>>>>>', monitor.getItem(), type)
    },
}

class PlaylistFile extends React.Component {

    render() {
        const { short, key, row, nowPlayning, isMobile, isSelected, nowPlst, playlist, role, classes, handleClickPlayNow, handleClickPlay, handleClick, connectDragSource, onDrop, isMouseOver, handleOverFile, token } = this.props;
        var name = row.oldname.split('/');
        var isHover = false;
        var isStream = false;

        if (name[0].toLowerCase() === 'http:' || name[0].toLowerCase() === 'https:') {
            isStream = true;
            name = row.oldname
        } else {
            name = name[name.length - 1]
        }
        if (isMouseOver.hover && isMouseOver.id === row.id && playlist.type !== 'pacs' && isSelected && !isStream) { isHover = true; } else { isHover = false; }
        row['playlistid'] = playlist.id
        row['ishover'] = isHover
        const preview = { 'own': playlist.own, 'filename': name, 'token': token, 'isStream': isStream }
        const elem = (
            <div key={key} onDrop={(e) => { onDrop(e, row) }} onMouseEnter={(event) => handleOverFile(row.id)}>
                {short ?
                    <TableRow key={row.id} hover className={isSelected ? classes.selShort : classes.hover} onClick={event => handleClickPlayNow(event, row)}
                    >

                        <TableCell component="th" scope="row" style={isMobile ? { width: '75%', paddingLeft: '1rem', fontSize: '10px' } : { width: '100%', paddingLeft: '1rem' }}>
                            {nowPlayning === row.name && nowPlst === playlist.id ? <div className={classes.textselect}>{row.name}</div> : <div>{row.name}</div>}
                        </TableCell>
                        {!isMobile && !isSelected ?
                            <TableCell numeric style={{ width: '15%', padding: 0, textAlign: 'left', fontSize: '10px' }}>{row.comment}</TableCell> : null}
                        <TableCell numeric style={isMobile ? { width: '10%', paddingRight: '1rem', fontSize: '10px' } : { width: '10%', paddingRight: '1rem' }}>{isSelected ? <PlayArrowIcon onClick={event => handleClickPlay(event, row)} /> : row.duration}</TableCell>
                    </TableRow> :
                    <TableRow key={row.id} hover className={isSelected ? classes.sel : classes.hover} onClick={role === 'listen' ? null : event => handleClick(event, row)}
                    >
                        {isHover ?

                            <Avatar variant="rounded" className={classes.avatarHuge}>
                                {/* <PreviewPlayer preview={preview} /> */}
                                {isStream ? <a>Stream</a> :
                                    <img src={`https://revip.org/api/ui/getpreviewgif/${playlist.own}/${name}`} className={classes.avatarHuge} alt={name} />}
                            </Avatar>
                            : null}
                        <TableCell component="th" scope="row" style={{ width: '85%', paddingLeft: '1rem' }}>
                            {nowPlayning === row.name && nowPlst === playlist.id ? <div className={classes.textselect}>{row.name}</div> : <div>{row.id}: {row.name}</div>}
                        </TableCell>
                        <TableCell numeric style={{ width: '15%', padding: 0, textAlign: 'left' }}>{row.comment}</TableCell>
                        <TableCell numeric style={{ width: '10%', paddingRight: '1rem' }}>{row.duration}</TableCell>
                    </TableRow>}
            </div>)

        if (isHover) {
            return elem;
        } else {
            return connectDragSource(elem);
        }
    }
}

export default DropTarget(['PLSTFILE'], fileTarget, collect)(DragSource('PLSTFILE', itemSource, collectSource)(withStyles(styles)(PlaylistFile)));