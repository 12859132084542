import React from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import * as pageActions from '../../store/actions/PageActions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DropTarget, DragSource } from 'react-dnd';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { updatePlaylist } from '../../store/actions/PageActions'
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import PlaylistSettingsDialog from './playlistSettingsDialog';
import { convertTiming } from '../../services';
import * as network from '../../services';
import Tooltip from '@material-ui/core/Tooltip';
import * as utils from '../../services/utils';
import IconReload from '@material-ui/icons/Cached';
import i18next from 'i18next';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import AlertDialog from '../AlertDialog';
import PlaylistFile from './PlaylistFile';

let tkn = '';
let p_type = '';


const styles = theme => ({
  root: {
    // width: '100%',
    // marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    marginBottom: '0.5rem',
    borderStyle: 'solid',
    borderColor: '#d1e4fc'
  },
  table: {
    backgroundColor: '#d1e4fc',

    // minWidth: 700,
  },
  row: {
    backgroundColor: 'white',
  },
  head: {
    marginTop: '1rem',
    marginLeft: '-0.5rem',
    height: '1.5rem',
    // fontSize: '0.8rem',
    flex: '0 0 auto',
  },
  headMobile: {
    marginTop: '0.8rem',
    marginLeft: '-0.5rem',
    // height: '1.5rem',
    fontSize: '12px',
    flex: '0 0 auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  roothead: {
    display: 'flex',
    backgroundColor: '#d1e4fc',
  },
  type: {
    marginTop: '1rem',
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
    fontSize: '0.8rem',
    flex: '0 0 auto',
  },
  typeMobile: {
    marginTop: '0.8rem',
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
    fontSize: '10px',
    flex: '0 0 auto',
  },
  cell: {
    width: '10rem'
  },
  fotter: {
    display: 'flex',
    backgroundColor: '#d1e4fc',
    height: '1.4rem',
    marginTop: '0.8rem',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    flex: '0 0 auto',
    textTransform: 'uppercase',
    fontSize: '0.7rem'
  },
  fotterMobile: {
    display: 'flex',
    backgroundColor: '#d1e4fc',
    height: '1rem',
    marginTop: '0.8rem',
    marginLeft: '0.2rem',
    flex: '0 0 auto',
    // textTransform: 'uppercase',
    fontSize: '0.4rem'
  },
  more: {
    // marginRight: '1rem',
    // marginBottom: '0.5rem',
    fontSize: '0.8rem',
    flex: '0 0 auto',
    // height: '1rem',
    color: 'red'
  },
  button: {
    width: '1.1rem',
    // height: '2.4rem',
    borderRadius: '4px',
    marginBottom: '0.2rem',
    // marginRight: '0.5rem',
    minWidth: '2.5rem'
  },
  buttonMobile: {
    width: '1.1rem',
    // height: '2.4rem',
    borderRadius: '4px',
    marginBottom: '0.2rem',
    // marginRight: '0.5rem',
    // minWidth: '2.5rem'
  },
  icon: {

  },
  iconMobile: {
    width: "16px",
    height: "16px"
  },
  hover: {
    '&:hover': {
      backgroundColor: '#c0dbff !important'
    }
  },
  nohover: {

  },
  sel: {
    backgroundColor: '#ff8f8f',
    '&:hover': {
      backgroundColor: '#fd4444 !important'
    }
  },
  selShort: {
    backgroundColor: '#8fffc1',
    '&:hover': {
      backgroundColor: '#c0dbff !important'
    }
  },
  textselect: {
    color: 'red'
  },
  reload: {
    display: 'flex'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '30rem',
    marginTop: "0.6rem",
    height: "2em",
    // backgroundColor: "white"
  },
  textFieldMobile: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '50rem',
    // marginTop: "0.6rem",
    height: "2em",
    // backgroundColor: "white"
  },
  paperWidthSm: {
    width: "50%"
  }

});

function addFileToPlist(id, playlist, own) {
  playlist.files.push(id)
  updatePlaylist(playlist, tkn, own);

}

const plistTarget = {
  drop(props, monitor, component) {
    const type = monitor.getItemType();
    p_type = type;
    if (props.playlist.type === "pacs") return;
    const item = monitor.getItem();
    if (type === 'FILE') {
      addFileToPlist(item.id, props.playlist, props.playlist.own);
    }


  },
  canDrop(item, monitor) {

    p_type = monitor.getItemType();
    if (p_type === 'FILE') { return true }

    return item.playlist.id === monitor.getItem().playlist.id;
  },
  // hover(item, monitor) {
  //   console.log('isOver >>>', item, monitor)
  // }
}




const itemSource = {
  // canDrag(props) {
  //   return props.isReady
  // },

  isDragging(props, monitor) {
    return monitor.getItem().id === props.id;
  },

  beginDrag(props, monitor, component) {
    // console.log('BEGIN', props)
    const item = {
      playlist: props.playlist,
      id: props.id
    };
    return item
  },

  endDrag(props, monitor, component) {


    if (!monitor.didDrop()) {
      return;
    }
    const item = monitor.getItem();

    const dropResult = monitor.getDropResult();
    // This is a good place to call some Flux action
    // console.log('Card Action - ', props, item);
  }
};


function collect(connect, monitor) {
  // console.log('Collect> ', monitor)

  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    item: monitor.getItem(),
  }
}


function collectSource(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging()
  };
}

let id = 0;
function createData(name, comment, duration, trackid = 0, size) {
  id += 1;
  return { id, name, comment, duration, trackid, size };
}


class PlaylistShort extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      numbCell: 3,
      isHover: false,
      playlist: this.props.playlist,
      // data: this.addToPlaylist(),
      selected: [],
      settingsDialogOpen: false,
      counter: 0,
      id: 0,
      converting: false,
      isSearch: false,
      search: "",
      alertDialog: false,
      more: false,
      hoverelem: 0,
      onDragId: 0,
      isMouseOver: { id: null, hover: false },
    }
  }



  handleOverFile = (id) => {
    this.setState({ isMouseOver: { id: id, hover: true } })
  }

  handleUnOverFile = (id) => {
    this.setState({ isMouseOver: { id: id, hover: false } })
  }
  addToPlaylist = () => {
    const { playlist } = this.props
    const { content } = this.props.page;
    let list = []
    id = 0
    if (playlist.files) {
      playlist.files.map(p => {
        content.map((c, key) => {
          if (c.id === p) {
            list.push(createData(c.name, c.comments, c.lenght, c.id, c.size));

          }
        });
      });
    }

    return list

  }


  handleOver = () => {
    this.setState({ isHover: !this.state.isHover });

  };

  handleChangeMoreOpen = () => {
    const { playlist } = this.props
    this.setState({
      numbCell: playlist.files.length,
      more: true
    })
  }

  handleChangeMoreClose = () => {
    this.setState({
      numbCell: 3,
      more: false
    })
  }
  handleAlertClose = () => {
    this.setState({
      alertDialog: false
    })
  }

  handleDeleteConfirm = () => {
    this.setState({
      alertDialog: !this.state.alertDialog
    })
  }

  handleDelete = () => {
    var p = this.props.playlist;
    this.state.selected
      // .sort(function (a, b) {
      //   return b - a;
      // })
      .map((v) => {
        return p.files.splice(v - 1, 1)
      })
    // console.log('HD> ', p.files)
    updatePlaylist(p, tkn, this.props.user.id)
    this.setState({ selected: [], counter: 0 })
  }

  handleRemovePlaylistConfirm = (playlist) => {
    this.setState({
      confirmedPlaylistId: playlist,
      alertDialog: true
    })
  }

  handleRemovePlaylistAgree = () => {
    this.handleDeleteConfirm();
    this.handleRemovePlaylist(this.state.confirmedPlaylistId);
  }


  handleRemovePlaylist = (playlist) => {
    const { id } = this.props.user;
    const pl = {
      playlist: {
        content: this.props.plplaylist.content,
        advertising: this.props.plplaylist.advertising,
        id: this.props.mac
      }
    }
    pl.playlist.content.splice(pl.playlist.content.indexOf(playlist.id), 1)
    network.socket.emit('play', { 'playlists': 1, 'player': this.props.mac, 'data': pl.playlist, 'ownid': id })
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, row) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    newSelected.sort(function (a, b) {
      return b - a
    })
    this.setState({ selected: newSelected });
  };

  handleClickPlayNow = (event, row) => {
    let newSelected = [];
    const { selected } = this.state;
    if (selected[0] !== row.id) {
      newSelected.push(row.id);
    }

    this.setState({ selected: newSelected });
  };

  handleClickPlay = (event, row) => {
    const { playNowFile } = this.props.pageActions;
    const id = this.props.user.role === 'listen' ? this.props.user.parent : this.props.user.id;
    const { mac } = this.props;

    playNowFile(id, mac, row.trackid);

  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleSettingsDialog = () => this.setState({ settingsDialogOpen: !this.state.settingsDialogOpen })

  handleCheckPacsPlaylist() {
    const { token } = this.props.user;
    const { playlist } = this.props;
    const { checkpacsplaylist } = this.props.pageActions;
    const { converting } = this.state;

    const data = {
      name: playlist.name,
      converting: converting
    }

    checkpacsplaylist(token, data);

  }

  handleConverting() {
    this.setState({
      converting: !this.state.converting
    })
  }

  handleSearch() {
    this.setState({
      isSearch: !this.state.isSearch,
      search: ""
    })
  }

  handleChangeSearch = event => {
    this.setState({ search: event.target.value });
  };

  calcTime() {
    var times = [];
    this.addToPlaylist().map(d => {
      times.push(d.duration)
    })
    const calc = utils.calculateTime(times);
    return calc;
  }

  onDropHandle(e, card) {
    const { user } = this.props;
    const oldId = this.state.onDragId;
    e.preventDefault()
    this.changePosition(oldId, card.id, user.id)
  }

  onDragHandle(card) {

    this.setState({
      onDragId: card.row.id
    })
  }

  changePosition(oldId, newId, own) {
    var { playlist } = this.props;
    const pV = this.addToPlaylist()
    const playlistV = pV.map(p => { return p.trackid })

    if (p_type === 'FILE' || playlistV === undefined) { return }
    oldId = oldId - 1;
    newId = newId - 1;
    const elem = playlistV[oldId];
    playlistV.splice(oldId, 1)
    playlistV.splice(newId, 0, elem)

    playlist.files = playlistV;

    updatePlaylist(playlist, tkn, own)

  }


  render() {
    const { classes, connectDropTarget, connectDragSource, nowPlayning, short, nowPlst, id, isMobile, playlist } = this.props;
    const { numbCell, settingsDialogOpen, selected, converting, isSearch, search, alertDialog, more, isMouseOver } = this.state
    const { token, role } = this.props.user;
    const { deletePlaylist } = this.props.pageActions;
    var totallSize = 0;
    tkn = token
    const numSelected = selected.length
    const addPlaylist = this.addToPlaylist();
    if (playlist.name.split('_')[1]) {
      playlist.name = playlist.name.replace('_', ' ');
    }

    const elem = (
      <div>
        <Paper className={classes.root}>
          {numSelected > 0 && !short ?
            <div className={classes.roothead}>
              <div className={classes.type}>{playlist.type}</div>
              <div className={classes.spacer} />
              <div className={classes.head}>{playlist.name}</div>
              <div className={classes.spacer} />

              <Tooltip title={i18next.t("Delete")}>
                <IconButton className={classes.button} aria-label="Delete">
                  <DeleteIcon className={classes.icon} onClick={this.handleDelete} />
                </IconButton>
              </Tooltip>
              {addPlaylist.length > 3 ?
                <Tooltip title={i18next.t("More")}>{3 >= numbCell ?
                  <IconButton className={isMobile ? classes.buttonMobile : classes.button} onClick={this.handleChangeMoreOpen}>
                    <AddIcon className={isMobile ? classes.iconMobile : classes.icon} />
                  </IconButton> :
                  <IconButton className={isMobile ? classes.buttonMobile : classes.button} onClick={this.handleChangeMoreClose}>
                    <RemoveIcon className={isMobile ? classes.iconMobile : classes.icon} />
                  </IconButton>}
                </Tooltip> : null}
            </div>
            :
            <div className={classes.roothead}>
              <div className={isMobile ? classes.typeMobile : classes.type}>{playlist.type}</div>
              <div className={classes.spacer} />
              {isSearch ? null :
                <div className={isMobile ? classes.headMobile : classes.head}>{playlist.name}</div>}

              {isSearch ? <Input
                placeholder="Search"
                className={isMobile ? classes.textFieldMobile : classes.textField}
                margin="normal"
                onChange={this.handleChangeSearch.bind(this)}
              /> : <div className={classes.spacer} />}
              {/* {short ? */}
              <Tooltip title={i18next.t("Search")}>
                <IconButton className={isMobile ? classes.buttonMobile : classes.button} aria-label="Search" onClick={this.handleSearch.bind(this)}>
                  <SearchIcon className={isMobile ? classes.iconMobile : classes.icon} />
                </IconButton>
              </Tooltip>
              {/* : null} */}
              {short && role !== 'listen' ?
                <Tooltip title={i18next.t("Delete_playlist")}>
                  <IconButton className={isMobile ? classes.buttonMobile : classes.button} aria-label="Delete" key={playlist.id} onClick={() => this.handleRemovePlaylistConfirm(playlist)}>
                    <DeleteIcon className={isMobile ? classes.iconMobile : classes.icon} key={playlist.id} />
                  </IconButton>
                </Tooltip>
                :
                <div></div>}
              {playlist.type === "pacs" && role === "admin" && playlist.master ?
                <div className={classes.reload}>
                  <Tooltip title={i18next.t("Reload_packets_playlist")}>
                    <IconButton className={classes.button} aria-label="Reload" onClick={this.handleCheckPacsPlaylist.bind(this)}>
                      <IconReload style={{ color: "#4660e2" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={i18next.t("Converting_files")}>
                    <IconButton className={classes.button} arial-label="Converting" onClick={this.handleConverting.bind(this)}>
                      <a style={{ color: converting ? "green" : "white" }} >C</a>
                    </IconButton>
                  </Tooltip>
                </div>
                : null}
              {role === 'listen' ? null :
                <Tooltip title={i18next.t("Settings")}>
                  <IconButton className={isMobile ? classes.buttonMobile : classes.button} aria-label="Settings" onClick={this.handleSettingsDialog}>
                    <SettingsIcon className={isMobile ? classes.iconMobile : classes.icon} />
                  </IconButton>
                </Tooltip>
              }
              {addPlaylist.length > 3 ?
                <Tooltip title={i18next.t("More")}>{3 >= numbCell ?
                  <IconButton className={isMobile ? classes.buttonMobile : classes.button} onClick={this.handleChangeMoreOpen}>
                    <AddIcon className={isMobile ? classes.iconMobile : classes.icon} />
                  </IconButton> :
                  <IconButton className={isMobile ? classes.buttonMobile : classes.button} onClick={this.handleChangeMoreClose}>
                    <RemoveIcon className={isMobile ? classes.iconMobile : classes.icon} />
                  </IconButton>}
                </Tooltip> : null}
            </div>}
          <Table className={classes.table}>

            <TableBody className={classes.row}>
              {
                addPlaylist
                  .filter((e) => {
                    e.oldname = e.name;
                    if (e.name.split('____')[1]) {
                      e.name = e.name.split('____')[1]
                    }
                    e.name = e.name.replace(/_/g, " ").replace(".mp4", "");
                    e.comment = e.comment.replace(/_/g, " ");
                    if (search === "") {
                      return e
                    } else if (e.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                      return e
                    }
                  })
                  .map((row, key) => {
                    const isSelected = this.isSelected(row.id);
                    totallSize = totallSize + row.size;
                    if (row.name.split('____')[1]) {
                      row.name = row.name.split('____')[1]
                    }
                    if (more) {

                      return (
                        // <div key={key} hover onMouseEnter={(event) => this.handleOverFile(row.id)} onMouseLeave={(event) => this.handleUnOverFile(row.id)}>
                        <PlaylistFile hover handleOverFile={this.handleOverFile} handleUnOverFile={this.handleUnOverFile} short={short} key={key} row={row} nowPlayning={nowPlayning} isMobile={isMobile} isSelected={isSelected} nowPlst={nowPlst} playlist={playlist} role={role} handleClickPlayNow={this.handleClickPlayNow} handleClickPlay={this.handleClickPlay} handleClick={this.handleClick.bind(this)} elem={key} changeElemInArray={this.changeElemInArray} onDrop={this.onDropHandle.bind(this)} onDragHandle={this.onDragHandle.bind(this)} isMouseOver={isMouseOver} token={token} />
                        // </div>
                      )
                    } else {
                      if (key + 1 <= numbCell) {

                        return (
                          // <div key={key} hover onMouseEnter={(event) => this.handleOverFile(row.id)} onMouseLeave={(event) => this.handleUnOverFile(row.id)}>
                          <PlaylistFile hover handleOverFile={this.handleOverFile} handleUnOverFile={this.handleUnOverFile} short={short} key={key} row={row} nowPlayning={nowPlayning} isMobile={isMobile} isSelected={isSelected} nowPlst={nowPlst} playlist={playlist} role={role} handleClickPlayNow={this.handleClickPlayNow} handleClickPlay={this.handleClickPlay} handleClick={this.handleClick} elem={key} changeElemInArray={this.changeElemInArray} onDrop={this.onDropHandle.bind(this)} onDragHandle={this.onDragHandle.bind(this)} isMouseOver={isMouseOver} token={token} />
                          // </div>

                        )
                      }
                    }
                  })
              }
            </TableBody>
          </Table>
          <div className={classes.roothead}>
            <div className={isMobile ? classes.fotterMobile : classes.fotter}> <div> <h style={{ color: 'blue' }}>{i18next.t("START")}</h> {convertTiming(playlist.start)}</div> {playlist.type !== "advertising" ? <div> <h style={{ color: 'blue' }}>&nbsp;&nbsp;{i18next.t("STOP")} </h>{convertTiming(playlist.stop)} </div> : null}</div>
            <div className={classes.spacer} />

            <div className={classes.spacer} />
            {isMobile || short ? null :
              <div className={classes.fotter}> {i18next.t("Totall_time")} {this.calcTime()} ({utils.prettiNum(Math.round(totallSize / 1024 / 1024))} Mb)</div>}

          </div>
        </Paper>
        <PlaylistSettingsDialog open={settingsDialogOpen} handleSettingsDialog={this.handleSettingsDialog} playlists={playlist} updatePlaylist={updatePlaylist} deletePlaylist={deletePlaylist} token={tkn} userid={id} role={role} />
        <AlertDialog open={alertDialog} alert="Delete playlist confirm!" message="Confirm remove playlist!" handleAgree={this.handleRemovePlaylistAgree} handleClose={this.handleAlertClose} />
      </div>)

    if (selected.length > 0) {
      return connectDropTarget(elem);
    } else {
      return connectDropTarget(connectDragSource(elem));
    }
  }
}

// PlaylistShort.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

function mapStateToProps(state) {
  return {
    user: state.user,
    page: state.page
  }
}

function mapDispatchToProps(dispatch) {
  return {
    pageActions: bindActionCreators(pageActions, dispatch),
    //   userActions: bindActionCreators(userActions, dispatch)
  }
}

export default DropTarget(['FILE', 'PLSTFILE'], plistTarget, collect)(DragSource('PLAYLIST', itemSource, collectSource)(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlaylistShort))));