import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as network from '../../services';
import * as userActions from '../../store/actions/UserActions';
import * as pageActions from '../../store/actions/PageActions';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ConfirmIcon from '@material-ui/icons/Done';
import MonetizationOnIcon from '@material-ui/icons/Payment';
import Grid from '@material-ui/core/Grid';
import AlertDialog from '../AlertDialog';
import i18next from 'i18next';
import Card from '../Card';
import * as Utils from '../../services/utils';
import DeletIcon from '@material-ui/icons/Delete';
import color from '@material-ui/core/colors/amber';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        //   minWidth: 700,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    totall: {
        margin: '1rem',
    },
    alert: {
        background: '#fde6e6'
    },
    button: {
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit * 2,
    },
    button2: {
        marginRight: theme.spacing.unit,
    },
    leftIcon: {
        marginTop: '-5px',
        marginLeft: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    tooltipCustomWidth: {
        maxWidth: 500,
    },
    tableCell: {
        padding: '5px',
        textAlign: 'center'
    }
});

const convertdate = date => {
    if (date !== undefined) {
        const newdate = date.split(" ");
        const formatdate = newdate[0].split("-");
        if (formatdate[2]) {
            return formatdate[2] + "-" + Utils.convertDay(formatdate[1]) + "-" + formatdate[0];
        } else {
            return newdate[1] + "-" + newdate[2] + "-" + newdate[3]
        }
    }
}

function order(a, b) {
    return a.props.value < b.props.value ? -1 : (a.props.value > b.props.value ? 1 : 0);
}

class Players extends React.Component {
    state = {
        mode: [],
        players: [],
        disabled: true,
        alertDialog: false,
        alertDialogNoMoney: false,
        tooltipMess: '',
        tooltip: false,
        title: '',
        paymentDialog: false,
        deleteAlert: false,
    };

    handleAlertConfirm() {
        const { changeModes } = this.props.pageActions;
        const { token, id } = this.props.user;
        const { players } = this.state;

        var data = [];
        players.map(p => {
            data.push({
                id: p.id,
                mode: p.mode,
                own: id
            });
        });
        changeModes(token, data);
        this.handleAlertClose();
    }

    handleTooltipOpen = (open, m) => {
        const { language } = this.props;
        var message = ''
        if (language === 'en') {
            message = m.desceng
        }
        else {
            message = m.descru
        }
        this.setState({
            tooltip: open,
            tooltipMess: message,
            title: m.name
        })
    }

    handleConfirm() {
        const { balance } = this.props.user;
        if (balance - this.totallCostExp() >= 0) {
            this.setState({
                alertDialog: true
            })
        } else {
            this.setState({
                alertDialogNoMoney: true
            })
        }

    }

    handleAlertClose() {
        this.setState({
            alertDialog: false,
            alertDialogNoMoney: false,
            paymentDialog: false,
            deleteAlert: false
        })
    }
    componentWillMount() {
        var m = []
        this.props.page.players.map(p => {
            m.push(p.mode)
        })
        this.setState({
            mode: m
        })
    }
    getMode(mode) {
        const { modes } = this.props.user;
        var mod = {
            cost: 0
        };
        // if (modes != undefined) {
        modes.map(m => {
            if (m.mode === mode) {
                mod = m;
            }
        });
        // }
        return mod;
    }

    changedCost() {
        var cost = 0;
        const { mode } = this.state;
        const { players } = this.props.page;

        players.map((p, i) => {

            // if (p.mode < mode[i]) {
            cost = cost + this.getMode(mode[i]).cost;
            // }
        })
        return cost
    }

    totallCostExp() {
        var cost = 0;
        const { mode, players } = this.state;

        players.map((p, i) => {

            if (this.checkDate(p.expires.split(' ')[0])) {
                cost = cost + this.getMode(mode[i]).cost;
            }
        })
        return cost
    }

    checkDate(dt) {
        const now = new Date();
        const date = new Date(dt);

        if (now > date) {
            return true
        }
        return false
    }

    changePlayersMode() {
        const { mode } = this.state;
        const { players } = this.props.page;
        var data = []
        players.map((p, i) => {
            if (p.mode !== mode[i]) {
                const d = {
                    id: p.id,
                    mac: p.mac,
                    mode: mode[i]
                }
                data.push(d)
            }
        })
    }

    playersToState() {
        const { mode } = this.state;
        var { players } = this.props.page;
        players.map((p, i) => {
            players[i].mode = mode[i]
        })
        return players
    }

    handleChange = id => event => {

        this.state.mode[id] = event.target.value;
        this.setState({
            players: this.playersToState(),
            disabled: false
        })
        // this.setState({ [event.target.name]: event.target.value });
    };

    handlePayment() {
        this.setState({
            paymentDialog: true
        });
    }

    getMessage(cost) {
        return i18next.t("You_have_change_player_mode_This_changed_totall_cost") + Utils.prettiNum(cost) + i18next.t("RUB_per_month") + i18next.t("Confirm")
    }

    handleDelete(player) {
        this.setState({
            deleteId: player.id,
            deleteAlert: true,
            deleteMac: player.mac
        })
    }

    handleAgreeDelete() {
        const { deletePlayer } = this.props.pageActions;
        const { token } = this.props.user;
        const { deleteId, deleteMac } = this.state;
        const { id } = this.props.user;
        const socket = network.socket;

        socket.emit('play', { 'exit': 1, 'player': deleteMac, 'ownid': id });

        deletePlayer(token, deleteId);

        this.setState({
            deleteAlert: false
        })

    }

    render() {

        const { players } = this.props.page;
        const { modes, balance } = this.props.user;
        const { classes, language } = this.props;
        const { disabled, alertDialog, acount, alertDialogNoMoney, tooltip, tooltipMess, title, paymentDialog, deleteAlert, deleteId } = this.state;

        return (
            <div>
                <AlertDialog alert={i18next.t("Confirmation_change_players_mode!")} message={this.getMessage(this.changedCost())} open={alertDialog} handleClose={this.handleAlertClose.bind(this)} handleAgree={this.handleAlertConfirm.bind(this)} />
                <AlertDialog alert="No enoth money!" message="You have up you acount!" open={alertDialogNoMoney} handleClose={this.handleAlertClose.bind(this)} handleAgree={this.handleAlertConfirm.bind(this)} />
                <AlertDialog alert="Info" message="payment_info" open={paymentDialog} handleAgree={this.handleAlertClose.bind(this)} />
                <AlertDialog alert="Delete player" message="confirm_delete_player" open={deleteAlert} handleAgree={this.handleAgreeDelete.bind(this)} handleClose={this.handleAlertClose.bind(this)} />
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h5" align="left" className={classes.totall}>  {i18next.t("Acount")} {Utils.prettiNum(balance)} {i18next.t("RUB")} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Grid container justify="flex-end">
                                        <Button variant="contained" color="primary" align="right" className={classes.button2} onClick={this.handlePayment.bind(this)}>
                                            {i18next.t("Up_balance")}
                                            <MonetizationOnIcon className={classes.leftIcon} />
                                        </Button>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                    </Table>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}> ID </TableCell>
                                <TableCell className={classes.tableCell}> {i18next.t("Player_name")}</TableCell>
                                <TableCell className={classes.tableCell}> {i18next.t("Mode")} </TableCell>
                                <TableCell className={classes.tableCell}> {i18next.t("Cost_per_month")} </TableCell>
                                <TableCell className={classes.tableCell}> {i18next.t("Expires")} </TableCell>
                                <TableCell className={classes.tableCell}> {i18next.t("Delete")} </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {players.map((player, id) => (
                                <TableRow key={id} id={player.id} className={this.checkDate(player.expires.split(' ')[0]) ? classes.alert : null}>
                                    <TableCell className={classes.tableCell} component="th" scope="row">{player.id}</TableCell>
                                    <TableCell className={classes.tableCell}>{player.name}</TableCell>
                                    <TableCell >
                                        <FormControl className={classes.formControl}>
                                            {/* <InputLabel htmlFor="made_change">Mode</InputLabel> */}
                                            {/* <Tooltip title={tooltipMess} enterDelay={500} open={tooltip}> */}
                                            <Select
                                                value={this.state.mode[id]}
                                                onChange={this.handleChange(id)}
                                                onClick={() => this.handleTooltipOpen(false, '', '')}
                                                inputProps={{
                                                    name: 'mode',
                                                    id: 'mode_change',
                                                }}
                                            >
                                                {modes.map((m, i) => (

                                                    <MenuItem
                                                        key={i}
                                                        value={m.mode}
                                                        onMouseEnter={() => this.handleTooltipOpen(true, m)}
                                                        onMouseLeave={() => this.handleTooltipOpen(false, '')}
                                                    >
                                                        {m.name}
                                                    </MenuItem>

                                                )).sort(order)}
                                            </Select>
                                            {/* </Tooltip> */}
                                        </FormControl>
                                    </TableCell>
                                    {/* <TableCell>{this.getMode(player.mode).name}</TableCell> */}
                                    <TableCell className={classes.tableCell}>{Utils.prettiNum(this.getMode(this.state.mode[id]).cost)} RUB</TableCell>
                                    <TableCell className={classes.tableCell}>{convertdate(player.expires)}</TableCell>
                                    <TableCell className={classes.tableCell}><Button onClick={this.handleDelete.bind(this, player)}><DeletIcon style={{ color: 'red' }} /></Button></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Typography variant="h5" align="right" className={classes.totall}>{i18next.t("TOTALL")} {Utils.prettiNum(this.changedCost())}{i18next.t("RUB_month")} </Typography>
                </Paper>
                <Grid container justify="flex-end">
                    <Button variant="contained" color="primary" align="right" className={classes.button} disabled={disabled} onClick={this.handleConfirm.bind(this)}>
                        {i18next.t("Confirm")}
                        <ConfirmIcon className={classes.leftIcon} />
                    </Button>
                </Grid>
                {/* <Card message={tooltipMess} /> */}
                {tooltip ? <Card title={title} message={tooltipMess} /> : null}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page,
        language: state.language.lang.language
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Players));