import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';
import { CssBaseline, Typography, Paper, Grid, Button } from '@material-ui/core';
import { PATHS, ROOT_API_URL } from '../../constants/urls';
import i18next from 'i18next';
import AlertDialog from '../AlertDialog';


const styles = theme => ({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      marginTop: '6rem',
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '6rem',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    stepper: {
      padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing.unit * 3,
      marginLeft: theme.spacing.unit,
    },
    subtext: {
        color: 'gray'
    },
    headline: {
        marginBottom: '3rem'
    }
  });

class Download extends React.Component{

    state = {
        alert: true,
        promotioncode: "",
        promoalert: false
    }

    handleAlert() {
        this.setState({
            alert: !this.state.alert
        })
    }

    handleDownload(name) {

        const url = 'https://play.google.com/store/apps/details?id=com.intellhomesystems.rvp';
        window.open(url, '_blank');
    }

    getpromotioncode() {
        const { token } = this.props.user;
        const { getpromotioncode } = this.props.userActions;

        getpromotioncode(token);
            this.setState({
                alert: false,
                promoalert: true
            });

    }

    handlePromoAgre() {
        this.setState({
            promoalert: false
        })
    }


    render(){
        const { classes } = this.props;
        const { promoalert } = this.state;
        const { promotioncode } = this.props.user;

        return(
            <React.Fragment>
                <CssBaseline />
                <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography variant="display1" align="center" className={classes.headline}>
                    {i18next.t("Downloads")}
                    </Typography>
                    <Grid container spacing={24}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="title" align="left">
                            {i18next.t("Android_app")}
                        </Typography>
                        <Typography variant="caption" align="left" className={classes.subtext}>
                        {i18next.t("Android_desc")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button onClick={() => {this.handleDownload('RVPL')}}>
                            <Typography variant="title" align="center">
                                ReViP APP
                            </Typography>
                            </Button>
                        
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        
                    </Grid>
                    </Grid>
                </Paper>
                </main>
                <AlertDialog open={this.state.alert} alert="Info" message="download_message" handleAgree={this.handleAlert.bind(this)} extraButton="get_code" extraClick={this.getpromotioncode.bind(this)}/>
                <AlertDialog open={promoalert} message={promotioncode} alert="You_promotion_code" handleAgree={this.handlePromoAgre.bind(this)}/>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Download)));