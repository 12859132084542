import axios from 'axios';


const network = axios.create({
});

network.interceptors.request.use(config => {

    // if (config.url === '/auth/tokenidenty') {
      
    //   const token = config.data;
    //   config.headers.Authorization = `Token ${token}`;
    // }
    // else if (!config.url.startsWith('/auth') || config.url === `/auth/logout`) {
    //   const token = sessionStorage.getItem('token');
    //   config.headers.Authorization = `Token ${token}`;
    // }
    //config.url = ROOT_API_URL + config.url
    return config;
  });

network.interceptors.response.use(undefined, error => {
    const resp = error.response;
    if (!resp) return Promise.reject('Произошла непредвиденная ошибка');
    if (resp.status === 401) return resp;
    return Promise.reject(error.response && error.response.data && error.response.data.error);
  });
  
  window.network = network
  
  export default network;